import styled from "styled-components";

export const GradientButton = styled.button`
  color: white;
  display: flex;
  background: linear-gradient(180deg, #fb8404 0%, #fe1e14 155.15%), #c4c4c4;
  border-radius: 40px;
  font-size: 5vw;
  padding-right: 8vw;
  padding-left: 8vw;
  border-style: none;
  @media (min-width: 900px) {
    font-size: 2vw;
    padding-right: 3vw;
    padding-left: 3vw;
  }
  :hover {
    transform: scale(1.05, 1.05);
    cursor: pointer;
    filter: drop-shadow(0px 0px 7px #f5a540);
    -webkit-filter: drop-shadow(0px 0px 7px #f5a540);
    -moz-filter: drop-shadow(0px 0px 7px #f5a540);
  }
`;
