import React from "react";
import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import zBoughtComplete from "../../../assets/ze-bought-complete.png";
import { useNavigate } from "react-router-dom";

import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { CollectibleType } from "../../../types/CollectibleType";

import { getAuthenticity } from "../../../utils/requests";

type props = {
  boughtNfts: CollectibleType[];
};

const PurchaseCompleted: React.FC<props> = ({
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  boughtNfts,
}) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1
    }
  };
  const navigate = useNavigate();

  const openTab = (url: any) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.setAttribute("target", "_blank");
    a.href = window.URL.createObjectURL(new Blob([url], {type: "text/plain"}));
    a.click();
  };

  const authNft = async () => {
    const data = await getAuthenticity(boughtNfts[0].uri, boughtNfts[0].tokenId);
    openTab(JSON.stringify(data));
  }

  return (

    <Grid container>
      <Grid item container xs={0} md={3} lg={4}>
        <ZeImage />
      </Grid>
      <Grid item container xs={12} md={9} lg={8} alignItems={"center"}>
        <Grid item container xs={12} justifyContent={"center"}>
          <Title>Compra efetuada com sucesso!</Title>
          <Carousel
            responsive={responsive}
            arrows={true} renderButtonGroupOutside={true} showDots={true} partialVisible={false} infinite={true} swipeable={true} autoPlay={false} autoPlaySpeed={100000000}
          >
            {
              boughtNfts.map(nft => {
                return (
                  <Grid container key={nft.collectionName} item xs={12} className={'carouselItem'} justifyContent={'center'}>
                    <Grid item container xs={6} sm={5} md={4} alignItems={"center"}>
                      <NftImage style={{ backgroundImage: `url("${nft.imageUrl}")` }} />
                    </Grid>
                    <Grid item xs={8}>

                      <Description>
                        <NftTitle>{nft.collectionName}</NftTitle>
                        <NftDescription>
                          Descrição: {nft.description.replace("\\n", "").substring(0, 120)}...
                        </NftDescription>
                        <NftAuthor>Autor: {nft.creator}</NftAuthor>
                        {/* <NftTransaction>Transaction ID: {nft.transactionID}</NftTransaction> */}
                        <NftAuthenticyButton onClick={authNft}>
                          <div><div>Certificado de Autenticidade&nbsp;&nbsp;<MuiOpenInNewIcon fontSize="small"/></div></div>
                        </NftAuthenticyButton>
                      </Description>
                    </Grid>
                  </Grid>)
              })
            }
          </Carousel>
          <Grid item container xs={12} md={"auto"} justifyContent={"center"}>
            <GoToMyColletion onClick={e => navigate("/meuperfil")}>IR PARA MINHA COLEÇÃO</GoToMyColletion>
          </Grid> 


        </Grid>
      </Grid>
    </Grid>

  );
};

export default PurchaseCompleted;

export const Grid = styled(MuiGrid)`
  .gotocolletion{
    justify-content:center !important;
    align-items:center !important;
  }
`;
export const ZeImage = styled.div`
  width: 100%;
  aspect-ratio: 317/648 !important;
  background-image: url(${zBoughtComplete});
  background-size: cover;
  display: none;
  @media (min-width: 900px) {
    display: flex;
    padding: 0px 10px;
  }
  @media (min-width: 1200px) {
    display: flex;
    margin: 0px 80px;
  }
`;

export const Title = styled.h1`
  font-family: Bebas Neue;
  font-size: 7vw;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.015em;
  text-align: left;
  margin: 10px;
  width: auto;
  display: flex;
  @media (min-width: 600px) {
    font-size: 35px;
  }
  @media (min-width: 900px) {
    width:100%;
    margin: 10px 25px
  }
  @media (min-width: 1200px){
    font-size:55px;
    line-height:46px;
  }
`;

export const Description = styled.div`
  background-size: cover;
  width: 100%;
  aspect-ratio: 426/246;
  padding: 0px 10px;
  margin: 10px 0px;
  @media (min-width: 900px) {
    padding-right: 30px;
  }
`;
export const NftImage = styled.div`
  /* background-image: url("https://i.imgur.com/VfoFR9T.png"); */
  background-size: cover;
  width: 100%;
  aspect-ratio: 19/25;
  border-radius: 10px;
  display: flex;
  margin: 10px 0px;
`;

export const NftTitle = styled.p`
  font-family: Barlow Semi Condensed;
  font-size: 3.3vw;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;
  width: 100%;
  @media (min-width: 900px) {
    font-size:25px;
    width:90%;
  }
  @media (min-width: 1200px){
    font-size:30px;
    line-height:36px;
  }
`;

export const NftDescription = styled.p`
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  /*  font-size: 20px; */
  font-size: 2.6vw;
  line-height: 2.6vw;
  margin: 10px 0px;
  width: 100%;
  display:flex !important;
  text-align:justify;
  @media (min-width: 900px) {
    font-size:18px;
    line-height:18px;
    width:90%;
  }
  @media (min-width: 1200px){
    font-size:22px;
    line-height:26px;
  }
`;

export const NftAuthor = styled.p`
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 2.6vw;
  line-height: 2.6vw;
  margin: 10px 0px;
  width: 100%;
  @media (min-width: 900px) {
    font-size:18px;
    line-height: 18px;
  }
  @media (min-width: 1200px){
    font-size:22px;
    line-height:26px;
  }
`;

export const NftTransaction = styled.p`
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 2.6vw;
  line-height: 2.6vw;
  margin: 10px 0px;
  width: 100%;
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 18px;
  }
  @media (min-width: 1200px){
    font-size:22px;
    line-height:26px;
  }
`;

export const NftAuthenticyContainer = styled.div``;

export const NftAuthenticyButton = styled.button`
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  align-items: center;

  background: linear-gradient(#000 0 0) padding-box,
    linear-gradient(to bottom, #fb8404, #fe1e14) border-box !important;
  color: white;
  /* padding: 10px !important; */
  margin: 10px 0px;
  margin-top:30px;
  border: 3px solid transparent !important;
  border-radius: 15px !important;
  display: inline-block !important;
  height: 6.3vw !important;
  min-height: 40px; ;
  width: 100% !important;
  @media (min-width: 900px) {
    font-size:22px !important;
    line-height:19px !important;
    height:50px !important;
    width:90% !important;
  }
  text-align:center ;
  div{
    text-align:center;
    width:100%;
    justify-content:center ;
    display: flex;
    @media (max-width: 900px) {
      font-size: 15px ;
    }
  }
  :hover{
    cursor:pointer;
  }
`;

export const Carousel = styled(MultiCarousel)`
width:100%;

.react-multiple-carousel__arrow{
  height:50px !important;
  width:30px !important;
  border-radius:50%;
  animation:none !important;
  padding: 0px 0px;
  background: rgba(0,0,0,0) !important;
  margin-left:0px;
}

.react-multiple-carousel__arrow--left{
  transform:translate(-35px, 0px) !important;
}

.react-multi-carousel-dot button{
  height:6px;
  width:10px;
  padding:0px;
  background: white;
  margin:2px;
  border-radius:0px;
}
.carouselItem{
  /* background:red; */
  padding: 0 20px;
  @media (min-width: 900px) {
    padding: 20px 25px;
  }
}
`

export const MuiOpenInNewIcon = styled(OpenInNewIcon)`
  /* margin-top:5px; */
  display:flex;
`

export const GoToMyColletion = styled.div`
  display: flex;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px;
  padding: 5px 30px;
  cursor: pointer;
  :hover{
    transform: scale(1.01);
  }
  @media (min-width: 900px) {

  }
`
