import React from 'react';
import * as S from './style'
import Grid from "@mui/material/Grid";
import { InfiniteScroll } from '../../components/InfiniteScroll/InfiniteScroll';
import zBoughtComplete from "../../assets/ze-bought-complete.png"
import { UserType } from '../../types/UserType';
import { InfluencerType } from '../../types/InfluencerType';
import { PostsType } from '../../types/PostsType';
import { getAnyUserData, getCollectionsRequest, getInfluencerData, getInfluencerPosts, DeletePost } from '../../utils/requests';
import { Tab, /* Typography */ } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
/* import PointingZe from '../../assets/Ze_pointing.png' */
import { CollectionsType } from '../../types/CollectionsType';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "../../components/CustomComponents/ToastContainer/toast";

interface Post {
  author?: {
    name: string;
    username: string;
  };
  user_author?: {
    name: string;
    username: string
  }
  body: string;
  id: string;
  media: string;
  title: string;
  mimeType: string;
}

type props = {
  creatorUsername: string | undefined;
  refresh: Function
};

const Closers: React.FC<props> = ({ creatorUsername, refresh }) => {

  const [page, setPage] = React.useState(0);
  const [user, setUser] = React.useState<UserType>({ name: "" } as UserType);
  const [influencer, setInfluencer] = React.useState<InfluencerType>({ name: "", nftCollections: [], nfts: [] } as InfluencerType);
  const [viewingUser, setViewingUser] = React.useState<UserType>({ name: "" } as UserType);
  const [influencerViewing, setInfluencerViewing] = React.useState<InfluencerType>({ name: "" } as InfluencerType);
  const [postsArr, setPostsArr] = React.useState<PostsType[]>([]);
  const [closersTab, setClosersTab] = React.useState<string>("PUBLIC");
  const [collections, setCollections] = React.useState<CollectionsType[]>([]);
  const [deleteMenu, setDeleteMenu] = React.useState<boolean[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);
  const [postIndex, setPostIndex] = React.useState<number>(0);

  const toggleDeleteMenu = (index: any) => {
    const updatedMenu = [...deleteMenu];
    updatedMenu[index] = !updatedMenu[index];
    setDeleteMenu(updatedMenu);
  };

  React.useEffect(() => {
    let isCancelled = false;
    if (creatorUsername) getAnyUserData(creatorUsername).then(res => {
      if (!isCancelled && res) setUser(res);
    })
    return () => { isCancelled = true; };
  }, [creatorUsername]);

  React.useEffect(() => {
    let isCancelled = false;
    if (creatorUsername) getInfluencerData(creatorUsername).then(res => {
      if (!isCancelled && res.username) setInfluencer(res);
    })
    return () => { isCancelled = true; };
  }, [creatorUsername]);

  const localUserData = JSON.parse(localStorage.getItem("user")!);

  React.useEffect(() => {
    let isCancelled = false;
    getAnyUserData(localUserData?.username).then(res => {
      if (!isCancelled && res.username) setViewingUser(res);
    })
    return () => { isCancelled = true; };
  }, [localUserData?.username]);

  React.useEffect(() => {
    let isCancelled = false;
    getInfluencerData(localUserData?.username).then(res => {
      if (!isCancelled && res.username) setInfluencerViewing(res);
    })
    return () => { isCancelled = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* const mapUserNFTs = viewingUser.username ? viewingUser?.nfts?.map(o => o.creator?.username!) : influencerViewing?.nfts?.map(o => o.creator?.username!); */

  /* const checkOwnedNFT = mapUserNFTs?.includes(creatorUsername ? creatorUsername : ''); */

  const postObj: PostsType[] = []

  let posts: Post = postObj[0]

  const convertedObjToArr = Object.values(posts || {});

  React.useEffect(() => {
    if (creatorUsername && (closersTab === "PUBLIC")) getInfluencerPosts(page, 5, creatorUsername, closersTab).then((res) => {
      if (res[0].length > 0) {
        res[0].forEach((posts: PostsType) => {
          convertedObjToArr.push(posts)
        });
        setPostsArr(convertedObjToArr);
        if (res[0].length === 0) {
          setPage(0);
        }
      }
    });
    else if (creatorUsername && (closersTab !== "PUBLIC")) getInfluencerPosts(page, 5, creatorUsername, "COLLECTION", closersTab).then((res) => {
      if (res[0].length > 0) {
        res[0].forEach((posts: PostsType) => {
          convertedObjToArr.push(posts)
        });
        setPostsArr(convertedObjToArr);
      }
      if (res[0].length === 0) {
        setPage(0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, closersTab]);

  const requestAndGetCollections = async () => {
    const response = await getCollectionsRequest();
    if (response) return await response;
    return [];
  };

  React.useEffect(() => {
    const fetchdata = async () => {
      const data = await requestAndGetCollections();
      if (data?.length > 0) {
        setCollections(data)
      }
      else {
        //open toast with error loading message
      }
    };
    fetchdata();
  }, []);

  const matchCollections = collections.filter((collection) => collection.creator.username.includes(creatorUsername!));

  const mobile = useMediaQuery("(max-width: 992px)");

  const handleClosersTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setClosersTab(newValue);
    setPostsArr([]);
  };

  const handlePostDeletion = async(id: string) => {
    const data: PostsType = await DeletePost(id);
    if (data.media) {
      showToast("Post deletado com sucesso", "success");
    } else {
      showToast("Erro na deleção do post", "error");
    }
  };

  return (
    <>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Grid container item lg={3}>
        <S.VerticalTabs
          value={closersTab}
          onChange={handleClosersTabChange}
          aria-label="Abas do perfil"
          orientation={mobile ? "horizontal" : "vertical"}
        >
          <Tab value="PUBLIC" label="Público" />
          {matchCollections.length > 0 && matchCollections.map((collection) => {
            return (
              <Tab key={collection.id} value={collection.url_title} label={collection.title} />
            )
          })
          }
        </S.VerticalTabs>
      </Grid>
      <Grid container item lg={9}>
        {postsArr.length > 0 &&
          <S.ClosersGrid container justifyContent='center'>
            {(convertedObjToArr.length > 0 || (user.username && viewingUser?.username === user?.username) || (influencerViewing.username && influencerViewing?.username === influencer?.username)) &&
              <Grid container justifyContent="center">
                <Grid container className='ButtonGrid'>
                  {influencer.statusCode ? viewingUser.username !== user.username : influencerViewing?.username !== influencer.username &&
                    <>
                      <S.RedInfoIcon />
                      <p className='IconText'>Segredos e planos publicados por {user?.username ? `@${user?.username}` : `@${influencer?.username}`} 👀</p>
                    </>
                  }
                </Grid>
                <S.ScrollableGrid container justifyContent='center'>
                  {postsArr.map((post: PostsType, i: number) => {
                    return (
                      <>
                        <S.PostGrid item md={7} key={post.id}>
                          <Grid container className='InnerGrid'>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item md={3} xs={2} sm={2} lg={1} xl={1}>
                                <S.ClosersAvatar alt="Avatar do Influenciador" src={user?.avatar ? user?.avatar : influencer?.avatar} />
                              </Grid>
                              <S.ClosersAvatarNameGrid item md={9} xs={9} sm={10} lg={8} xl={8}>
                                <p>{post?.author?.name ? post.author.name : post.user_author.name}</p>
                                <p>@{post?.author?.username ? post.author.username : post.user_author.username}</p>
                              </S.ClosersAvatarNameGrid>
                              <S.PostMenuGrid container item lg={3} xl={3} flexDirection="column">
                                <MoreHorizIcon sx={{ cursor: "pointer" }} onClick={() => toggleDeleteMenu(i)} />
                                {deleteMenu[i] &&
                                  <S.PostOptionsGrid container sx={{ backgroundColor: "#000" }}>
                                    <p style={{ cursor: "pointer" }} onClick={() => { setShowDeleteConfirmation(true); setPostIndex(i) }} >Deletar post</p>
                                  </S.PostOptionsGrid>
                                }
                              </S.PostMenuGrid>
                            </Grid>
                            <Grid container>
                              <p>{post.body}</p>
                            </Grid>
                            <Grid container>
                              {post?.mimeType?.includes("image")
                                ?
                                <img src={post.media} alt='Imagem postada por influenciador' width='100%' height='100%' />
                                :
                                <video width='100%' height='100%' controls><source src={post.media} type="video/mp4" /></video>
                              }
                            </Grid>
                          </Grid>
                        </S.PostGrid>
                        {showDeleteConfirmation &&
                          <S.PostDeleteConfirmationGrid container>
                            <Grid container justifyContent="center">
                              <p>Deseja realmente apagar este post?</p>
                            </Grid>
                            <Grid container className="buttonGrid" item flexDirection="row" justifyContent="center">
                              <S.ConfirmButtonsGrid container item lg={5} md={5}>
                                <S.IconFab size="small" onClick={() => { setShowDeleteConfirmation(false); handlePostDeletion(postsArr[postIndex].id); setTimeout(() => { refresh(Math.random()) }, 1000); }}>
                                  <DoneIcon />
                                </S.IconFab>
                                <p>Confirmar exclusão</p>
                              </S.ConfirmButtonsGrid>
                              <S.ConfirmButtonsGrid container item lg={5} md={5}>
                                <S.IconFab size="small" onClick={() => setShowDeleteConfirmation(false)}>
                                  <CancelIcon />
                                </S.IconFab>
                                <p>Cancelar exclusão</p>
                              </S.ConfirmButtonsGrid>
                            </Grid>
                          </S.PostDeleteConfirmationGrid>
                        }
                      </>
                    );
                  })
                  }
                  <InfiniteScroll callback={() => setPage((previousPage) => previousPage + 1)} />
                  {/* {showDeleteConfirmation &&
                    <S.PostDeleteConfirmationGrid container>
                      <Grid container justifyContent="center">
                        <p>Deseja realmente apagar este post?</p>
                      </Grid>
                      <Grid container className="buttonGrid" item flexDirection="row" justifyContent="center">
                        <S.ConfirmButtonsGrid container item lg={5} md={5}>
                          <S.IconFab size="small" >
                            <DoneIcon />
                          </S.IconFab>
                          <p>Confirmar exclusão</p>
                        </S.ConfirmButtonsGrid>
                        <S.ConfirmButtonsGrid container item lg={5} md={5}>
                          <S.IconFab size="small" onClick={() => setShowDeleteConfirmation(false)}>
                            <CancelIcon />
                          </S.IconFab>
                          <p>Cancelar exclusão</p>
                        </S.ConfirmButtonsGrid>
                      </Grid>
                    </S.PostDeleteConfirmationGrid>
                  } */}
                </S.ScrollableGrid>
              </Grid>
            }
          </S.ClosersGrid>
        }
        {postsArr.length === 0 &&
          <>
            <S.EmptyClosersGrid container justifyContent='center'>
              <S.CreationGrid container item xs={4} lg={2}>
                <img src={zBoughtComplete} className='PointingImg' alt='Mascote Zé apontando para a direita'></img>
              </S.CreationGrid>
              <Grid item xs={7} lg={5}>
                <S.EmptyClosersText>{user?.username ? `@${user?.username}` : `@${influencer?.username}`} ESTÁ<br /> PLANEJANDO SUAS PUBLICAÇÕES...</S.EmptyClosersText>
              </Grid>
            </S.EmptyClosersGrid>
          </>
        }
      </Grid>
    </>
  )
}

export default Closers