import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import { GradientButton as Button } from "../../../components/CustomComponents/GradientButton/style";

export const Grid = styled(MuiGrid)
`
`;

export const OutlinedText = styled(MuiGrid)
`
  p {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Black Han Sans;
    font-size: 25vw;
    font-style: normal;
    font-weight: 400;
    line-height: 103%;
    letter-spacing: 0.05em;
    text-align: center;
    overflow-y: hidden;
    color: rgb(0, 0, 0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.16);
  }
  @media (min-width: 900px) {
    p {
      font-size: 15vw;
      justify-content: flex-end;
      padding-top: 30px;
    }
  }
`;

export const TextWrapper = styled(MuiGrid)
`
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 7.5vw;
  line-height: 84.5%;
  letter-spacing: 0.015em;
  /* or 59px */
  text-align: right;
  padding-right: 10vw;
  padding-left: 10vw;
  color: #ffffff;
  transform: translate(0px, -30%);
  justify-content: flex-end;
  p {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  @media (min-width: 900px) {
    font-size: 5vw;
    transform: translate(0px, -26%);
    padding-right: 3vw;
    padding-left: 3vw;
    p {
      justify-content: center;
      width:100%;
    }
  }
`;


export const MobileImage = styled(MuiGrid)
`
  /* padding-top:80px; */
  img {
    text-align: center;
    width: 100%;
  }
  @media (min-width: 900px) {
    display: none;
  }
`;

export const DesktopImage = styled(MuiGrid)
`
  padding-right: 6vw;
  padding-left: 3vw;
  img {
    width: 100%;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
export const GradientButton = styled(Button)
`
  padding: 0px 50px;
  display:flex;
  margin-top:30px;
  
`