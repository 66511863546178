import { createContext, useState } from "react";

export const NFTListContext = createContext();

export const NFTListProvider = ({ children }) => {

	const [nftList, setNftList] = useState();

	return (
		<NFTListContext.Provider value={{ nftList, setNftList }}>
			{children}
		</NFTListContext.Provider>
	);
};