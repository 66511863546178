import React from "react";
import * as S from "./style";
import { useNavigate } from "react-router-dom";
import { changePassword, forgotPassword } from "../../utils/requests";
//toast
import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "./../../components/CustomComponents/ToastContainer/toast";
import { useParams } from "react-router-dom";
import { validateEmail } from "../../utils/sharedfunctions";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';



const RecoverPassword: React.FC = () => {
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { token } = useParams();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);

  const [email, setEmail] = React.useState<{
    email: string;
    valid: boolean;
  }>({ email: "", valid: false });

  const [password, setPassword] = React.useState<{
    password: string;
    valid: boolean;
  }>({ password: "", valid: true });

  const [repeatPassword, setRepeatPassword] = React.useState<{
    password: string;
    valid: boolean;
  }>({ password: "", valid: true });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword((prevState) => {
      return {
        ...prevState,
        password: e.target.value,
        valid:
          20 >= e.target.value.length && e.target.value.length >= 4
            ? true
            : false,
      };
    });
  };

  const handleRepeatPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword((prevState) => {
      return {
        ...prevState,
        password: e.target.value,
        valid:
          20 >= e.target.value.length && e.target.value.length >= 4
            ? true
            : false,
      };
    });
  };

  const handleChangePasswordClick = () => {
    if (password.password === repeatPassword.password) {
      if (password.password.length >= 4 && password.password.length <= 20) {
        if (!!token) {
          changePassword(token, password.password).then((res) => {
            if (res.email) {
              showToast("Senha alterada com sucesso! Redirecionando para login!", "success");
              setTimeout(() => {
                navigate("/login");
              }, 3000)
            } else showToast("Erro desconhecido ao alterar a senha", "error");
          })
        } else showToast("Não foi possível continuar: token inexistente.", "error");

      } else showToast("A senha precisa ter entre 4 e 20 caracteres", "error");
    } else {
      showToast("Senhas não conferem", "error");
    }
  }

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    //Login, ForgotPassword, Transaction, CreateIntent
    const token = await executeRecaptcha('ForgotPassword');
    setRecaptchaToken(token);
    return token;
  }, [executeRecaptcha]);

 

  const handleGetToken = () => {
    if(email.valid === false && !recaptchaToken) return;
    else{
      forgotPassword(email.email, recaptchaToken!);
      showToast("Email solicitado com sucesso! Verifique sua caixa de entrada.", "success");
      setEmail({...email, valid: false});
      handleReCaptchaVerify();
    }

  }

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  if (token === "solicitartoken") return (
    <S.MainContainer container>
      <S.Grid item xs={12} md={6} lg={5} container>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <S.ViuzzV />
        <h1>Solicitar Senha</h1>
        <form>
          <S.Grid item container xs={12}>
            <S.Grid item container xs={12}>
              <S.TextField
                name="email"
                type={"email"}
                placeholder="E-mail"
                value={email.email}
                onChange={
                  (e) => {
                    setEmail({
                      email: e.target.value,
                      valid: validateEmail(e.target.value)
                    })
                  }
                }
                error={!email.valid}
              />
            </S.Grid>

            <S.Grid item container xs={12}>
              <button
                className="login"
                onClick={(e) => {
                  e.preventDefault();
                  if(email.valid) handleGetToken();
                }}
                disabled={!email.valid}
              >
                Solicitar nova senha
              </button>
            </S.Grid>
          </S.Grid>
        </form>
      </S.Grid>
    </S.MainContainer>
  )

  return (
    <S.MainContainer container>
      <S.Grid item xs={12} md={6} lg={5} container>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <S.ViuzzV />
        <h1>Digite sua nova senha!</h1>
        <form>
          <S.Grid item container xs={12}>
            <S.Grid item container xs={12}>
              <S.TextField
                name="password"
                type={"password"}
                placeholder="Nova Senha"
                value={password.password}
                onChange={handlePasswordChange}
                // inputRef={register}
                error={!password.valid}
              />
            </S.Grid>
            <S.Grid item container xs={12}>
              <S.TextField
                name="repeatPassword"
                type={"password"}
                placeholder="Repetir senha"
                value={repeatPassword.password}
                onChange={handleRepeatPasswordChange}
                // inputRef={register}
                error={!repeatPassword.valid}
              />
            </S.Grid>
            <S.Grid item container xs={12}>
              <button
                className="login"
                onClick={(e) => {
                  e.preventDefault();
                  handleChangePasswordClick();
                }}
              >
                Alterar Senha
              </button>
            </S.Grid>
          </S.Grid>
        </form>
      </S.Grid>
    </S.MainContainer>
  );
};

export default RecoverPassword;
