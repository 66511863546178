import styled from "styled-components";
import { Fab, Grid as MuiGrid } from "@mui/material";
import { ReactComponent as ArrowRight } from "../../../assets/ArrowRight.svg";

export const MainContainer = styled(MuiGrid)``;
export const CardContainer = styled(MuiGrid)`
  background-repeat: no-repeat;
  background-size: contain;
  width:100%;
  aspect-ratio: 19/22;
  border-radius: 10px;
  margin:10px;
  text-align: left !important;
  justify-content: flex-end;
  cursor:pointer !important;
    div.card{
      width:100%;
      aspect-ratio: 19/22;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 10px;
      @media (min-width: 1600px) {
        min-height: 380px;
      }
      @media (max-width: 992px) {
        min-height: 150px;
      }
      min-height: 300px;
    }
    div.unavailable{
      filter: saturate(0)
    }
  :hover {
    div.details{
      opacity:1;
      /* display:block !important; */
    }
  }
  /* border-style: solid; */

  .defaultFiller {
    aspect-ratio: 500/490;
  }
  .unavailableFiller {
    aspect-ratio: 460/355;
  }

  img {
    max-width: 100%;
    display: flex;
    padding: 0px !important;
    margin: 0px !important;
    border-radius: 10px;
  }
`;

export const CardInfoTest = styled.div`
  width:100% ;
  aspect-ratio: 595/195 !important;
  display:block;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.116);
  box-sizing: border-box;
  backdrop-filter: blur(30px);

  border-radius: 0px 0px 10px 10px;
  border-width: 1px;
  @media (min-width:900px){
    opacity:0%;
  }
  h2 {
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 3.2vw;
    line-height: 3vw;
    display: flex;
    color: #ffffff;
    padding-left: 0.5vw;
    margin: 0px;
    margin-bottom:0px !important;
    padding-top:2vw;
    padding-bottom:3px;
    overflow:hidden;

    width:100%;
    white-space: pre-line;

  }
  h3 {
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 3vw;
    line-height: 3vw;
    
    /* identical to box height */

    display: inline-flex;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.7);
    margin:0px;
    padding:0px;
    padding-left:1.8vw;
    :hover{
      cursor: pointer;
      transform:translate(0px,-2px);
    }
  }
  @media (min-width: 900px) {
    h2 {
      line-height: 3vw;
      padding-left: 1.3vw;
      margin-top: 0.3vw;
      font-size: 2.6vw;
    }
    h3{
      font-size: 1.8vw;
      line-height: 60px;
      padding-left: 1.3vw;
    }
  }
  @media (min-width: 1200px) {
    h2 {
      padding-left: 10px;
      margin-top: 0.5vw;
      padding-top:0px;
      font-size: 1.5em;
      line-height: 1em;
      max-width: fit-content;
    }
    h3{
      font-size:1.1em;
      line-height:0.9em;
      padding-left:10px;
      padding-bottom:3px;
    }
  }
  @media (min-width: 1440px) {
    h2 {
      padding-left: 20px;
      margin-top: 0px;
      font-size: 2em;
      line-height: 1em;
      padding-top: 0.8vw;
    }
    h3{
      padding-left:20px;
    }
  }
`;

export const ArrowRightIcon = styled(ArrowRight)`
  display: inline-flex;
  width: 2.5vw !important;
  padding: 8px;
  :hover {
    cursor: pointer;
  }
  @media (min-width: 900px) {
    width: 4vw;
    padding: 0px;
    padding-right: 12px;
    padding-bottom: 10px;
  }
  @media (min-width: 1200px) {
    width: 2.3vw;
    padding-bottom: 13px;
    padding-right: 15px;
  }
`;
export const Divider = styled.div`
  height: 2px;
  width: 100%;
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(251, 132, 4, 1) 0%,
    rgba(254, 30, 20, 1) 100%
  );
  padding: 0px;
  margin: 0px;
  z-index: 3;
  @media (min-width:900px){
    opacity:0 ;
  }
`;
export const QuantityFab = styled(Fab)`
  position: absolute !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  width: 2rem !important;
  height: 2rem !important;
  min-height: 0 !important;
`
export const UnavailableGrid = styled(MuiGrid)`
  width: 100%;
  aspect-ratio: 2000 / 490;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(30px);
  max-height: 40px;
  
  p {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
`
export const SpaceFiller = styled(MuiGrid)`
  width: 100%;
`