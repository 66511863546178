import { Tab } from '@mui/material';
import React from 'react';
import { getUserCollectibles, getUserData } from "../../utils/requests";
import Navbar from "../../components/Navbar/Navbar";

import * as S from './style'
import Profile from './Profile/Profile';
import { BillingAddressType } from '../../types/BillingAddressType';
import { CollectibleType } from '../../types/CollectibleType';
import CollectibleList from '../Collectibles/CollectibleList/CollectibleList';
import { UserType } from '../../types/UserType';
import ChangePassword from './ChangePassword/ChangePassword';

type props = {
  auth: boolean;
  setAuth: (auth: boolean) => void;
}
const MyProfile: React.FC<props> = ({ auth, setAuth }) => {
  type TabType = 'profile' | 'mycollection' | 'password' | 'address';
  //eslint-disable-next-line
  const [billingAddress, setBillingAddress] = React.useState<BillingAddressType>({} as BillingAddressType);
  const [collectibles, setCollectibles] = React.useState<CollectibleType[]>([]);
  const [currentTab, setCurrentTab] = React.useState<TabType>("mycollection");
  //eslint-disable-next-line
  const [user, setUser] = React.useState<UserType>({} as UserType);

  // React.useEffect(() => {
  //   let isCancelled = false;
  //   getBillingAddress().then((res) => {
  //     if (!isCancelled && res?.data) setBillingAddress(res?.data);
  //   })
  //   return () => { isCancelled = true; };
  // }, []);

  React.useEffect(() => {
    let isCancelled = false;
    getUserCollectibles(user.id).then((res) => {
      if (!isCancelled && res?.nfts) setCollectibles(res?.nfts);
    })
    const localUserData = JSON.parse(localStorage.getItem("user")!);
    getUserData(localStorage.getItem("token") ?? "", localUserData.id).then(res => {
      if (!isCancelled && res?.email) setUser(res);
    })
    return () => { isCancelled = true; };
  }, [user.id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setCurrentTab(newValue);
  };

  const flatCollectibles = collectibles.flat();

  return (
    <>
      <Navbar auth={auth} setAuth={setAuth} />
      <S.MainContainer container justifyContent={"center"}>
        <S.Grid item container xs={12} justifyContent={"center"}>
          <S.Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="Abas do perfil"
            centered
          >
            <Tab value="mycollection" label="Minha coleção" />
            <Tab value="profile" label="Perfil" />
            {/* <Tab value="payment" label="Pagamento" /> */}
            <Tab value="password" label="Senha" />
          </S.Tabs>
          <div className="linear-gradient-line"></div>
        </S.Grid>

        <S.CenteredContainer item container justifyContent={"center"} xs={12}>
          {currentTab === "profile" && <Profile user={user} setUser={setUser} />}
        </S.CenteredContainer>

        { currentTab === "mycollection" &&
          (<S.CenteredContainer>
              { collectibles.length > 0 ? 
                <CollectibleList collectibleList={flatCollectibles} ownerDetails={true} showOwned={true}/> :
                <h1>Você não possui nfts em sua coleção.</h1>
              }
          </S.CenteredContainer>)
        }
        
        {currentTab === "password" && (
          <S.CenteredContainer>
            <ChangePassword user={user} />
          </S.CenteredContainer>
        )}

      </S.MainContainer>
    </>
  )
}

export default MyProfile