import React from "react";
import * as S from "./style";

import useShoppingCart from "../../hooks/useShoppingCart";
import { getCollectiblesRequest } from "../../utils/requests";
import { CollectibleType } from "../../types/CollectibleType";
import CollectibleList from './CollectibleList/CollectibleList';
import CollectibleListSkeleton from "./Skeletons/CollectibleListSkeleton";

type ColecionaveisProps = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const Colecionaveis: React.FC<ColecionaveisProps> = ({ auth, setAuth }) => {
  //eslint-disable-next-line
  const [shoppingCart, setShoppingCart] = useShoppingCart();

  const requestAndGetCollectibles = async () => {
    const response = await getCollectiblesRequest();
    if (response.nfts) return await response.nfts;
    if (response.error) {
      //you could set errors or call toast notification here
    }

    return [];
  };

  const [collectibles, setCollectibles] = React.useState<CollectibleType[] | undefined>(undefined);

  React.useEffect(() => {
    const fetchdata = async () => {
      const data = await requestAndGetCollectibles();
      if (data?.length > 0) {
        setCollectibles(data)
      }
      else {
        //open toast with error loading message

      }
    };
    fetchdata();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  scrollToTop();

  const flatNfts = collectibles?.flat();

  return (
    <>
      <S.NavBar
        cartItemCount={shoppingCart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <S.MainContainer
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <S.Container container item>
          <S.TitleContainer item xs={12}>
            <S.Title> Coleções </S.Title>
          </S.TitleContainer>
          <S.CardListContainer container item xs={12}>
            {flatNfts 
            ? <CollectibleList collectibleList={flatNfts} /> 
            : <CollectibleListSkeleton />}
            {/* <CollectibleListSkeleton /> */}
          </S.CardListContainer>
        </S.Container>
      </S.MainContainer>
      <S.Footer />
    </>
  );
};

export default Colecionaveis;
