import React from "react";
import * as S from "./style";

import useShoppingCart from "../../hooks/useShoppingCart";
import { getCollectionsRequest, getProjectsRequest, getTrending } from "../../utils/requests";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";
import { ProjectType } from "../../types/ProjectType";
import ProjectCard from "./CollectibleList/ProjectCard";
import { CollectionsType } from "../../types/CollectionsType";

import ViuzzWebBanner from "../../assets/web-viuzz-banner.png"
import ViuzzMobileBanner from "../../assets/mobile-viuzz-banner.png"
import BossWebBanner from "../../assets/web-boss-banner.png"
import BossMobileBanner from "../../assets/mobile-boss-banner.png"
import ZeWebBanner from "../../assets/web-ze-banner.png"
import ZeMobileBanner from "../../assets/mobile-ze-banner.png"

type ColecionaveisProps = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewColecionaveis: React.FC<ColecionaveisProps> = ({ auth, setAuth }) => {
  //eslint-disable-next-line
  const [shoppingCart, setShoppingCart] = useShoppingCart();
  const [isDragging, setIsDragging] = React.useState<boolean>();

  const requestAndGetProjects = async () => {
    const response = await getProjectsRequest();
    if (response) return await response;
    return [];
  };

  const requestAndGetCollections = async () => {
    const response = await getCollectionsRequest();
    if (response) return await response;
    return [];
  };

  const [projects, setProjects] = React.useState<ProjectType[]>([]);
  const [collections, setCollections] = React.useState<CollectionsType[]>([]);
  const [trending, setTrending] = React.useState<CollectionsType[]>([]);

  const newArr: Array<ProjectType[] | CollectionsType[]> = [];

  React.useEffect(() => {
    const fetchdata = async () => {
      const data = await requestAndGetProjects();
      if (data?.length > 0) {
        setProjects(data)
      }
      else {
        //open toast with error loading message
      }
    };
    fetchdata();
  }, []);

  React.useEffect(() => {
    const fetchdata = async () => {
      const data = await getTrending();
      if (data?.length > 0) {
        setTrending(data)
      }
      else {
        //open toast with error loading message
      }
    };
    fetchdata();
  }, []);

  React.useEffect(() => {
    const fetchdata = async () => {
      const data = await requestAndGetCollections();
      if (data?.length > 0) {
        setCollections(data)
      }
      else {
        //open toast with error loading message
      }
    };
    fetchdata();
  }, []);

  newArr.push(collections);
  newArr.push(projects);
/*   const flatArr = newArr.flat(); */

  const disableLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isDragging) {
      event.preventDefault();
    }
  };

  const responsiveBanner = {
    oneItemAll: {
      breakpoint: { max: 5000, min: 0 },
      items: 1,
      partialVisibilityGutter: 100,
    },
  };

  const responsiveCarousel = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1920 },
      items: 8,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 1920, min: 1024 },
      items: 7,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <>
      <S.NavBar
        cartItemCount={shoppingCart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <S.MainContainer
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
      </S.MainContainer>
      <S.GridContainer>
        <S.CarouselGrid>
          <S.Wrapper>
            <Carousel
              responsive={responsiveBanner}
              infinite
              swipeable
              draggable
              centerMode
              itemClass="slider-image-item"
              showDots
              focusOnSelect
              beforeChange={() => setIsDragging(true)}
              afterChange={() => setIsDragging(false)}
            >
              <S.SlideWrapper>
                <S.BannerLink to={""} onClick={(event) => {disableLink(event); window.location.href = 'mailto:tecnologia@viuzz.com.br'}} draggable={false}>
                  <S.BannerImage src={ViuzzWebBanner} mobileSrc={ViuzzMobileBanner} draggable={false} className="banner-image" />
                </S.BannerLink>
                <S.BannerDescription className="banner-description">Envie ja sua idéia!</S.BannerDescription>
              </S.SlideWrapper>
              <S.SlideWrapper>
                <S.BannerLink to={"/colecoes/the_big_boss"} onClick={(event) => disableLink(event)} draggable={false}>
                  <S.BannerImage src={BossWebBanner} mobileSrc={BossMobileBanner} draggable={false} className="banner-image" />
                </S.BannerLink>
                <S.BannerDescription className="banner-description">The Big Bosses Collection , já disponível!</S.BannerDescription>
              </S.SlideWrapper>
              <S.SlideWrapper>
                <S.BannerLink to={"/colecoes/geracao_ze"} onClick={(event) => disableLink(event)} draggable={false}>
                  <S.BannerImage src={ZeWebBanner} mobileSrc={ZeMobileBanner} draggable={false} className="banner-image" />
                </S.BannerLink>
                <S.BannerDescription className="banner-description">Geração Zê, já disponível!</S.BannerDescription>
              </S.SlideWrapper>
            </Carousel>
          </S.Wrapper>
        </S.CarouselGrid>
        <Grid>
          <p className="categories-text">TENDÊNCIAS</p>
        </Grid>
        <S.BottomCarouselGrid>
          <Carousel
            responsive={responsiveCarousel}
            showDots={true}
            swipeable={true}
            draggable={true}
            centerMode={true}
            infinite
            arrows={true}
            beforeChange={() => setIsDragging(true)}
            afterChange={() => setIsDragging(false)}
          >
            {trending?.map((collection) => {
              return (
                <S.BannerLink to={"/colecoes/" + collection.url_title} onClick={(event) => disableLink(event)} draggable={false} key={collection.id}>
                  <ProjectCard project={collection} xs={12} md={12} lg={12} key={collection.id}/>
                </S.BannerLink>
              )
            })
            }
          </Carousel>
        </S.BottomCarouselGrid>
        <Grid>
          <p className="categories-text">PROJETOS</p>
        </Grid>
        <S.BottomCarouselGrid>
          <Carousel
            responsive={responsiveCarousel}
            showDots={true}
            swipeable={true}
            draggable={true}
            centerMode={true}
            infinite
            arrows={true}
            beforeChange={() => setIsDragging(true)}
            afterChange={() => setIsDragging(false)}
          >
            {projects?.map((project) => {
              return (
                <S.BannerLink to={"/projetos/" + project.url_title} onClick={(event) => disableLink(event)} draggable={false} key={project.id}>
                  <ProjectCard project={project} xs={12} md={12} lg={12} key={project.id}/>
                </S.BannerLink>
              )
            })
            }
          </Carousel>
        </S.BottomCarouselGrid>
      </S.GridContainer>
      <S.Footer />
    </>
  );
};

export default NewColecionaveis;