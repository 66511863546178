import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createTheme({
  unstable_strictMode: true,
  typography: {
    fontFamily: 'Barlow Semi Condensed, sans-serif',
  },
  palette: {
    type: 'dark',
    background: {
      default: '#10111d',
    },
    primary: {
      main: '#FD700B',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FE1E14',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 23,
        fontSize: 28,
        paddingLeft: 93,
        paddingRight: 93,
        textTransform: 'none',
      },
      sizeLarge: {
        borderRadius: 25,
        fontSize: 30,
        paddingLeft: 100,
        paddingRight: 100,
      },
    },
    MuiDialog: {
      paper: {
        background: 'linear-gradient(180deg, #222c5d 0%, #121933 100%)',
      },
    },
    MuiSnackbarContent: {
      message: {
        fontSize: '1rem',
      },
    },
  },
});

export default responsiveFontSizes(theme);
