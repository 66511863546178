import styled from "styled-components";
import {
  Grid as MuiGrid,
  Checkbox as CheckboxBase,
  FormControlLabel as FormControlLabelBase,
} from "@mui/material";
import { ReactComponent as ViuzzVSVG } from "../../assets/ViuzzV.svg";
import BackgroundV from "../../assets/bigViuzz.svg";
import Input from "../../components/Input/input";

export const MainContainer = styled(MuiGrid)`
  height: 100vh !important;
  background-image: url(${BackgroundV});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  @media (min-width: 575px) {
    justify-content: start;
  }
  h1 {
    text-align: center;
    display: flex;
    color: rgb(253, 112, 11);
    font-size: 3rem;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 10px;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
`;
export const Grid = styled(MuiGrid)`
  justify-content: center;
  text-align: center;
  form {
    width: 100%;
    display: flex;
  }
  button.login {
    color: white;
    background-color: #fd700b;
    border-radius: 23px;
    display: block;
    width: 100%;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    border-width: 0px;
    text-align: center;
    height: 65px;
    cursor: pointer;
    @media (min-width: 320px) {
      width: 320px;
    }
    @media (min-width: 420px) {
      width: 420px;
    }
    :hover {
      background-color: #78420b;
      transition-duration: 250ms;
    }
  }
  a.forgotPassword {
    font-family: Barlow Semi Condensed, sans-serif;
    margin-top: 30px;
    margin-bottom: 35px;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 24px;
    color: #fd700b;
    :hover {
      color: #78420b;
      transition-duration: 250ms;
    }
  }
  a.newAccount {
    font-family: Barlow Semi Condensed, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #fd700b;
    font-size: 1.25rem;
    font-weight: 600;
    span {
      font-weight: 700;
    }
    :hover {
      color: #78420b;
      transition-duration: 250ms;
      cursor: pointer;
    }
  }
`;
export const ViuzzV = styled(ViuzzVSVG)`
  position: relative;
  height: 70px;
  width: 100%;
  margin-right: 70px;
`;
export const TextField = styled(Input)`
  margin: 10px;
  @media (min-width: 320px) {
    width: 320px !important;
  }
  @media (min-width: 420px) {
    width: 420px !important;
  }

  [name="password"] {
    margin-top: 0px;
  }
`;

export const FormControlLabel = styled(FormControlLabelBase)`
  && {
    color: rgb(253, 112, 11);
    font-size: 10px;
    font-weight: 500;
    margin-left: 10px;
    @media (min-width: 420px) {
      width: 420px;
    }
    .Mui-checked {
      color: rgb(253, 112, 11);
    }
  }
`;

export const Checkbox = styled(CheckboxBase)`
  && {
    color: rgb(253, 112, 11);
  }
`;
