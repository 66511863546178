import styled from "styled-components";
import {
  Grid as MuiGrid,
  List as MuiList,
  ListItemText as MuiListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Divider as MuiDivider } from "@mui/material";

export const MainContainer = styled(MuiGrid)``;
export const Grid = styled(MuiGrid)`
  overflow-x: hidden !important;
  font-size: 3.5vw;
  @media (min-width: 900px) {
    font-size:2.5vw;
  }
`;

export const More = styled(ExpandMore)`
  font-size: 30px !important;
  padding-top: 1vw !important;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;
export const Less = styled(ExpandLess)`
  font-size: 30px !important;
  padding-top: 1vw !important;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;
export const Divider = styled(MuiDivider)`
  width: 80vw;
  color: white;
  background-color: white;
  height: 1px !important;
  @media (min-width: 900px) {
    width: 65vw;
  }
`;
export const List = styled(MuiList)`
  padding: 12vw !important;
  font-size: 5vw;
  overflow-x:hidden !important;
  @media (min-width: 900px) {
    padding: 18vw !important;
    padding-top: 2vw !important;
    font-size: 2.5vw;
  }
`;
export const ListItemText = styled(MuiListItemText)`
  margin: 2vw !important;
  margin-top: 0px !important;
  font-family: Barlow Semi Condensed !important;
  font-style: normal;
  font-weight: bold;
  font-size: 2vw;
`;
export const FaqTitle = styled(MuiGrid)`
  padding: 2.5vw;
  span {
    margin-top: 4vw;
    font-family: Barlow Semi Condensed !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 3.5vw;
  }
  @media (min-width: 900px) {
    padding: 0vw;
    padding-left: 10vw;
    span {
      font-size: 2.5vw;
    }
  }
`;

export const WhiteStrap = styled.div`
  height: 3.8vw;
  width: 102%;
  display: inline-flex;
  background: #ffffff;
  transform: rotate(1.5deg) translate(-5px, 0px);
`;
export const OrangeStrap = styled.div`
  height: 3.8vw;
  width: 102%;
  display: inline-flex;
  background: #fc5d0a;
  transform: rotate(-1.5deg) translate(-5px, 0px);
`;
