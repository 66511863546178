import React from "react";
import * as S from "./style";
import avatardemo from "../../../assets/avatar-demo.png";
interface CounterProps {}

const Avatar: React.FC<CounterProps> = () => {
  return (
    <>
      <S.Container container justifyContent={{ xs: "center", md: "flex-end" }}>
        <S.Grid item display={{ xs: "12", md: "none" }}>
          <img className="avatardemo" src={avatardemo} alt="exemplo de avatar" />
        </S.Grid>
        
      </S.Container>

      <S.Container container>
        <S.Grid item display={{ xs: "none", md: "inline-flex" }} md={5}>
          <img className="avatardemo" src={avatardemo} alt="exemplo de avatar"/>
        </S.Grid>

        <S.TextWrapper
          item
          md={7}
          container
          textAlign={{ xs: "center", md: "left" }}
          justifyContent={{ xs: "center" }}
        >
          <S.OutlinedText item xs={12}>
          <p>
            AVATAR <br />
            VIUZZ
          </p>
        </S.OutlinedText>
          <S.TextContainer item container xs={12}>
            <span>
              USE SEU AVATAR VIUZZ PARA TRAZER PERSONALIDADE AO MUNDO VIRTUAL EM
              SEUS APPS
              <S.Grid item xs={12} textAlign={"center"}>
                {/* <S.Button>VER AVATARES DISPONÍVEIS</S.Button> */}
                <S.Button style={{background: 'darkgray'}}>EM BREVE</S.Button>
              </S.Grid>
            </span>
          </S.TextContainer>
        </S.TextWrapper>
      </S.Container>
    </>
  );
};

export default Avatar;
