import React from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import * as S from "./style";

//create interface extenging BadgeProps
interface Props extends BadgeProps {
  cartItemCount: number;
}

const CartIconBadge: React.FC<Props> = (props) => {
  return (
    <Badge>
      <IconButton aria-label="cart">
        <S.Badge badgeContent={props.cartItemCount}>
          <ShoppingCartIcon />
        </S.Badge>
      </IconButton>
    </Badge>
  );
};
export default CartIconBadge;