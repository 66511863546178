import React from "react";
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import * as S from './style'
import { useFilePicker } from 'react-sage';
import { createClosersPost, getCollectionsRequest } from "../../../utils/requests";
import DeleteIcon from '@mui/icons-material/Delete';
import showToast from "../../../components/CustomComponents/ToastContainer/toast";
import * as T from "../../../components/CustomComponents/ToastContainer/style";
import { CollectionsType } from "../../../types/CollectionsType";

type props = {
	avatar?: string | undefined
	closeFunc: Function
	userUsername?: string | undefined
	influencerUsername?: string | undefined
	refresh: Function
};

const ClosersModal: React.FC<props> = ({ avatar, closeFunc, userUsername, influencerUsername, refresh }) => {

	const [text, setText] = React.useState<string>("");
	const [postAvatar, setPostAvatar] = React.useState<File>()
	const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(undefined);
	const [visibility, setVisibility] = React.useState<string>("PUBLIC");
	const [collections, setCollections] = React.useState<CollectionsType[]>([]);
	const [collectionUrlTitle, setCollectionUrlTitle] = React.useState<string>("");

	const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

	React.useEffect(() => {
		const getDataUrls = async () => {
			if (imageInput.files && imageInput.files[0]) {
				setPostAvatar(imageInput.files[0]);
				const reader = new FileReader();
				reader.readAsDataURL(imageInput.files[0]);
				reader.onload = () => {
					setPreviewUrl(reader.result as string);
				};
			};
		};
		getDataUrls();
	}, [imageInput.files]);

	const requestAndGetCollections = async () => {
		const response = await getCollectionsRequest();
		if (response) return await response;
		return [];
	};

	React.useEffect(() => {
		const fetchdata = async () => {
			const data = await requestAndGetCollections();
			if (data?.length > 0) {
				setCollections(data)
			}
			else {
				//open toast with error loading message
			}
		};
		fetchdata();
	}, []);

	const matchCollections = collections.filter((collection) => collection.creator.username.includes(userUsername || influencerUsername!));

	/* 	const localUserData = JSON.parse(localStorage.getItem("user")!);
		const userId = localUserData.oobId; */

	const publishPost = () => {
		createClosersPost({
			body: text,
			media: postAvatar,
			visibility: (visibility === "PUBLIC") ? visibility : "COLLECTION",
			author: influencerUsername,
			user_author: userUsername,
			collectionUrlTitle: (visibility === "PUBLIC") ? undefined : visibility,
		}).then(res => {
			if (res.body) {
				showToast(`Post publicado com sucesso`, "success");
				refresh(Math.random());
				setTimeout(() => closeFunc(false), 1000);
			}
			else {
				showToast(`Erro na publicação, tente novamente`, "error");
			}
		})
	};

	const deleteAvatar = () => {
		setPostAvatar(undefined);
		setPreviewUrl(undefined);
	}

	const handleChange = (event: SelectChangeEvent) => {
		setVisibility(event.target.value as string);
	};
	const handleUrlTitleChange = (event: SelectChangeEvent) => {
		setCollectionUrlTitle(event.target.value as string);
	};

	return (
		<S.ClosersModal container>
			<T.ToastContainer
				position="top-center"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<S.ModalContainer container>
				<Grid container justifyContent="right" onClick={() => closeFunc(false)}>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="xButton">
						<path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white" />
					</svg>
				</Grid>
				{postAvatar &&
					<Grid container className="send">
						<Grid container className="SendGrid">
							{previewUrl?.includes("image")
								?
								<img src={previewUrl} className="previewImg" alt="Preview da imagem"></img>
								:
								<video src={previewUrl} width='90%' height='90%' controls className="previewVid"></video>
							}
							<S.DeleteFab size="small" onClick={() => deleteAvatar()}>
								<DeleteIcon />
							</S.DeleteFab>
						</Grid>
					</Grid>
				}
				{!postAvatar &&
					<Grid container className="send" onClick={imageInput.onClick}>
						<svg width="119" height="81" viewBox="0 0 119 81" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M95.9438 30.7813C92.5721 13.6751 77.5483 0.833008 59.5 0.833008C45.1704 0.833008 32.725 8.96467 26.5271 20.8647C11.6025 22.4513 0 35.0951 0 50.4163C0 66.8284 13.3379 80.1663 29.75 80.1663H94.2083C107.893 80.1663 119 69.0597 119 55.3747C119 42.2847 108.835 31.6738 95.9438 30.7813ZM69.4167 45.458V65.2913H49.5833V45.458H34.7083L59.5 20.6663L84.2917 45.458H69.4167Z" fill="#323232" />
						</svg>
						<p>
							Enviar vídeo ou imagem
						</p>
						<p>
							Tipos de arquivos compatíveis:  .jpg, .jpeg, .png, .mp4
						</p>
						<imageInput.HiddenFileInput accept=".jpg, .jpeg, .png, .mp4" />
					</Grid>
				}
				<Grid container item lg={9} className="BottomGrid">
					<Grid item lg={2} xl={1}>
						<S.ModalAvatar src={avatar ?? ""} />
					</Grid>
					<Grid item lg={10} xl={11} className="TextfieldGrid">
						<S.TextField
							type="text"
							placeholder="Escreva uma legenda..."
							value={text}
							onChange={(e) => {
								setText(e.target.value)
							}}
							disableUnderline
							multiline
						/>
						<S.ModalSelectGrid container>
							<FormControl>
								<Select
									labelId="demo-simple-select-label"
									className="select"
									id="demo-simple-select"
									value={visibility}
									label="Visibilidade"
									onChange={handleChange}
									displayEmpty
								>
									<MenuItem value={"PUBLIC"}>Público</MenuItem>
{/* 									{matchCollections.length > 0 &&
										<MenuItem value={"COLLECTION"}>Coleções</MenuItem>
									} */}
									{matchCollections.map((collection) => {
										return (
											<MenuItem key={collection.id} value={collection.url_title}>{collection.title}</MenuItem>
										)
									})
									}
								</Select>
							</FormControl>
							{visibility === "COLLECTION" &&
								<FormControl>
									<Select
										labelId="demo-simple-select-label"
										className="select"
										id="demo-simple-select"
										value={collectionUrlTitle}
										label="Visibilidade"
										onChange={handleUrlTitleChange}
										displayEmpty
									>
										{matchCollections.map((collection) => {
											return (
												<MenuItem key={collection.id} value={collection.url_title}>{collection.title}</MenuItem>
											)
										})
										}
									</Select>
								</FormControl>
							}
						</S.ModalSelectGrid>
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<S.ModalButton onClick={publishPost}>
						<p className="ButtonTxt">Publicar</p>
						<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.00999999 18.5L21 9.5L0.00999999 0.5L0 7.5L15 9.5L0 11.5L0.00999999 18.5Z" fill="white" />
						</svg>
					</S.ModalButton>
				</Grid>
			</S.ModalContainer>
		</S.ClosersModal>
	)
};

export default ClosersModal