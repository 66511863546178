import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About";
import NotFound from "./pages/404";
import Collectibles from "./pages/Collectibles/Collectibles";
import CollectibleDetails from "./pages/CollectibleDetails/CollectibleDetails";
import Login from "./pages/Login/Login";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import Signup from "./pages/Signup/Signup";
import Payment from "./pages/Payment/Payment";
import MyProfile from "./pages/MyProfile/MyProfile";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PublicProfile from "./pages/MyProfile/PublicProfile/PublicProfile";

// import useAuthentication from "./hooks/useAuthentication";
import "./App.css";
import { getCollectiblesRequest, getUserData } from "./utils/requests";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import FormsRedirect from "./components/FormsRedirect/FormsRedirect";
import NewColecionaveis from "./pages/Collectibles/NewCollectibles";
import ProjectDetails from "./pages/Collectibles/ProjectDetails";

import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';
import { NFTListContext } from "./context/NFTListContext";
import CollectionsDetails from "./pages/Collectibles/CollectionsDetails";
import Maintenance from "./pages/Maintenance/Maintenance";

function App() {
  const [auth, setAuth] = React.useState(
    !!localStorage.getItem("token") ?? false
  );

  const [token, setToken] = React.useState<string>(
    localStorage.getItem("token") ?? ""
  )
  // updates token value at every update	
  React.useEffect(() => {
    if (!token) {
      let ltoken = localStorage.getItem("token")
      if (ltoken) setToken(ltoken);
    }
    if (token) {
      let ltoken = localStorage.getItem("token")
      if (ltoken && ltoken !== token) setToken(ltoken)
    }
  }, [token])

  React.useEffect(() => {
    let isCancelled = false;

    const verifyLoginStatus = async () => {
      const token = localStorage.getItem("token");
      if (!token && !isCancelled) setAuth(false);
      const localUserData = JSON.parse(localStorage.getItem("user")!);
      const user = await getUserData(token ?? "", localUserData.id);
      return user;
    };

    if (token !== "") {
      const user = verifyLoginStatus();
      user.then(res => {
        if (res?.email) {
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(res));
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("shoppingcart");
          if (!isCancelled) {
            setToken("");
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        }
      })
    }

    return () => {
      isCancelled = true;
    };
  }, [token]);

  const {setNftList} = React.useContext(NFTListContext);

  const requestAndGetCollectibles = async () => {
		const response = await getCollectiblesRequest();
		if (response.nfts) {
			setNftList(response.nfts)
		};
		if (response.error) {
			//you could set errors or call toast notification here
		}
		return [];
	};

	React.useEffect(() => {
		requestAndGetCollectibles();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <Routes>
              {/* { Unprotected Routes } */}
              <Route path="/" element={<><Home auth={auth} setAuth={setAuth} /> </>} />

              <Route path="/forms" element={<FormsRedirect url={"https://docs.google.com/forms/d/e/1FAIpQLSdLx--Uk7_wpie2Lgz60guEo6rQ15xUbAARBQV_8BGqilpqIw/viewform"} />} />
              <Route path="/sobre" element={<><About /></>} />
              <Route
                path="/colecionaveis/:collectionName"
                key={"single"}
                element={<><CollectibleDetails auth={auth} setAuth={setAuth} /> </>}
              />
              <Route
                path="/colecionaveis"
                key={"all"}
                element={<><Collectibles auth={auth} setAuth={setAuth} /> </>}
              ></Route>

              <Route path="*" element={<><NotFound /></>}></Route>

              <Route
                path="/login"
                element={<Maintenance />}
                /* element={<><Login auth={auth} setAuth={setAuth} /></>} */
              />

              <Route path="/termosecondicoes" element={<><TermsAndConditions /></>} />
              <Route path="/politicadeprivacidade" element={<><PrivacyPolicy /></>} />
              <Route path="/recuperarsenha/:token" element={<><RecoverPassword /></>} />
              <Route path="/manutencao" element={<Maintenance />} />      
              <Route
                path="/carrinho"
                element={
                  auth ? (
                    <ShoppingCart auth={auth} setAuth={setAuth} />
                  ) : (
                    <Login auth={auth} setAuth={setAuth} />
                  )
                }
              />
              <Route
                path="/cadastrar"
                element={
                  auth ? (
                    <Home auth={auth} setAuth={setAuth} />
                  ) : (
                    <Signup auth={auth} setAuth={setAuth} />
                  )
                }
              />
              <Route
                path="/carrinho/pagamento"
                element={
                  auth ? (
                    <Payment auth={auth} setAuth={setAuth} />
                  ) : (
                      <Home auth={auth} setAuth={setAuth} />
                    )
                }
              />
              <Route
                path="/meuperfil"
                element={
                  auth ? (
                    <MyProfile auth={auth} setAuth={setAuth} />
                  ) : (
                    <Home auth={auth} setAuth={setAuth} />
                  )
                }
              />
              <Route
                path="/profile/:username"
                element={<><PublicProfile auth={auth} setAuth={setAuth} /> </>}
              />
              <Route
                path="/colecoes"
                element={<><NewColecionaveis auth={auth} setAuth={setAuth} /> </>}
              />
              <Route
                path="/colecoes/:collectionName"
                element={<><CollectionsDetails auth={auth} setAuth={setAuth} /> </>}
              />
              <Route
                path="/projetos/:url_title"
                element={<><ProjectDetails auth={auth} setAuth={setAuth} /> </>}
              />
            </Routes>
          </Router>
        </MuiThemeProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
