import React from "react";

import { useNavigate } from "react-router-dom";
import { UserType } from "../../../types/UserType";
import { getUserInfo } from "../../../utils/requests";
import * as S from "./style";

type Props = {
  auth: any;
  setAuth: (auth: any) => void;
};

const Avatar: React.FC<Props> = ({ auth, setAuth }) => {
  const [user, setUser] = React.useState<UserType>({} as UserType);

  React.useEffect(() => {
    let isCancelled = false;
    if (auth) {
      getUserInfo().then((res) => {
        if (!isCancelled) setUser(res?.user ?? ({} as UserType));
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [auth]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("shoppingcart");
    navigate("/");
    setAuth(false);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <React.Fragment>
      {user?.avatar ? (
        <S.Avatar onClick={handleClick} src={user?.avatar ?? ""}>
          V
        </S.Avatar>
      ) : (
        <S.Avatar onClick={handleClick}>V</S.Avatar>
      )}

      <S.Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <S.MenuItem onClick={handleClose}>Configurações</S.MenuItem> */}
        <S.MenuItem
          onClick={() => {
            navigate("/meuperfil");
          }}
        >
          Minha Conta
        </S.MenuItem>
        <S.MenuItem
          onClick={() => {
            navigate(`/profile/${user.username}`);
          }}
        >
          Meu Perfil
        </S.MenuItem>
        <S.MenuItem
          onClick={() => {
            handleLogout();
            console.log("logout");
          }}
        >
          Sair
        </S.MenuItem>
      </S.Menu>
    </React.Fragment>
  );
};

export default Avatar;
