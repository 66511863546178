import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import { GradientButton as Button } from "../../../components/CustomComponents/GradientButton/style";


export const MainContainer = styled(MuiGrid)
`
  z-index: -99999 !important;
  .mobile-bg {
    width: 100vw;
    aspect-ratio:763/506;
  }
  .desktop-bg {
    display: none;
    aspect-ratio:763/506;
  }

  @media (min-width: 900px) {
    .mobile-bg {
      display: none;
    }
    .desktop-bg {
      display: block;
      width: 100%;
    }
    .fadeout {
      background: linear-gradient(360deg, #000000 8.3%, rgba(0, 0, 0, 0) 100%);
      height: 80px;
      width: 100%;
      transform: translate(0px, -100%);
    }
  }
`;

export const GradientButton = styled(Button)
`
  margin-top:30px;
`

export const WelcomeText = styled(MuiGrid)
`
  padding-left: 13vw;
  padding-right: 40px;
  font-family: Bebas Neue;
  /* font-size: 1.1em; */
  font-size: 6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 0.9em;
  letter-spacing: 0.015em;
  text-align: left;
  p {
    padding: 0px;
    margin: 0px;
  }
  @media (min-width: 900px) {
    padding-top: 15vw !important;
    font-size: 2.5vw;
    padding-top: 91px;
    padding-right: 0px;
    padding-left: 0px;
    transform: translate(5vw, 0px);
  }
`;
export const WelcomeImg = styled(MuiGrid)
`
  img {
    opacity: 0%;
    overflow: hidden;
  }
`;

export const MobileImg = styled(MuiGrid)
``;

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) {
// }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) {
// }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) {}