import { useState } from "react";
import { CartItem } from "../types/CartItemType";

function useShoppingCart() {
  let startValue: CartItem[] = [];

  try {
    let item = window?.localStorage?.getItem("shoppingcart");
    startValue = item ? JSON.parse(item) : [];
  } catch (error) {}

  const [storedValue, setStoredValue] = useState(startValue);
  const setValue = (value: CartItem[]) => {
    try {
      setStoredValue(value);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(
          "shoppingcart",
          JSON.stringify(value)
        );
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  return [storedValue, setValue]as const;
}

export default useShoppingCart;
