/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from "@mui/material";
import React from "react";
import BgBase from "../../../assets/bgBase.svg";

import * as S from "./style";

import { CollectibleType } from "../../../types/CollectibleType";
import { ProjectType } from "../../../types/ProjectType";
import { CollectionsType } from "../../../types/CollectionsType";


type props = {
  collectible?: CollectibleType;
  project: ProjectType | CollectionsType
  xs?: number
  md?: number
  lg?: number
  showOwned?: boolean
  ownerDetails?: boolean
};

const ProjectCard: React.FC<props> = ({ collectible, xs, md, lg, project }) => {

/*   const handleOnMouseOver = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.currentTarget.play();
  };

  const handleOnMouseOut = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.currentTarget.pause();
  }; */

  return (
    <S.MainContainer container item xs={12} spacing={3}>
          <S.CardContainer
            item
            container
            xs={xs? xs : 6}
            md={md? md : 4}
            lg={lg? lg : 3}
/*             onClick={() => {
              scrollToTop();
              navigate("/colecionaveis/" + collectible.collectionName, {
                state: { collectible: collectible, ownerDetails: ownerDetails },
              });
            }} */
            style={{ 'backgroundImage': `url(${BgBase})` }}
            key={project.id}
          >
{/*             {showOwned &&
              <S.QuantityFab color="primary" aria-label="add">
              <p>{collectible.available}</p>
              </S.QuantityFab>
            } */}
            {!collectible?.videoUrl &&
              <div className={'card'} style={{ 'backgroundImage': `url("${project.thumb}")` }}>
              <div style={{ 'width': '100%', 'aspectRatio': '500/490' }}>
              </div>
              <S.Divider className={'details'}/>
              <S.CardInfoTest
              className={'details'}
              // item container xs={12} rowSpacing={0}
              
              >
                <Grid item container xs={12} alignItems={"center"}>
                  <Grid item xs={12}><h2>{project.title}</h2></Grid>
                  <Grid item xs={10}>
                    <h3>
                        Mais detalhes
                    </h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={2}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                  >
                    <S.ArrowRightIcon />
                  </Grid>
                </Grid>

              </S.CardInfoTest>
            </div>
            }
{/*             {collectible?.videoUrl &&
              <div className={'card'} >
              <div style={{ 'width': '100%', 'aspectRatio': '595/490' }}>
              <video width="100%" height="100%" muted loop onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
                <source src={collectible?.videoUrl ?? ""} type="video/mp4"></source>
              </video>
              </div>
              <S.Divider className={'details'}/>
              <S.CardInfoTest
              className={'details'}
              // item container xs={12} rowSpacing={0}
              
              >
                <Grid item container xs={12} alignItems={"center"}>
                  <Grid item xs={12}><h2>{collectible.title}</h2></Grid>
                  <Grid item xs={10}>
                    <h3>
                        Mais detalhes
                    </h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={2}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                  >
                    <S.ArrowRightIcon />
                  </Grid>
                </Grid>

              </S.CardInfoTest>
            </div>
            } */}
          </S.CardContainer>
    </S.MainContainer>
  );
};

export default ProjectCard;
