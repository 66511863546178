import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useShoppingCart from "../../hooks/useShoppingCart";
import { CartItem } from "../../types/CartItemType";
import * as S from "./style";
//import toastify and custom tostify styles
import sadZ from "../../assets/ze_sad.png";
import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "../../components/CustomComponents/ToastContainer/toast";

import { Navigate, useNavigate } from "react-router-dom";
import { OrderType } from "../../types/OrderType";
import { createOrder } from "../../utils/requests";
import { calculateTotalPrice, formatAsBRLCurrency } from "./../../utils/sharedfunctions";

type RenderCartProps = {
  cartitems: CartItem[];
  setShoppingCart: (shoppingCart: CartItem[]) => void;
};

const RenderCartItems: React.FC<RenderCartProps> = ({
  cartitems,
  setShoppingCart,
}: RenderCartProps) => {
  return (
    <S.Grid container item xs={12} px={1}>
      <S.Grid
        container
        item
        alignItems={"flex-start"}
        className="columntitles"
        xs={12}
      >
        <S.Grid item xs={5}>
          <h2>PRODUTOS</h2>
        </S.Grid>
        <S.Grid item xs={3}>
          <h2>PREÇO</h2>
        </S.Grid>
        <S.Grid item xs={2}>
          <h2>QTD.</h2>
        </S.Grid>
        <S.Grid item xs={2}>
          <h2>TOTAL</h2>
        </S.Grid>
        <S.Grid item xs={12} className={"head-divider"}>
          <S.Divider />
        </S.Grid>

        <S.ItemsWrapper item container xs={12}>
          {cartitems.map((item: CartItem) => {
            function isNumeric(value: string) {
              return /^\d+$/.test(value);
            }

            const removeCartItem = (url_title: string) => {
              let newdata = cartitems.filter(
                (item: CartItem) => item.url_title !== url_title
              );
              showToast("Item removido do carrinho!", "error");
              setShoppingCart(newdata);
            };
            const changeQuantity = (url_title: string, ev: HTMLInputElement) => {
              if (isNumeric(ev.value)) {
                let quantityInt = parseInt(ev.value);
                if (quantityInt < 1) quantityInt = 1;
                if (quantityInt > 10000) quantityInt = 10000;
                if (quantityInt > item.available) quantityInt = item.available;
                let newdata = cartitems.map((item: CartItem) => {
                  if (item.url_title === url_title) {
                    if(quantityInt > item.available) item.quantity = item.available;
                    else item.quantity = quantityInt;
                  }
                  ev.value = quantityInt.toString();
                  return item;
                });
                showToast("Atualizada a quantidade do item", "success");
                setShoppingCart(newdata);
              } else {
                cartitems.map((item: CartItem) => {
                  if (item.url_title === url_title) {
                    ev.value = item.quantity.toString();
                  }
                  return item;
                });
              }
            };
            return (
              <React.Fragment key={item.url_title}>
                <S.Grid className="itemcontainer" item container xs={5}>
                  <S.Grid item container xs={6} p={1} sm={5} md={4} lg={3}>
                    <img src={item.imageUrl} alt="teste nft" />
                  </S.Grid>
                  <S.Grid
                    item
                    container
                    xs={6}
                    sm={7}
                    md={8}
                    lg={9}
                    alignItems="flex-start"
                    justifyContent={"center"}
                    direction={"column"}
                  >
                    <S.Grid item>
                      <h3>{item.title}</h3>
                    </S.Grid>
                    <S.Grid item>
                      <button
                        className="removeitem"
                        onClick={() => {
                          removeCartItem(item.url_title);
                        }}
                      >
                        Remover
                      </button>
                    </S.Grid>
                  </S.Grid>
                </S.Grid>
                <S.Grid item container xs={3} textAlign="center" my={"auto"}>
                  <h4>{formatAsBRLCurrency(item.price)}</h4>
                </S.Grid>
                <S.Grid item container xs={2} textAlign="center" my={"auto"}>
                  <h4>
                    <input
                      type="text"
                      defaultValue={item.quantity}
                      onBlur={(e) => {
                        changeQuantity(item.url_title, e?.target);
                      }}
                    />
                  </h4>
                </S.Grid>
                <S.Grid item container xs={2} textAlign="center" my={"auto"}>
                  <h4>{formatAsBRLCurrency(item.price * item.quantity)}</h4>
                </S.Grid>
                <S.Grid item xs={12} className={"head-divider"}>
                  <S.Divider />
                </S.Grid>
              </React.Fragment>
            );
          })}
        </S.ItemsWrapper>
        <S.Grid item xs={12}>
          <S.Divider />
        </S.Grid>
      </S.Grid>
    </S.Grid>
  );
};
const RenderCart: React.FC<RenderCartProps> = ({
  cartitems,
  setShoppingCart,
}) => {
  return (
    <S.Grid item xs={12}>
      <h1>
        <S.ShoppingCart />
        SEU CARRINHO
      </h1>
      <S.CartContainer item container>
        <RenderCartItems
          cartitems={cartitems}
          setShoppingCart={setShoppingCart}
        />
      </S.CartContainer>
    </S.Grid>
  );
};

type CartProps = {
  auth: any;
  setAuth: (auth: any) => void;
};

const ShoppingCart: React.FC<CartProps> = ({ auth, setAuth }) => {
  const [cartItems, setCartItems] = useShoppingCart();
  const [shoppingCart, setShoppingCart] = React.useState(cartItems)
  const [order, setOrder] = React.useState<OrderType | undefined>(undefined);
  const navigate = useNavigate();
  if(!auth) navigate("/");

  const handleSetShoppingCart = (updatedCart: CartItem[]) => {
    setShoppingCart(updatedCart);
    setCartItems(updatedCart);
  }

  const handleGoToPaymentClick = async () => {
    let token = localStorage.getItem("token");
    if (token) {
      createOrder(token, shoppingCart).then(res => {
        if(res?.order) setOrder(res)
        else if (res?.statusCode === 400) {
          showToast("Não é possivel solicitar mais de 1 NFT gratuito", "error")
        }
        else showToast("Erro ao criar o pedido", "error");
      });
    }else{
      showToast("Não foi possível realizar essa requisição: token não encontrado.", "error");
    }
  }

  return (
    order ? <Navigate to="/carrinho/pagamento" state={{'order': order}}/> :
    <React.Fragment>
      <Navbar
        cartItemCount={shoppingCart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <S.MainContainer container justifyContent={"center"} alignItems={"start"}>
        <S.Container
          item
          container
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <S.Grid container item>
            <S.Grid item xs={12}>
              {shoppingCart.length > 0 ? (
                <>
                  <RenderCart
                    cartitems={shoppingCart}
                    setShoppingCart={handleSetShoppingCart}
                  />
                  <S.Grid item container xs={12} justifyContent={"flex-end"}>
                    <S.Grid
                      item
                      container
                      justifyContent={"flex-end"}
                      xs={12}
                      sm={6}
                    >
                      <S.Subtotal>
                        SUBTOTAL:
                        {formatAsBRLCurrency(calculateTotalPrice(shoppingCart))}
                      </S.Subtotal>
                      <br />
                      <S.Tributos>Tributos calculados no checkout.</S.Tributos>
                    </S.Grid>
                    <S.Grid
                      item
                      container
                      justifyContent={"flex-end"}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                    >
                      <button
                        onClick={
                          handleGoToPaymentClick
                          // (e) => navigate("/carrinho/pagamento")
                        }
                        className={"fecharPedido"}
                      >
                        Finalizar compra
                      </button>
                    </S.Grid>
                  </S.Grid>
                </>
              ) : (
                <S.Grid
                  xs={12}
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"start"}
                >
                  <S.Grid
                    className={"imgContainer"}
                    item
                    container
                    xs={12}
                    md={5}
                    lg={4}
                  >
                    <img
                      className={"sadz"}
                      src={sadZ}
                      alt={"Mascote da viuzz triste"}
                    />
                  </S.Grid>
                  <S.Grid
                    className={"noItensMessage"}
                    item
                    container
                    xs={12}
                    md={5}
                    lg={4}
                  >
                    <span>Parece que seu carrinho esta vazio....</span>
                    <button
                      className="gotogome"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/");
                      }}
                    >
                      VOLTAR PARA HOME
                    </button>
                  </S.Grid>
                </S.Grid>
              )}
            </S.Grid>
          </S.Grid>
        </S.Container>
      </S.MainContainer>
    </React.Fragment>
  );
};

export default ShoppingCart;
