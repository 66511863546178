import styled from "styled-components";
import {
  Grid as MuiGrid,
  // Checkbox as CheckboxBase,
  // FormControlLabel as FormControlLabelBase
} from "@mui/material";
import { Link } from "react-router-dom";
//   import { ReactComponent as ViuzzVSVG } from "../../assets/ViuzzV.svg";
import BackgroundV from "../../assets/bigViuzz.svg";
import Input from "../../components/Input/input";

export const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const MainContainer = styled(MuiGrid)`
  height: 100vh !important;
  background-image: url(${BackgroundV});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  background-color: rgba(0,0,0,1);

  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  @media (min-width: 575px) {
    justify-content: start;
  }
  h1 {
    text-align: left;
    display: flex;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 10px;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
`;

export const Grid = styled(MuiGrid)`
  justify-content: center;
  text-align: center;
  form {
    width: 100%;
    display: flex;
  }
  button.continue {
    color: white;
    background-color: #fd700b;
    border-radius: 30px;
    display: block;
    width: 100%;
    margin-top: 15px;
    margin-right: 10px;
    border-width: 0px;
    height: 50px;
    line-height: 25px;
    :hover {
      background-color: #78420b;
      transition-duration: 250ms;
      cursor: pointer;
    }
  }
  a.forgotPassword {
    font-family: Barlow Semi Condensed, sans-serif;
    margin-top: 30px;
    margin-bottom: 35px;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 24px;
    color: #fd700b;
    :hover {
      color: #78420b;
      transition-duration: 150ms;
    }
  }
  a.newAccount {
    font-family: Barlow Semi Condensed, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #fd700b;
    font-size: 1.25rem;
    font-weight: 600;
    span {
      font-weight: 700;
    }
    :hover {
      color: #78420b;
      transition-duration: 250ms;
    }
  }
`;
export const FormContainer = styled.div`
  width: 100%;
  height: 500px;
  @media (min-width: 420px) {
    width: 420px;
  }
`;

export const TextField = styled(Input)`
  .MuiInputBase-input {
    border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important;
    padding: 15px 20px;
    transition: 250ms;
  }
  [aria-invalid="true"] {
    border-style: solid !important;
    border-width: 1px !important;
  }
  margin: 5px;
  @media (min-width: 320px) {
    width: 320px !important;
  }
  @media (min-width: 420px) {
    width: 420px !important;
  }

  [name="password"] {
    margin-top: 0px;
  }
`;

export const ButtonContainer = styled.div`
  background-color: rgb(46, 46, 56);
  border-radius: 10px;
  transform: translate(5px, 0px);
  margin-top: 5px;
  padding: 15px 20px;
  text-align: left;
  font-size: 0.95em;
`;

export const LinkContainer = styled.div`
  width:100%;
  text-align:center;
  padding-top: 15px ;
`

export const LoginLink = styled(Link)`
  color: #fd700b;
  font-size:20px;
`