import React from "react";
import * as S from "./style";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { login } from "../../utils/requests";
//toast
import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "./../../components/CustomComponents/ToastContainer/toast";

import * as EmailValidator from "email-validator";

import { LoginResponse } from "../../types/RequestTypes";
import { getUserData } from "./../../utils/requests";
type LoginProps = {
  auth: any;
  setAuth: (auth: any) => void;
};

const Login: React.FC<LoginProps> = ({ auth, setAuth }) => {
  const [email, setEmail] = React.useState<{ email: string; valid: boolean }>({
    email: "",
    valid: true,
  });
  const [password, setPassword] = React.useState<{
    password: string;
    valid: boolean;
  }>({ password: "", valid: true });

  // const [rememberMe, setRememberMe] = React.useState(true);
  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail((prevState) => {
      return {
        ...prevState,
        email: e.target.value,
        valid: EmailValidator.validate(e.target.value),
      };
    });
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword((prevState) => {
      return {
        ...prevState,
        password: e.target.value,
        valid:
          20 >= e.target.value.length && e.target.value.length >= 4
            ? true
            : false,
      };
    });
  };

  const isValid = () => {
    if (email.email.length === 0) return false;
    if (password.password.length === 0) return false;
    return email.valid && password.valid;
  };
  
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);

  async function handleLogin() {
    if (isValid() && recaptchaToken) {
      let response: LoginResponse = await login(email.email, password.password, recaptchaToken);
      if (response?.accessToken) {
        showToast("Logado com sucesso! redirecionando...", "success");
        //setTimeout waits for toast to be closed to handle navigation
        const localUserData = JSON.parse(localStorage.getItem("user")!);
        if (localUserData) {
          getUserData(response?.accessToken, localUserData.id).then((res) => {
            setAuth(true);
            localStorage.setItem("user", JSON.stringify(res));
          });
        }
        setTimeout(() => {
          if (response?.accessToken)
            localStorage.setItem("token", response.accessToken);
          setAuth(true);

          if (window?.location?.pathname === "/login") navigate("/");
          if (window?.location?.pathname === "/colecionaveis")
            navigate("/colecionaveis");
          navigate(window?.location?.pathname ?? "/");
        }, 3000);
      } else {
        if (response?.statusCode !== 200) {
          showToast(response?.message ?? "", "error");
        }
      }
    } else {
      showToast("Preencha os campos corretamente antes de continuar", "error");
    }
  }


  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    //Login, ForgotPassword, Transaction, CreateIntent
    const token = await executeRecaptcha('Login');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <S.MainContainer container>
      <S.Grid item xs={12} md={6} lg={5} container>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <S.ViuzzV />
        <h1>Bem-vindo!</h1>
        <form>
          <S.Grid item container xs={12}>
            <S.Grid item container xs={12}>
              <S.TextField
                name="email"
                type={"email"}
                placeholder="E-mail"
                value={email.email}
                onChange={handleEmailChange}
                error={!email.valid}
              />
            </S.Grid>
            <S.Grid item container xs={12}>
              <S.TextField
                name="password"
                type={"password"}
                placeholder="Senha"
                value={password.password}
                onChange={handlePasswordChange}
                // inputRef={register}
                error={!password.valid}
              />
            </S.Grid>
            {/* <S.Grid item container xs={12}>
              <S.FormControlLabel
                control={
                  <S.Checkbox
                    color="primary"
                    name="rememberMe"
                    onChange={handleRememberMeChange}
                    value={rememberMe}
                  />
                }
                label="Mantenha-me conectado"
              />
            </S.Grid> */}
            <S.Grid item container xs={12}>
              <button
                className="login"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                Entrar
              </button>
            </S.Grid>
            <S.Grid item container xs={12}>
              <Link to="/recuperarsenha/solicitartoken" className="forgotPassword">
                Esqueci minha senha
              </Link>
              {/* <a
                href="https://app.viuzz.com.br/esqueci-minha-senha"
                className="forgotPassword"
              >
                Esqueci minha senha
              </a> */}
            </S.Grid>
            <S.Grid>
              <Link to="/cadastrar" className="newAccount">
                Não possui cadastro?<span>&nbsp;Cadastre-se</span>
              </Link>
            </S.Grid>
          </S.Grid>
        </form>
      </S.Grid>
    </S.MainContainer>
  );
};

export default Login;
