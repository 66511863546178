import styled from "styled-components";
import Navbar from "../../components/Navbar/Navbar";
import { Button, Divider, FormControlLabel, InputLabel, Grid as MuiGrid } from "@mui/material";
import FooterComponent from "../../components/Footer/Footer";
import VerifiedIcon from '@mui/icons-material/Verified';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { css } from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import { Tabs as MuiTabs } from "@mui/material";

type ImageProps = {
  src: string;
  mobileSrc: string;
};

export const NavBar = styled(Navbar)`
  && {
    position: absolute;
    z-index: 500;
  }
`;
export const Title = styled.h1`
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

export const CollectionTitle = styled.h1`
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 5px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

export const MainContainer = styled(MuiGrid)`
  padding-top: 30px;
  @media (min-width: 900px) {
    padding-bottom: 30px;
    padding-top: 80px;
  }
`;
export const Container = styled(MuiGrid)`
  margin-left:15px !important;
  margin-right:15px !important;

  .subTitle {
    font-weight: 500;
    font-size: 40px;
  }
  .nftListGrid {
    padding: 0 100px;
  }
`;

export const SubtitleGrid = styled(MuiGrid)`
  @media (max-width: 992px) {
    justify-content: center;
  }
`

export const CardListContainer = styled(MuiGrid)`

`;
export const TitleContainer = styled(MuiGrid)`
  align-content: center !important;
  text-align: center !important;

  .creatorGrid {
    p {
      margin-top: 0;
    }
  }

  .sideItems {
    padding-top: 7px;
  }

  .creatorName {
    font-size: 26px;
    font-weight: 700;
    color: #E22B00;
    padding: 0 5px;
    cursor: pointer;
  }
`;

export const Footer = styled(FooterComponent)`
`

export const CarouselGrid = styled(MuiGrid)`
  .react-multi-carousel-dot button {
    width: 25px;
    height: 4px;
    border-radius: 1px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px 4px;
    cursor: pointer;
    border-color: lightgray;
  }

  .react-multi-carousel-dot--active button {
    border-color: white;
  }

  img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
  }

  .banner-wrapper {
    height: 100%;
  }

  .react-multi-carousel-track {
      align-items: center;
  }
`

export const GridContainer = styled(MuiGrid)`
  .under-carousel-text{
    font-size: calc(14px + 1.5vw);
    margin-top: 20px;
    font-weight: 600;
    padding-right: 50px;
    @media (max-width: 699px) {
      padding-right: 20px;
    }
  }
  .categories-text {
    font-size: calc(12px + 1.5vw);
    margin: 0;
    font-weight: 700;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    @media (max-width: 699px) {
      padding-left: 10px;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

`

export const BottomCarouselGrid = styled(MuiGrid)`
  .react-multi-carousel-dot button {
    width: 25px;
    height: 4px;
    border-radius: 1px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px 4px;
    cursor: pointer;
    background: #FD670B;
    border-color: white;
  }

  .react-multi-carousel-dot--active button {
    border-color: #FD670B;
  }

  img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
  }

  .banner-wrapper {
    height: 100%;
  }

  .react-multi-carousel-track {
    align-items: center;
    margin-bottom: 30px;
    gap: 15px;
  }
`

export const CollectionContainer = styled(MuiGrid)`
  justify-content: center;
  padding: 60px 100px;
  @media (max-width: 1199px) {
    padding: 0;
  }

  .collection-image{
    width: 250px;
    @media (min-width: 1200px){
      height: 100%;
      width: 100%;
    }
    @media (min-width: 1200px) and (max-width: 1600px) {
      min-width: 230px;
      max-height: 290px;
    }
  }

  .collection-title{
    font-weight: 700;
    font-size: 46px;
    line-height: 55px;
    margin: 0;
  }

  .collection-description{
    text-align: justify;
    font-size: 18px;
    padding-right: 10px;
    @media (min-width: 1200px) {
      padding-right: 80px;  
    }
  }

  .collection-username{
    color: #E22B00;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin: 10px 0;
    cursor: pointer;
  }

  .collection-by{
    font-size: 24px;
    margin-top: 15px;
  }

  .collectionInfoGrid {
    padding-left: 80px;
    @media (max-width: 1199px) {
      padding-left: 10px;
    }
    @media (min-width: 1200px) and (max-width: 1600px) {
      padding-left: 70px;
    }
  }

  .imageGrid {
    @media (max-width: 1199px) {
      justify-content: center;
    }
  }
`

export const Verified = styled(VerifiedIcon)`
  padding-top: 20px;
  color: #E22B00;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    li.react-multi-carousel-item {
      transform: scale(0.97);
      transition: all 400ms;
    }
    .MuiTypography-body1{
      font-size: 1.4rem !important;
    }
    li.react-multi-carousel-item {
      /* transform: scale(1.05); */
      transition: all 400ms;
      left: -15vw;
    }
    li.react-multi-carousel-item--active {
      /* transform: scale(1.05); */
      transition: all 400ms;
      width: 80vw !important; */
    }
    .react-multi-carousel-track {
      /* justify-content: center; */
      align-items: center;
      margin: 0;
      .banner-description {
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
        bottom: -50px;
      }
      .react-multi-carousel-item--active .banner-description {
        width: 100vw;
        transition: all 2000ms;
      }
      ${theme.breakpoints.up('xl')} {
        height: 600px;
        .banner-image {
          height: 400px;
        }
        .react-multi-carousel-item--active {
          .banner-image {
            height: 500px;
          }
        }
      }
      ${theme.breakpoints.down('xl')} {
        height: 600px;
        .banner-image {
          height: 400px;
        }
        .react-multi-carousel-item--active {
          .banner-image {
            height: 500px;
          }
        }
      }
      ${theme.breakpoints.down('md')} {
        height: 500px;
        .banner-image {
          height: 300px;
        }
        .react-multi-carousel-item--active {
          .banner-image {
            height: 400px;
          }
        }
      }
      ${theme.breakpoints.down('sm')} {
        height: 500px;
        .banner-image {
          height: 250px;
        }
        .react-multi-carousel-item--active {
          .banner-image {
            height: 400px;
          }
        }
      }
    }
    .banner-image {
      object-fit: cover;
      filter: brightness(60%);
    }
    .banner-title-image {
      width: 0;
      transition: 0.4s;
    }
    .react-multi-carousel-item--active {
      .banner-title-image {
        width: 25%;
        z-index: 1;
      }
      :hover {
        .banner-image {
          -webkit-box-shadow: 0px 0px 5px 2px rgba(251, 128, 8, 0.75);
          box-shadow: 0px 0px 5px 2px rgba(251, 128, 8, 0.75);
        }
      }
      .banner-image {
        transition: all 0.4s;
        width: 140%;
        filter: brightness(100%);
      }
    }
    .react-multi-carousel-dot-list {
      list-style: none;
      display: flex !important;
      align-items: center;
      justify-content: center;
      transform: translate(20%, -120px);
      bottom: -55px;
      ${theme.breakpoints.down('sm')} {
        transform: translate(15%, -100px);
      }

      li {
        background: ${theme.palette.common.white};
        width: 25px;
        height: 4px;
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 ${theme.spacing(0.5)}px;
        cursor: pointer;
        ${theme.breakpoints.down('md')} {
          width: 15px;
          height: 3px;
        }

        &.react-multi-carousel-dot--active {
          background: ${theme.palette.primary.main};
        }
      }

      button {
        opacity: 0;
        width: 12px;
        height: 12px;
        cursor: pointer;
/*         margin-top: 100px; */
      }
    }
  `}
`;

export const SlideWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BannerImage = styled.div<ImageProps>`
  ${({ theme, src, mobileSrc }) => css`
    background-color: ${theme.palette.background.default};
    background-image: url(${src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    ${theme.breakpoints.down('md')} {
      background-image: url(${mobileSrc});
    }
  `}
  transition: all 0.4s;
  width: 100%;
  ${({ theme }) => css`
    border-radius: ${theme.spacing(0.5)}px;
  `}
`;

export const BannerLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const BannerTitle = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-25%, -20%);
  width: 40%;
  /* height: 100px; */
`;

export const BannerDescription = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.h2.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    text-align: center;
    margin-top: 25px !important;
    ${theme.breakpoints.down('lg')} {
      font-size: ${theme.typography.h3.fontSize};
    }
    ${theme.breakpoints.down('md')} {
      font-size: ${theme.typography.h4.fontSize};
    }
    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.h6.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }
  `}
`;

export const RightGrid = styled(MuiGrid)`
  border: 5px solid #E22B00;
  border-radius: 12px;
  margin-left: 32px;
  @media (max-width: 992px) {
    margin-left: 0;
  }
  
  .valueText {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .collectedText {
    align-self: self-end;
    color: #c4c3c2;
    font-size: 20px;
    margin-bottom: 10px;
    padding-left: 5px;
  }
  
  .goalText {
    font-size: 22px;
    color: #c4c3c2;
    margin-top: 5px;
    margin-left: 45px;
    @media (max-width: 992px) {
      margin-left: 40px;
    }
  }

  .valueGrid {
    margin-left: 40px;
    @media (max-width: 992px) {
      margin-left: 35px;
    }
  }
`

export const LeftCarouselGrid = styled(MuiGrid)`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media (max-width: 899px) {
    flex-direction: column !important;
  }

  img {
    max-height: 450px;
  }

  .testCarouselBugFix {
    width: 100%;
  }

  .react-multi-carousel-dot button {
    width: 25px;
    height: 4px;
    border-radius: 1px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px 4px;
    cursor: pointer;
    border-color: lightgray;
  }

  .react-multi-carousel-dot--active button {
    border-color: #E22B00;
  }

  .react-multi-carousel-dot-list {
    bottom: 15px;
    right: 15px;
    justify-content: end;
  }
`

export const FundingProgressBar = styled(LinearProgress)`
  width: 90%;
  height: 20px !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px;

  .MuiLinearProgress-bar1Determinate{
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%);
    border-radius: 50px;
  }
`

export const Tabs = styled(MuiTabs)`
  justify-content: space-evenly;
  @media (min-width: 1440px) {
    width: 100%;
  }
  .MuiTab-root {
    font-family: "Barlow Semi Condensed";
    font-style: normal;
    font-weight: 600;
    text-transform: none !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    @media (min-width: 570px) {
      font-size: 20px;
    }
    @media (min-width: 990px) {
      font-size: 28px;
    }
  }
  .MuiTabs-indicator {
    z-index: 2;
    height: 6px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    border-radius: 1px;
    @media (min-width: 600px) {
      height: 9px;
    }
  }
`;

export const TabDivider = styled(Divider)`
  border-width: 1px !important;
  margin-bottom: 10px !important;
  border-image: linear-gradient(90deg ,#fb8404 0%, #fe1e14 100%) 30;
  width: 100%;
  padding: 0;
  height: 0;
`

export const TabGrid = styled(MuiGrid)`
  max-height: 70px;

  @media (max-width: 899px) {
      justify-content: center;
  }
  
  .profilebuttons {
    padding-left: 60px;
    padding-right: 60px;
    
    @media (max-width: 899px) {
      padding: 0;
    }
  }
  
  .MuiTabs-flexContainer{
    justify-content: space-evenly;
  }
`;

export const OuterCarouselGrid = styled(MuiGrid)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
`

export const StatsGrid = styled(MuiGrid)`
  padding-left: 30px;

  .numbers {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
    padding: 0 10px;
    padding-top: 5px;
  }

  .rewardNumber {
    padding-left: 18px;
  }

  .icons {
    padding-top: 10px;
  }

  .rewardsText {
    font-size: 20px;
    color: #c4c3c2;
  }
`

export const BuyButtons = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
    
  @media (min-width: 700px) {
      width: 90%;
      height: 60px;
   }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`

export const RewardsContainer = styled(MuiGrid)`
  gap: 32px;
  margin-top: 45px;
  margin-bottom: 100px;
`

export const RewardsGrid = styled(MuiGrid)`
  border: 1px solid #E22B00;
  border-radius: 12px;
  padding: 30px 20px;
  max-width: 350px;

  .tierText {
    color: #c4c3c2;
    font-size: 20px;
  }

  .rewardText {
    font-size: 40px;
    font-weight: 600;

    p{
      margin: 0;
    }
  }

  .descriptionTitle {
    color: #c4c3c2;
    font-size: 16px;
    font-weight: 700;
  }

  li {
    color: #c4c3c2;
    font-size: 16px;
    font-weight: 400;
  }

  .disabledButton {
    background: rgba(49, 49, 49, 0.65) !important;
  }
`

export const RewardButton = styled(BuyButtons)`
  width: 300px;
  height: 40px;

  .ButtonTxt{
    font-size: 20px;
  }
`

export const BottomContainer = styled(MuiGrid)`
  margin-bottom: 40px;
  
  .searchGrid {
    margin-right: 30px;
    @media (max-width: 992px) {
      margin-bottom: 20px;
      margin-right: 0;
    }

    input::placeholder {
    font-weight: bold;
    color: #FFFFFF;
    font-weight: 400;
    font-family: 'Barlow Semi Condensed';
    font-size: 18px;
    opacity: 0.75;
    }
  }
  
  .searchBar {
    background-color: #1E1E1E;
    border-radius: 5px;
    border: 2px solid #FC580A;
    color: #FFFFFF;
    width: 100%;
    height: 40px;
    font-family: 'Barlow Semi Condensed';
    padding-left: 15px;
    font-size: 18px;
  }

  .select {
    background-color: #1E1E1E;
    color: #FFFFFF;
    opacity: 0.75 !important;
    border: 2px solid #FC580A;
    height: 46px;
    width: 22vw;
    
    @media (max-width: 992px) {
    width: 100%;
    }

    svg {
      color: #FFFFFF;
    }

    .MuiOutlinedInput-notchedOutline {
      border-style: none !important;
    }
  }

  .results {
    font-size: 24px;
    margin: 0;
    text-align: center;
    @media (max-width: 992px) {
      margin: 20px 0;
    }
  }

  .leftIcon {
    padding-top: 15px;
    cursor: pointer;
  }
`

export const SelectGrid = styled(MuiGrid)`
  min-width: 120px;

  .MuiFormLabel-root-MuiInputLabel-root{
    font-weight: bold;
    color: #FFFFFF;
    font-weight: 400;
    font-family: 'Barlow Semi Condensed';
    font-size: 18px;
    opacity: 0.75;
  }
`

export const OuterContainer = styled(MuiGrid)`
  padding-top: 30px;
  padding-left: 45px;
  padding-right: 45px;
  @media (min-width: 900px) {
    padding-bottom: 30px;
    padding-top: 80px;
  }
  @media (max-width: 992px) {
    padding-left: 15px;
    padding-right: 20px;
  }
`;

export const VerticalTabs = styled(Tabs)`
  width: 100%;
  .MuiTab-root {
    font-weight: 400;
    height: 100px;
  }
  .MuiTab-root.Mui-selected {
    font-weight: 600;
  }
  .MuiTabs-indicator {
    left: 10px;
  }
`

export const GoalsGrid = styled(MuiGrid)`
  .title {
    p {
      font-size: 40px;
      margin-bottom: 0;
      font-weight: 600;
    }
  }
  .description {
    margin-top: 0;
    font-size: 20px;
  }
`

export const FilterIconGrid = styled(MuiGrid)`
  justify-content: end;
  @media (max-width: 992px) {
    justify-content: center;
  }
`

export const CheckboxLabel = styled(FormControlLabel)`
  svg {
    color: white;
  }
`

export const FilterGrid = styled(MuiGrid)`
  .MuiTypography-root{
    font-family: "Barlow Semi Condensed", sans-serif !important;
    font-size: 18px;
  }
`

export const SelectLabel = styled(InputLabel)`
  color: #FFFFFF !important;
  font-weight: 400 !important;
  font-family: 'Barlow Semi Condensed'!important;
  font-size: 18px !important;
  opacity: 0.75 !important;
  top: -4px !important;
`