import React from "react";
import * as S from "./style";
import nftimg from "../../../assets/nft.png";
import { useNavigate } from "react-router-dom";

export const Nft: React.FC = () => {
  const navigate = useNavigate();
  return (
    <S.Grid container justifyContent={{ xs: "center" }}>
      <S.MobileImage item xs={8}>
        <img src={nftimg} alt="exemplo de nft" />
      </S.MobileImage>
      <S.Grid item container xs={12} justifyContent={{ xs: "center" }}>
        <S.Grid container item xs={12} md={7}>
          <S.OutlinedText item xs={12} >
            <p>NFTs</p>
          </S.OutlinedText>
          <S.TextWrapper item container xs={12} justifyContent={'flex-end'}>
            <p>
              os colecionáveis
              <br />
              nasceram para ajudar
              <br />
              artistas e colecionadores
              <br />a se conectarem de um
              <br />
              modo fácil ao mundo das
              <br />
              artes digitais e das NFTs
            </p>
            {/* <S.GradientButton onClick={() => { navigate("/colecionaveis") }} >VER COLEÇÃO</S.GradientButton> */}

            <S.GradientButton onClick={()=>{ navigate("/colecionaveis") }} >VER COLEÇÕES</S.GradientButton>
          </S.TextWrapper>
        </S.Grid>
        <S.DesktopImage item md={5}>
          <img src={nftimg} alt="exemplo de nft" />
        </S.DesktopImage>
      </S.Grid>
    </S.Grid>
  );
};

export default Nft;
