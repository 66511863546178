import styled from "styled-components";
import { Fab, Input, InputLabel, Tabs as MuiTabs, Typography } from "@mui/material";
import { Grid as MuiGrid, Divider } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import VerifiedIcon from '@mui/icons-material/Verified';

export const Tabs = styled(MuiTabs)`
  @media (min-width: 1440px) {
    width: 100%;
  }
  .MuiTab-root {
    font-family: "Barlow Semi Condensed";
    font-style: normal;
    font-weight: 600;
    text-transform: none !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    @media (min-width: 570px) {
      font-size: 20px;
    }
    @media (min-width: 990px) {
      font-size: 28px;
    }
  }
  .MuiTabs-indicator {
    z-index: 2;
    height: 6px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    border-radius: 1px;
    @media (min-width: 600px) {
      height: 9px;
    }
  }
`;
export const MainContainer = styled(MuiGrid)`
  padding-top: 80px;
  width: 100%;
  text-align: center;
`;
export const Grid = styled(MuiGrid)`
  text-align: center;
  justify-content: center !important;

  .linear-gradient-line {
    display: flex;
    width: 100vw;
    background-color: red;
    height: 2px;
    transform: translate(0, -4px);
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    opacity: 0.75;
    border-radius: 5px;
    @media (min-width: 440px) {
      width: 440px;
    }
    @media (min-width: 570px) {
      width: 570px;
    }
    @media (min-width: 600px) {
      width: 600px;
      height: 3px;
      transform: translate(0, -6px);
    }
    @media (min-width: 990px) {
      width: 780px;
    }
  }
`;
export const CenteredContainer = styled(MuiGrid)`
  padding:5px 15px;
  @media (min-width: 440px) {
    width: 440px !important;
  }
  @media (min-width: 570px) {
    width: 570px !important;
  }
  @media (min-width: 600px) {
    width: 600px !important;
  }
  @media (min-width: 990px) {
    width: 780px !important;
  }
`;

export const RightGrid = styled(MuiGrid)`
  left: 70%;
  top: 128px;

  overflow-wrap: anywhere !important;

  /* background: #000000; */
/*   border: 2px solid;
  border-radius: 45px;
  border-image: linear-gradient(90deg ,#fb8404 0%, #fe1e14 100%) 30; */
  border: double 4px transparent;
  border-radius: 12px;
  background-image: linear-gradient(black, black), radial-gradient(#fb8404 0%, #fe1e14 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  justify-items: center;
  justify-content: center !important;
  text-align: center;
  color: #E22B00;
  font-family: 'Barlow Semi Condensed' !important;
  font-style: normal;
  font-weight: 500;

    img {
      height: 130px;
      width: 130px;
      margin-top: 25px;
      border-radius: 30px;

        @media (max-width: 899px) {
          width: 130px;
        }
    }

    @media (min-width: 900px) {
      flex-direction: column !important;
    }
    
    .RightGridUsername {
      padding-top: 15px;
      font-size: 30px;
      font-weight: 700 !important;
        
        @media (max-width: 899px) {
        margin-left: 20px;
        }
    }

    .UserInfoNumbers {
      font-size: 64px;
      line-height: 77px;
      padding-top: 15px;
    }

    .UserInfoLabel {
      font-size: 24px;
      line-height: 29px;
    }

    .UserBio {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      padding-top: 40px;
      padding-bottom: 50px;

      @media (min-width: 900px) {
        padding-bottom: 300px;
      }
    }
    .TextGrid {
      padding-left: 15px;
    }
    
    .EthIcon {
      height: 20px;
      width: auto;
      margin-top: 0;
      padding-right: 5px;
      padding-top: 2px;
    }

    .WalletText {
      margin: 0;
      font-size: 20px;
    }
    
`
export const TabDivider = styled(Divider)`
  border-width: 1px !important;
  margin-bottom: 10px !important;
  border-image: linear-gradient(90deg ,#fb8404 0%, #fe1e14 100%) 30;
  width: 100%;
  padding: 0;
  height: 0;
`

export const BottomGrid = styled(MuiGrid)`
    @media (min-width: 900px) {
    }

    .TxtGrid {
      margin-bottom: 15px !important;
      @media (min-width: 900px) {
      margin-bottom: 15px !important;
      }
    }

    .CollectGrid {
        @media (min-width: 900px) {
          padding-top: -50px;
        }
}
`

export const CreationText = styled(Typography)`
  font-family: 'Bebas Neue' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 50px !important;
  
  @media (min-width: 700px) {
    font-size: 66px !important;
   }

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  line-height: 0.8 !important;

  color: #FFFFFF;

  padding-bottom: 30px;
`

export const CreationGrid = styled(Grid)`
  justify-content: end;
  text-align: left !important;
  

    .SadImg {
      height: 304px;
      width: 238px;
      margin-right: 70px;
        
        @media (min-width: 700px) {
        height: 404px;
        width: 238px;
        }
    }

    .PointingImg{
      height: 304px;
      width: 238px;
      margin-right: 40px;
        
        @media (min-width: 700px) {
        height: 404px;
        width: 238px;
        }
    }
    
`

export const RedInfoIcon = styled(InfoIcon)`
  color: #E22B00;
`

export const RedirectButtons = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  margin-bottom: 50px !important;
    
  @media (min-width: 700px) {
      width: 417px;
      height: 60px;
   }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`

export const TabGrid = styled(MuiGrid)`
  max-height: 70px;

  @media (max-width: 899px) {
      justify-content: center;
  }
  
  .profilebuttons {
    padding-left: 60px;
    padding-right: 60px;
    
    @media (max-width: 899px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  
  .css-heg063-MuiTabs-flexContainer{
    justify-content: space-evenly;
  }
`;

export const GridContainer = styled(MuiGrid)`
  padding-top: 130px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;

  .iconText{
    @media (min-width: 900px) {
/*     margin-top: 60px; */
    }
  }
  .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
  .css-k008qs{
    justify-content: space-evenly;
  }
  .closersText{
    font-size: 15px;
  }
`

export const CollecGrid = styled(MuiGrid)`
  @media (min-width: 900px) {
    }
`

export const ContentGrid = styled(MuiGrid)`
  padding-left: 50px;
  padding-top: 20px;

  @media (max-width: 400px) {
    padding-left: 0;
  }
`
export const ClosersText = styled(Typography)`
  font-family: 'Bebas Neue' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 38px !important;
  
  @media (min-width: 700px) {
    font-size: 66px !important;
   }

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  line-height: 0.8 !important;

  color: #FFFFFF;

  padding-bottom: 30px;
`
export const RightGridText = styled(Typography)`
  font-family: 'Barlow Semi Condensed' !important;
`

export const ClosersGrid = styled(MuiGrid)`
    .IconText {
      margin: 0;
    };

    .LeurisImg {
        height: 304px;
        width: 400px;
        margin-right: 40px;
          
          @media (min-width: 700px) {
          height: 600px;
          width: 600px;
          }
          @media (min-width: 900px) {
          padding-top: 200px;
          height: 600px;
          width: 600px;
          }
          @media (min-width: 1200px) {
          padding-top: 200px;
          height: 600px;
          width: 800px;
          }
    };

    .ButtonGrid {
      @media (max-width: 650px) {
        justify-content: center;
        margin-top: 15px;
      }
    }
`

export const TestClosersGrid = styled(MuiGrid)`
    .ClosersAvatar {
    width: 86px;
    height: 86px;
    border-radius: 65px;
    }
`
export const ClosersAvatarNameGrid = styled(MuiGrid)`
  @media (min-width: 1200px) {
    padding-left: 40px;
  }
`

export const ClosersAvatar = styled(Avatar)`
  border: double 2px transparent;
  border-radius: 60px;
  background-image: linear-gradient(black, black), radial-gradient(#fb8404 0%, #fe1e14 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  width: 70px !important;
  height: 70px !important;
`

export const Verified = styled(VerifiedIcon)`
  padding-top: 28px;
  padding-left: 5px;
`

export const PostGrid = styled(MuiGrid)`
  background: rgba(255, 255, 255, 0.1);
  padding-bottom: 60px;
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 15px;
  margin-top: 30px;

  .InnerGrid {
    padding: 20px 20px 0 20px;
  }
`

export const ScrollableGrid = styled(MuiGrid)`
  overflow: auto;
  max-height: 680px;
  margin-top: 20px;
  @media (max-width: 899px) {
    margin-bottom: 30px;
  }
`

export const EmptyClosersText = styled(Typography)`
  font-family: 'Bebas Neue' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 38px !important;
  
  @media (min-width: 700px) {
    font-size: 66px !important;
   }

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  line-height: 0.8 !important;

  color: #FFFFFF;

  padding-bottom: 30px;
  padding-top: 100px;
`

export const EmptyClosersGrid = styled(MuiGrid)`
  margin-bottom: 30px;
`

export const ClosersModal = styled(MuiGrid)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    height: auto;
  }

  .select {
    background-color: #1E1E1E;
    color: #FFFFFF;
    border: 2px solid #FC580A;
    height: 46px;
    width: 15vw;
    
    @media (max-width: 992px) {
      width: 100%;
    }

    svg {
      color: #FFFFFF;
    }

    .MuiOutlinedInput-notchedOutline {
      border-style: none !important;
    }
  }

  .MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: white !important;
  }
`

export const ModalContainer = styled(MuiGrid)`
  background-color: #101010;
  width: 60% !important;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 25px;
  @media (min-width: 992px) {
    margin-top: 85px;
  }
  @media (max-width: 650px) {
    width: 80% !important;
    margin-top: 55px;
  }

  .send{
    padding: 32px 20px;
    width: 80%;
    height: 40%;
    border: 2px;
    background-image: repeating-linear-gradient(-50deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px), repeating-linear-gradient(40deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px), repeating-linear-gradient(130deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px), repeating-linear-gradient(220deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px);
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @media (max-width: 650px) {
      margin-top: 30px;
    }
  }

  .xButton{
    padding: 20px 20px 0 0;
    cursor: pointer;
  }

  .TextfieldGrid{
    @media (min-width: 1536px) {
      padding-left: 40px;
    }
  }

  .previewImg{
    width: 100%;
    height: auto;
    margin: 20px;
  }

  .previewVid{
    margin: 20px 0 20px 20px;
    width: 90%;
    height: 90%;
  }

  .BottomGrid {
    margin-top: 40px;
    @media (max-width: 650px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .SendGrid{
    justify-content: center;
  }
`

export const ModalAvatar = styled(Avatar)`
  background-color: rgb(38, 38, 38) !important;
  border-width: 3px;
  border-color: rgb(251,132,4);
  border-style: solid;
  width: 80px !important;
  height: 80px !important;
/*   width: 300px !important;
  height: 300px !important; */
`;

export const TextField = styled(Input)`
  width: 100%;
  font-family: 'Barlow Semi Condensed';
  font-size: 22px;
  font-weight: 400;
  .MuiInputBase-input {
/*     border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important; */
    padding: 15px 15px;
/*     transition: 250ms; */
/*     border: 1px solid rgb(46,46,56) !important; */
    color: #FFFFFF;
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
    ::placeholder{
      color: #FFFFFF;
      opacity: 1;
      font-family: 'Barlow Semi Condensed';
      font-weight: 200;
      font-size: 20px;
    }
  }
`;

export const ModalButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  gap: 15px;
  margin: 20px 0 !important;
    
  @media (min-width: 700px) {
    width: 30%;
    height: 60px;
  }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`
export const CreatePostButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  gap: 15px;
    
  @media (min-width: 700px) {
      width: 300px;
      height: 60px;
   }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`

export const DeleteFab = styled(Fab)`
  justify-self: right;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
    
    :hover{
      transform: scale(1.1);
/*       margin-right: 10px; */
    }
`

export const SelectLabel = styled(InputLabel)`
  color: white;
`

export const ClosersContainer = styled(MuiGrid)`
  margin-top: 30px;
  justify-content: center;
`

export const ModalSelectGrid = styled(MuiGrid)`
  flex-direction: row;
  gap: 30px;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`