import React from "react";
import * as S from "./style";

import useShoppingCart from "../../hooks/useShoppingCart";
import { getCollection } from "../../utils/requests";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CollectionsType } from "../../types/CollectionsType";
import ProjectList from "./CollectibleList/ProjectList";

type ColecionaveisProps = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const CollectionsDetails: React.FC<ColecionaveisProps> = ({ auth, setAuth }) => {
  //eslint-disable-next-line
  const [shoppingCart, setShoppingCart] = useShoppingCart();
  const params = useParams();
  const navigate = useNavigate();

  const [collections, setCollections] = React.useState<CollectionsType | undefined>(undefined);

  const requestAndSetCollection = async () => {
    const response = await getCollection(params?.collectionName ?? "");
    if (response) {
      let collection = response;
      setCollections(collection);
    }
  };

  React.useEffect(() => {
    requestAndSetCollection();
    //eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  scrollToTop();

  return (
    <>
      <S.NavBar
        cartItemCount={shoppingCart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <S.MainContainer
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <S.Container container item>
          <S.TitleContainer item xs={12}>
            <S.Title> Coleções </S.Title>
          </S.TitleContainer>
          <S.CollectionContainer container>
            <Grid container item lg={2} className="imageGrid">
                <img alt="teste" className="collection-image" src={collections ? collections?.thumb : undefined} />
            </Grid>
            <Grid container item lg={10} flexDirection="column" className="collectionInfoGrid">
                <p className="collection-title">{collections ? collections?.title : null}</p>
                <Grid container flexDirection="row" style={{gap: 10}}>
                  <p className="collection-by">por </p>
                  <p className="collection-username" onClick={() => navigate(`/profile/${collections?.creator.username}`)}> {collections ? collections?.creator?.name : null}</p>
                  <S.Verified />
                </Grid>
                <p className="collection-description">{collections ? collections?.description : null}</p>
            </Grid>
          </S.CollectionContainer>
          <Grid container className="nftListGrid">
						{collections
						?	<ProjectList cleanedNftsList={collections ? collections.cleanedNfts : []} lg={2} />
						: null		
						}
          </Grid>
        </S.Container>
      </S.MainContainer>
      <S.Footer />
    </>
  );
};

export default CollectionsDetails;
