import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Divider as MuiDivider } from "@mui/material";
export const MainContainer = styled(MuiGrid)`
  padding-top: 70px;
  padding-bottom: 70px;
  height: 100vh !important;
  @media (min-width: 900px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`;
export const Container = styled(MuiGrid)`
  margin: 0px 2px !important;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #262626 !important;
  padding-top: 15px;
  padding: 2px;

  /* height: 65vh; */

  @media (min-width: 575px) {
    margin-left: 15px !important;
    margin-right: 15px !important;
    padding: 10px;
  }
  @media (min-width: 1076px) {
    width: 1076px !important;
  }
  @media (min-width: 1440px) {
    width: 1440px !important;
  }
`;
export const Grid = styled(MuiGrid)`
  font-family: Barlow Semi Condensed;
  font-style: normal;

  h1 {
    font-weight: bold;
    font-size: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }
  h2 {
    margin: 10px 0px !important;
    font-weight: 600;
    font-size: 16px;
    text-align: center;

    @media (min-width: 575px) {
      font-size: 20px;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 13px;

    color: rgba(255, 255, 255, 0.9);
    margin: 0px;
    @media (min-width: 575px) {
      font-size: 18px;
    }
  }
  h4 {
    font-size: 13px;
    align-items: center;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin: auto;
    @media (min-width: 575px) {
      font-size: 18px;
    }
  }
  .columntitles {
    padding: 0px 2px;
    /* height: 100%; */
    display: flex;
    @media (min-width: 575px) {
      padding: 0px 5px;
    }
  }
  .removeitem {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    color: white;
    font-size: 12px;
    text-decoration: underline;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
  }
  img {
    border-radius: 10px;
    max-height: 70px;
    max-width: 49px;
    @media (min-width: 575px) {
      max-height: 120px;
      max-width: 84px;
    }
    @media (min-width: 600px) {
      max-height: 100px;
      max-width: 70px;
    }
    @media (min-width: 900px) {
      max-height: 120px;
      max-width: 84px;
    }
  }
  input {
    width: 50px;
    height: 27px;
    border-radius: 5px;
    border-style: none;
    text-align: center;
    background-color: rgb(70, 70, 70);
    color: white;
  }
  .head-divider {
    padding-right: 3px;
  }
  .itemcontainer {
    height: 80px;
    @media (min-width: 575px) {
      height: 115px;
    }
    @media (min-width: 900px) {
      height: 135px;
    }
  }
  .imgContainer {
    height: 200px;
    justify-content: center;
    margin: 10px 0px;
    @media (min-width: 900px) {
      justify-content: end;
      height: 400px;
    }
  }
  .noItensMessage {
    justify-content: center;
    @media (min-width: 900px) {
      justify-content: start;
    }
  }
  .sadz {
    margin: 0px;
    padding: 0px;
    height: 200px !important;
    display: flex;
    max-width: 500px;
    max-height: 500px;
    @media (min-width: 900px) {
      height: 400px !important;
    }
  }
  span {
    width: 100%;
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    align-items: center;
    letter-spacing: 0.015em;
    color: #ffffff;
    padding: 0px 30px;
    text-align: center;
    @media (min-width: 575px) {
      padding: 0px 50px;
    }
    @media (min-width: 900px) {
      text-align: left;
      padding: 0px 0px;
      padding-top: 120px;
    }
    @media (min-width: 966px) {
      padding-right: 60px;
    }
    @media (min-width: 1438px) {
      padding-right: 90px;
    }
    @media (min-width: 1451px) {
      padding-right: 120px;
    }
  }
  button.gotogome {
    font-size: 25px;
    background: linear-gradient(180deg, #fb8404 0%, #fe1e14 155.15%), #c4c4c4;
    border-radius: 36.5px;
    border-style: none;
    margin: 10px 0px;
    padding: 0px 50px;
    color: white;
    cursor: pointer;
    text-align: center;
  }
  button.fecharPedido {
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    align-items: center;

    color: #ffffff;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 10px;
    border-style: none;
    padding: 5px 30px;
    margin: 10px 0px;
    cursor:pointer;
  }
`;

export const CartContainer = styled(MuiGrid)`
  background-color: #262626;
  border-radius: 5px;
  padding-bottom: 10px;
`;
export const ShoppingCart = styled(ShoppingCartIcon)`
  margin-right: 10px;
`;
export const Divider = styled(MuiDivider)`
  background-color: rgba(255, 255, 255, 0.3);
  height: 1px;
  display: flex;
`;
export const ItemsWrapper = styled(MuiGrid)`
  overflow-y: scroll;
  max-height: 72vh;
`;

export const Subtotal = styled.span`
  text-align: right !important;
  margin-top:15px !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
  font-size: 20px !important;
  line-height: 20px !important;
`;

export const Tributos = styled(Subtotal)`
  margin-top: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 0.7 !important;
`;
