import { useState } from "react";

const useCountdown = (date: string) => {
  const [day, setDay] = useState<number>();
  const [hour, setHour] = useState<number>();
  const [minute, setMinute] = useState<number>();
  const [second, setSecond] = useState<number>();
  
  const countdown = () => {
    const countDate = new Date(date).getTime();
    const now = new Date().getTime();
  
    const interval = countDate - now;
  
    const seconds = 1000;
    const minutes = seconds * 60; 
    const hours = minutes * 60;
    const days = hours * 24;
  
    const dayNumber = Math.floor(interval / days);
    const hourNumber = Math.floor((interval % days) / hours);
    const minuteNumber = Math.floor((interval % hours) / minutes);
    const secondNumber = Math.floor((interval % minutes) / seconds);

    setDay(dayNumber);
    setHour(hourNumber);
    setMinute(minuteNumber);
    setSecond(secondNumber);
  }

  setInterval(countdown, 1000);

  return [day, hour, minute, second];
};

export default useCountdown;