import styled from "styled-components";
import { Grid } from "@mui/material";
import { ReactComponent as ViuzzLogo } from "../../assets/viuzzLogo.svg";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { Divider as MuiDivider } from "@mui/material";
import { NavLink as RRDNavLink } from "react-router-dom";
import CartIconBadge from "../CustomComponents/CartIconBadge/CartIconBadge";
import SearchIcon from '@mui/icons-material/Search';

export const NavLink = styled(RRDNavLink)``;
export const MainContainer = styled(Grid)`
  position: fixed;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+20,000000+20,000000+97&1+20,0+97 */
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0) 97%
  ); /* FF3.6-15 */
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0) 97%
  ); /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0) 97%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

  height: 65px;
  align-items: center;
  z-index: 99 !important;
  @media (min-width: 576px) {
    height: 70px;
  }

  @media only screen and (min-width: 992px) {
    height: 70px;
  }
`;
export const Container = styled(Grid)`
  align-items: center;
`;
export const Item = styled(Grid)`
  && {
    font-family: Barlow Semi Condensed;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 36px; */
    letter-spacing: 0em;
    text-align: left;
    flex-direction: end;
    .last-item {
      padding-right: 80px;
    }
    a {
      padding: 15px;
      padding-top: 5px;
      padding-bottom: 8px;
      border-radius: 50%;
      text-decoration: none;
      color: white;

      .logo {
        background: none;
      }
      a .logo :hover {
        transform: scale(1.3);
      }
    }
    .active,
    a:hover {
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      background: linear-gradient(180deg, #fb8404 0%, #fe1e14 155.15%), #c4c4c4;
      border-radius: 16px;
    }

    button {
      padding: 15px;
      padding-top: 5px;
      padding-bottom: 8px;
      border-radius: 50%;
      text-decoration: none;
      color: white;
      background-color: rgba(0, 0, 0, 0);
      border-width: 0px;
      line-height: 30px;
    }
    /* .active,
    button:hover {
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      background: linear-gradient(180deg, #fb8404 0%, #fe1e14 155.15%), #c4c4c4;
      border-radius: 16px;
      cursor: pointer;
    } */

    a.logo {
      background: none;
      display: flex;
      padding-top: 0px;
      padding-left: 0px;
      height: 60px;
      @media (min-width: 992px) {
        height: 70px;
      }
    }
    a.logo.active {
      background: none;
    }
    a.logo:hover {
      background: none;
    }
  }
`;
export const NavItem = styled(Grid)`
  display: none;
  @media (min-width: 992px) {
    display: inline-flex;
  }

  a {
    text-align: right;
  }

  .shoppingcart {
    button {
      padding: 0px;
    }
  }
`;
export const MenuContainer = styled(Grid)`
  @media (min-width: 992px) {
    display: none !important;
    padding: 0px !important;
    margin: 0px !important;
  }
`;
export const MenuItem = styled(Grid)`
  padding-right: 15px !important;
`;
export const MenuIcon = styled(MenuRoundedIcon)`
  font-size: 3em !important;
  @media (min-width: 576px) {
    font-size: 3em !important;
  }

  @media (min-width: 992px) {
    display: none !important;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 3.2em !important;
  }

  :hover {
    cursor: pointer;
  }
`;

export const CartIcon = styled(ShoppingCart)`
  padding: 0px !important;
  margin: 0px 15px;
  font-size: 2.2em !important;
  margin-top: 5px !important;
  :hover {
    cursor: pointer;
  }
`;
export const Logo = styled(ViuzzLogo)`
  padding-top: 8px;
  padding-bottom: 5px;
  display: inline-flex;
  height: 50px;
  padding-left: 10px;
  @media (min-width: 576px) {
    height: 60px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  @media (min-width: 992px) {
    /* height: 70px; */
    padding-left: 30px;
    /* padding-top: 10px; */
  }
  :hover {
    transform: scale(1.1);
  }
`;
export const DrawerContainer = styled(Grid)`
  height: 100%;
  width: 300px !important;
  padding: 15px;
  background-color: rgb(30, 30, 30);
  justify-content: flex-end !important;
  display: block !important;
  text-align: right;
  font-family: Barlow Semi Condensed;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  color: white;
`;
export const DrawerItem = styled(Grid)`
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  a.active {
    padding: 5px;
    border-radius: 10px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, #fb8404 0%, #fe1e14 155.15%), #c4c4c4;
  }
  button {
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    color: white;
    line-height: 20px;
  }
`;
export const Divider = styled(MuiDivider)`
  border-width: 1px !important;
  background-color: grey !important;
`;

export const StyledCartIconBadge = styled(CartIconBadge)`
`;

export const SearchDrawerContainer = styled(Grid)`
  height: 100%;
  width: 300px !important;
  padding: 15px;
  background-color: rgb(30, 30, 30);
  justify-content: flex-end !important;
  display: block !important;
  font-family: Barlow Semi Condensed;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  color: white;
`;

export const NavSearchIcon = styled(SearchIcon)`
  padding-left: 30px;
  padding-top: 10px;
  cursor: pointer;
`
// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }
// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
