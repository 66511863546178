import { Grid, SwipeableDrawer } from "@mui/material";
import * as React from "react";
import {
  NavLink,
  Link,
  useNavigate,
} from "react-router-dom";
import Avatar from "./Avatar/Avatar";
import { SearchBar } from "../SearchBar/SearchBar";
import { refreshAccessToken } from "../../utils/requests";

import * as S from "./style";

type Props = {
  cartItemCount?: number;
  auth: any;
  setAuth: (auth: any) => void;
};
const Navbar: React.FC<Props> = ({ cartItemCount, auth, setAuth }) => {
  type DrawerItem = { item: string; link: string; classname: string };

  var drawerItems: DrawerItem[] = [
    // { item: "Avatares", link: "/avatares", classname: "" },
    { item: "Colecionáveis", link: "/colecoes", classname: "" },
  ];

  const localUserData = JSON.parse(localStorage.getItem("user")!);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openSearchDrawer, setOpenSearchDrawer] = React.useState(false);
  const [search, setSearch] = React.useState<boolean>();

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("shoppingcart");
    navigate("/");
    setAuth(false);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const isSearching = () => {
    setSearch(!search);
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    refreshAccessToken().then(res => {
      if (res?.accessToken && token !== res?.accessToken) {
        localStorage.setItem("token", res?.accessToken);
      };
    })
  }, []);

  const renderDrawerItems = () => {
    return (
      <S.DrawerContainer
        id="topo"
        container
        item
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        {drawerItems.map((menuitem) => {
          return (
            <React.Fragment key={menuitem.item}>
              <S.DrawerItem key={menuitem.item} item xs={12}>
                <NavLink
                  to={menuitem.link}
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                >
                  {menuitem.item}
                </NavLink>
              </S.DrawerItem>
              <S.Divider />
            </React.Fragment>
          );
        })}
        <S.DrawerItem key={"viuzztv"} item xs={12}>
          <a href={"https://viuzz.com.br/"}>ViuzzTV</a>
        </S.DrawerItem>
        <S.Divider />
        {auth &&
          <>
            <S.DrawerItem key={"minhaconta"} item xs={12}>
              <Link to={"/meuperfil"}>Minha Conta</Link>
            </S.DrawerItem>
            <S.Divider />
            <S.DrawerItem key={"meuperfil"} item xs={12}>
              <Link to={`/profile/${localUserData?.username}`}>Meu Perfil</Link>
            </S.DrawerItem>
            <S.Divider />
          </>
        }
        <S.DrawerItem key={"busca"} item xs={12} onClick={() => {toggleSearchDrawer()}}>
          Buscar
        </S.DrawerItem>
        <S.Divider />
        {auth ? (
          <React.Fragment>
            <S.DrawerItem key="menusair" item xs={12}>
              <button
                onClick={() => {
                  setAuth !== undefined && setAuth(false);
                  handleLogout();
                }}
              >
                Sair
              </button>
            </S.DrawerItem>
            <S.Divider />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <S.DrawerItem key="menulogin" item xs={12}>
              <NavLink to={"/login"}>Login</NavLink>
            </S.DrawerItem>
            <S.Divider />
          </React.Fragment>
        )}
      </S.DrawerContainer>
    );
  };

    const renderSearchDrawer = () => {
      return (
        <S.SearchDrawerContainer
          id="topo"
          container
          item
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <SearchBar />
        </S.SearchDrawerContainer>
      );
    };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

    const toggleSearchDrawer = () => {
      setOpenSearchDrawer(!openSearchDrawer);
    };

  return (
    <S.MainContainer container alignItems="center">
      <Grid item container xs={6} sm={3} justifyContent="flex-start">
        <S.Item item>
          <S.NavLink to="/" className="logo">
            <S.Logo />
          </S.NavLink>
        </S.Item>
      </Grid>
      <S.MenuContainer
        item
        container
        xs={6}
        sm={9}
        justifyContent="flex-end"
        direction={"row"}
      >
        <S.MenuItem item style={{ marginTop: "5px" }}>
          <Link to="/carrinho">
            <S.StyledCartIconBadge cartItemCount={cartItemCount ?? 0} />
          </Link>
        </S.MenuItem>
        <S.MenuItem item container justifyContent="flex-end" xs={3} md={1}>
          <S.MenuIcon onClick={() => toggleDrawer()} />
        </S.MenuItem>
      </S.MenuContainer>
      <S.Item
        item
        xs={4}
        sm={9}
        container
        spacing={4}
        justifyContent="flex-end"
        alignItems="center"
        className="nav-items"
      >
        {drawerItems.map((draweritem) => {
/*           // eslint-disable-next-line react-hooks/rules-of-hooks
          let resolved = useResolvedPath(draweritem.link);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          let match = useMatch({ path: resolved.pathname, end: true }); */
          return (
            <S.NavItem
              key={draweritem.item}
              className={draweritem.classname}
              item
            >
              <Link to={draweritem.link} /* className={match ? "active" : ""} */>
                {draweritem.item}
              </Link>
            </S.NavItem>
          );
        })}
        <S.NavItem key="viuzztvnav" item>
          <a href="https://viuzz.com.br/">Viuzz TV</a>
        </S.NavItem>
        <S.NavItem
          className="shoppingcart"
          key="shoppingcart"
          item
          style={{ marginTop: "5px" }}
        >
          {auth ? (
            <Link
              to="/carrinho"
/*               className={
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useMatch({ path: "/carrinho", end: true }) ? "active" : ""
              } */
            >
              <S.StyledCartIconBadge cartItemCount={cartItemCount ?? 0} />
            </Link>
          ) : (
            ""
          )}
        </S.NavItem>
        <S.NavItem key="user" item xs={2}>
          {auth ? (
            <Avatar auth={auth} setAuth={setAuth} />
          ) : (
            <Link to="/login">Login</Link>
          )}
          <S.NavSearchIcon onClick={() => /* toggleSearchDrawer() */ isSearching()} />
          {search &&
            <SearchBar />
          }
        </S.NavItem>
      </S.Item>
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={(e) => toggleDrawer()}
        onOpen={(e) => toggleDrawer()}
        variant="temporary"
      >
        {renderDrawerItems()}
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={openSearchDrawer}
        onClose={(e) => toggleSearchDrawer()}
        onOpen={(e) => toggleSearchDrawer()}
        variant="temporary"
      >
        {renderSearchDrawer()}
      </SwipeableDrawer>
    </S.MainContainer>
  );
};

export default Navbar;
