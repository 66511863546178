import React from "react";

import useShoppingCart from "../../hooks/useShoppingCart";
import * as S from "./style";

import desktopRoadmap from "../../assets/newdesktop-roadmap.png";
import Box from '@mui/material/Box';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


type Props = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};
const Home: React.FC<Props> = ({auth, setAuth}) => {
  //eslint-disable-next-line
  const [shoppingCart, setShoppingCart] = useShoppingCart();
  return (
    <>
      <S.NavBar cartItemCount={shoppingCart.length} auth={auth} setAuth={setAuth}/>
      <S.Welcome auth={auth} setAuth={setAuth}/>
      <S.Roadmap src={desktopRoadmap}/>
      <S.Avatar />
      <S.Nft />
      <S.Faq />
      <S.Footer />
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <a href="mailto:contato@viuzz.com.br">
          <S.SupportFab color="primary" aria-label="support">
            <SupportAgentIcon />
          </S.SupportFab>
        </a>
      </Box>
    </>
  );
};

export default Home;
