import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const Grid = styled(MuiGrid)`
  text-align: justify;
  h1 {
    text-align: left !important;
    font-size: 1.2rem;
    padding: 15px 15px;
  }
  h1.maintitle {
    font-size: 1.3rem;
    text-align: center !important;
  }
  p {
    justify-content: justify !important;
    padding: 0px 15px;
    tab-size: 8;
  }
  div.catchy {
    border-radius: 5px;
    background-color: #f7a55d;
    margin: 20px 10px;
    padding: 1px 0px;
    color: black !important;
  }
  table {
    text-align: center;
    /* margin:0px 10px; */
    /* display: block; */
    width: 100%;
    margin-right: 10px !important;
    border-radius: 5px;
  }
  th {
    background-color: #ff9400;
    color: white;
    line-height: 1.5rem;
  }
  td.bgorange {
    background-color: #ff9400;
    padding: 10px;
  }
  td.bggrey {
    background-color: #2d2d27;
    p {
      text-align: justify;
    }
  }
`;

export const TableWrapper = styled.div`
  padding: 10px !important;
`;

export const BackButton = styled(ArrowBackIcon)`
  color: white;
  font-size: 45px !important ;
  padding: 10px;
  cursor:pointer;
`;
