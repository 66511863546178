import maintenance from '../../assets/maintenance.jpg'
import * as S from './style'

const Maintenance: React.FC = () => {

  return (
    <S.BlackGrid container>
      <img src={maintenance} alt="Página em manutenção" />
    </S.BlackGrid>
  )
}

export default Maintenance