import React from "react";
import * as S from "./style";
import * as EmailValidator from "email-validator";
import { register, login } from "../../utils/requests";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
//toast
import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "./../../components/CustomComponents/ToastContainer/toast";
import { UserType } from "../../types/UserType";

type Props = {
  auth: any;
  setAuth: (auth: any) => void;
};

const Signup: React.FC<Props> = ({ auth, setAuth }) => {
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);

  const [name, setName] = React.useState<{ name: string; valid: boolean }>({
    name: "",
    valid: true,
  });
  const [email, setEmail] = React.useState<{ email: string; valid: boolean }>({
    email: "",
    valid: true,
  });
  const [username, setUsername] = React.useState<{ username: string; valid: boolean }>({
    username: "",
    valid: true,
  });
  const [password, setPassword] = React.useState<{
    password: string;
    valid: boolean;
  }>({ password: "", valid: true });

  //handlers for all textfields
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName((prevState) => {
      return {
        ...prevState,
        name: e.target.value,
        valid:
          40 >= e.target.value.length && e.target.value.length >= 4
            ? true
            : false,
      };
    });
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail((prevState) => {
      return {
        ...prevState,
        email: e.target.value,
        valid: EmailValidator.validate(e.target.value),
      };
    });
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword((prevState) => {
      return {
        ...prevState,
        password: e.target.value,
        valid: e.target.value.length >= 4 ? true : false,
      };
    });
  };
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername((prevState) => {
      return {
        ...prevState,
        username: e.target.value,
        valid:
          10 >= e.target.value.length && e.target.value.length >= 4
            ? true
            : false,
      };
    });
  };

  const isValid = () => {
    if (name.name.length === 0) return false;
    if (email.email.length === 0) return false;
    if (password.password.length === 0) return false;
    if (username.username.length === 0) return false;
    return name.valid && email.valid && password.valid && username.valid;
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isValid()) {
      let response: UserType = await register(
        name.name,
        email.email,
        password.password,
        username.username
      );
      if (response.email && response.username && recaptchaToken) {
        showToast("Usuário cadastrado com sucesso!", "success");
        setTimeout(() => {
          localStorage.setItem("user", JSON.stringify(response));
          setAuth(true);
          navigate("/");
        }, 3000);
        login(email.email, password.password, recaptchaToken).then((res) => {
          if(res?.accessToken){
            localStorage.setItem("token", res?.accessToken);
          }else{
            setAuth(false);
          }
        });
      } else {
        showToast("Email ou Nome de usuário já cadastrado!", "error");
      }
    } else {
      showToast("Por favor, preencha todos os campos corretamente", "error");
    }
  };

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    //Login, ForgotPassword, Transaction, CreateIntent
    const token = await executeRecaptcha('Login');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <S.MainContainer container>
      <S.Grid item xs={12} md={6} lg={5} container>
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <S.FormContainer>
          <h1>Seja Bem-vindo(a)!</h1>
          <S.TextField
            type="text"
            value={name.name}
            error={!name.valid}
            placeholder="Nome"
            onChange={handleNameChange}
          />
          <S.TextField
            type="email"
            value={email.email}
            error={!email.valid}
            placeholder="Email"
            onChange={handleEmailChange}
          />
          <S.TextField
            type="password"
            value={password.password}
            error={!password.valid}
            placeholder="Senha"
            onChange={handlePasswordChange}
          />
          <S.TextField
            type="text"
            value={username.username}
            error={!username.valid}
            placeholder="Nome de usuário"
            onChange={handleUsernameChange}
          />
          <S.ButtonContainer>
            Ao se cadastrar você concorda com os Termos de uso e nossa Política
            de privacidade. E em receber notícias, ofertas especiais e outras
            informações da Viuzz e das empresas do grupo.
            <button className={"continue"} onClick={handleSubmit}>
              Continuar
            </button>
            <S.LinkContainer>
              <S.LoginLink to="/login" > Já possui conta? <b>Entrar</b></S.LoginLink>
            </S.LinkContainer>
          </S.ButtonContainer>
        </S.FormContainer>
      </S.Grid>
    </S.MainContainer>
  );
};

export default Signup;
