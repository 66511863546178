import React from "react";
import * as S from "./style";
import { Link } from "react-router-dom";
const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <S.Grid container>
        <S.Grid item xs={12}>
          <Link to="/"><S.BackButton /></Link>
        </S.Grid>
        <S.Grid xs={12}>
          <h1 className="maintitle">POLÍTICA DE PRIVACIDADE - VIUZZ</h1>
        </S.Grid>
        <S.Grid item xs={12}>
          <p>
            A Viuzz está comprometida em resguardar sua privacidade. O intuito
            deste documento é esclarecer quais informações são coletadas dos
            usuários de nosso site{" "}
            <a href="https://app.www.viuzz.com.br">www.viuzz.com.br</a>
            &nbsp;e respectivos serviços – e de que forma esses dados são
            manipulados e utilizados.
          </p>
          <p>
            Se você não concorda com o conteúdo desta política, não é
            recomendável utilizar quaisquer de nossos serviços.
          </p>
          <div className={"catchy"}>
            <p>
              <b>Por que a Empresa trata seus dados pessoais?</b>
            </p>
            <p>
              O presente termo permite que a Viuzz use suas informações pessoais
              para diferentes finalidades.
            </p>
            <p>
              O e-mail é utilizado para a operação de envio do material ou
              informação por você requisitada no preenchimento do formulário.
              Também pode ser usado para envio de Newsletters, sempre
              relacionadas aos temas da Viuzz.
            </p>
            <p>
              Por fim, o e-mail será utilizado ainda para comunicar o lançamento
              de novidades da Viuzz e parceiros. No entanto, o usuário pode
              cancelar a assinatura a qualquer momento
            </p>
            <p>
              Em todos os e-mails que enviamos há sempre um link para cancelar a
              assinatura disponível nas últimas linhas. Ao clicar nesse link
              você será automaticamente descadastrado da lista.
            </p>
            <p>
              Os dados poderão ser divulgados como pesquisas e estatísticas, não
              sendo reveladas abertamente nenhuma informação pess oal, a menos
              que autorizada explicitamente.
            </p>
            <p>
              Funcionários da Viuzz poderão eventualmente entrar em contato via
              e - mail ou telefone para fazer pesquisas ou apresentar produtos e
              serviços. Suas informações pessoais serão compartilhadas com
              nossos parceiros apenas quando a parceria estiver explícita na
              página onde consta o formulário para o recebimento de conteúdo
              educativo.
            </p>
          </div>
          <h1>
            1. CONCEITOS BÁSICOS: O QUE VOCÊ PRECISA SABER PARA COMPREENDER ESTA
            POLÍTICA?
          </h1>
          <S.TableWrapper>
            <table>
              <tr>
                <th>Termo</th>
                <th>Conceito</th>
              </tr>
              <tr>
                <td className={"bgorange"}>Dado Pessoal</td>
                <td className={"bggrey"}>
                  <p>
                    É uma informação relacionada a uma pessoa física e que seja
                    capaz de identificar a pessoa ou tornar possível a sua
                    identificação.
                  </p>
                  <p>
                    A depender da sensibilidade do Dado Pessoal o mesmo será
                    classificado como sensível, que nos termos da lei é:
                  </p>
                  <p>
                    <i>
                      “II - dado pessoal sensível: dado pessoal sobre origem
                      racial ou étnica, convicção religiosa, opinião política,
                      filiação a sindicato ou a organização de caráter
                      religioso, filosófico ou político, dado referente à saúde
                      ou à vida sexual, dado genético ou biométrico, quando
                      vinculado a uma pessoa natural;”
                    </i>
                  </p>
                  <p>
                    São exemplos de dados pessoais que podem permitir a sua
                    identificação: Nome, CPF, telefone, e-mail, placa do seu
                    veículo etc.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={"bgorange"}>Tratamento</td>
                <td className={"bggrey"}>
                  <p>
                    É toda forma de uso que podemos fazer dos seus Dados
                    Pessoais, incluindo, mas não se limitando às seguintes
                    atividades: coleta, armazenamento, consulta, uso,
                    compartilhamento, transmissão, classificação, reprodução,
                    exclusão e avaliação.{" "}
                  </p>
                  <p>
                    Este termo (e outros derivados dele) não serão utilizados
                    com letras maiúsculas neste documento.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={"bgorange"}>Titular</td>
                <td className={"bggrey"}>
                  <p>
                    É você, a pessoa física a quem os Dados Pessoais se referem.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={"bgorange"}>Empresa</td>
                <td className={"bggrey"}>
                  <p>
                    Somos nós, a <b>VIUZZ</b>.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={"bgorange"}>Política</td>
                <td className={"bggrey"}>
                  <p>É esta Política de Privacidade.</p>
                </td>
              </tr>
            </table>
          </S.TableWrapper>
          <h1>2. QUE TIPOS DE DADOS PESSOAIS A EMPRESA TRATA?</h1>
          <p>
            Em nosso site, as informações são coletadas das seguintes formas:
          </p>
          <p>
            Informações fornecidas por você – Coletamos informações de
            identificação pessoal – como nome, telefone, e-mail, empresa em que
            trabalha e cargo – via preenchimento dos formulários. Eventualmente,
            a solicitação de alguma informação pode ser feita por meio de
            contato direto Viuzz com os usuários via e-mail ou telefone.
          </p>
          <p>
            Informações de navegação no site – Quando você visita nosso site, é
            inserido um "cookie" no seu navegador por meio do software Google
            Analytics, para identificar quantas vezes você retorna ao nosso
            endereço. São coletadas, anonimamente, informações, como endereço
            IP, localização geográfica, fonte de referência, tipo de navegador,
            duração da visita e páginas visitadas.
          </p>
          <p>
            A Viuzz armazena informações a respeito de todos os contatos já
            realizados com nossos usuários, a partir de nossas páginas e
            interações via e -mail.
          </p>
          <h1>3. COM QUEM A EMPRESA COMPARTILHA OS SEUS DADOS PESSOAIS?</h1>
          <p>
            Poderão ver suas informações pessoais apenas os funcionários da
            Viuzz. Eventualmente, caso a inserção de suas informações se dê em
            ações criadas em parcerias, os parceiros explicitamente
            identificados também terão acesso às informações. Nenhuma informação
            pessoal poderá ser divulgada publicamente.
          </p>
          <p>
            A Viuzz também se compromete a não vender, alugar ou repassar suas
            informações para terceiros. Excepcionalmente, os dados poderão ser
            compartilhados, com a finalidade expressamente prevista na Lei Geral
            de Proteção aos Dados Pessoais, sobretudo a boa-fé.
          </p>
          <p>
            Além disso, embora trabalhemos com boas práticas de proteção e
            segurança, em casos de incidentes envolvendo os dados pessoais dos
            usuários, a empresa se compromete a atuar de forma a sanar a
            situação o mais rápido possível, de acordo com os critérios
            estabelecidos pela Agência Nacional de Proteção de Dados Pessoais.
          </p>
          <h1>3.1 Sobre o compartilhamento de conteúdo nas redes sociais</h1>
          <p>
            Ao clicar nos botões de compartilhamento de conteúdo nas mídias
            sociais disponíveis em nossas páginas, o usuário estará publicando o
            conteúdo por meio de seu perfil na rede selecionada. A Viuzz não tem
            acesso ao login e senha dos usuários nessas redes, nem publicará
            conteúdo em nome do usuário sem que ele realize esta ação.
          </p>
          <h1>4. QUAIS SÃO OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?</h1>
          <p>
            <b>Os Dados Pessoais são seus</b>&nbsp;e a lei brasileira lhe
            garante uma série de direitos relacionados a eles. Nós, da{" "}
            <b>VIUZZ</b>, estamos comprometidos com o cumprimento desses
            direitos e, nessa seção, vamos explicar quais são esses direitos e
            como você pode exercê-los. Veja a seguir:
          </p>
          <S.TableWrapper>
            <table>
              <tr>
                <th>Seus Direitos</th>
                <th>Explicação</th>
              </tr>
              <tr>
                <td className="bgorange">Confirmação e Acesso</td>
                <td className="bggrey">
                  <p>
                    Você pode solicitar à Empresa a confirmação sobre a
                    existência de tratamento dos seus Dados Pessoais para que,
                    em caso positivo, você possa acessá-los, inclusive por meio
                    de solicitação de cópias dos registros que temos sobre você.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">Correção</td>
                <td className="bggrey">
                  <p>
                    Você pode solicitar a correção dos seus Dados Pessoais caso
                    estes estejam incompletos, inexatos ou desatualizados.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">
                  Anonimização, bloqueio ou eliminação
                </td>
                <td className="bggrey">
                  <p>
                    Você pode solicitar <b>(a)</b> a anonimização dos seus Dados
                    Pessoais, de forma que eles não possam mais ser relacionados
                    a você e, portanto, deixem de ser Dados Pessoais; <b>(b)</b>{" "}
                    o bloqueio dos seus Dados Pessoais, suspendendo
                    temporariamente a sua possibilidade de os tratarmos para
                    certas finalidades; e <b>(c)</b> a eliminação dos seus Dados
                    Pessoais, caso em que deveremos apagar todos os seus Dados
                    Pessoais sem possibilidade de reversão.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">Portabilidade</td>
                <td className="bggrey">
                  <p>
                    Você pode solicitar que a Empresa forneça os seus Dados
                    Pessoais em formato estruturado e interoperável visando à
                    sua transferência para um terceiro, desde que essa
                    transferência não viole a propriedade intelectual ou segredo
                    de negócios da Empresa.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">
                  Informação sobre o compartilhamento
                </td>
                <td className="bggrey">
                  <p>
                    Você tem o direito de saber quais são as entidades públicas
                    e privadas com as quais a Empresa realiza uso compartilhado
                    dos seus Dados Pessoais. Manteremos, no item 3 dessa
                    Política, uma indicação das nossas relações com terceiros
                    que podem envolver o compartilhamento de Dados Pessoais. Em
                    todo caso, se você tiver dúvidas ou quiser mais detalhes,
                    você tem o direito de nos solicitar essas informações. A
                    depender do caso, podemos limitar as informações fornecidas
                    a você caso a sua divulgação possa violar a propriedade
                    intelectual ou segredo de negócios da Empresa.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">
                  Informação sobre a possibilidade de não consentir
                </td>
                <td className="bggrey">
                  <p></p>
                  <p>
                    Você tem o direito de saber quais são as entidades públicas
                    e privadas com as quais a Empresa realiza uso compartilhado
                    dos seus Dados Pessoais. Manteremos, no item 3 dessa
                    Política, uma indicação das nossas relações com terceiros
                    que podem envolver o compartilhamento de Dados Pessoais. Em
                    todo caso, se você tiver dúvidas ou quiser mais detalhes,
                    você tem o direito de nos solicitar essas informações. A
                    depender do caso, podemos limitar as informações fornecidas
                    a você caso a sua divulgação possa violar a propriedade
                    intelectual ou segredo de negócios da Empresa.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">
                  Informação sobre a possibilidade de não consentir
                </td>
                <td className="bggrey">
                  <p>
                    Você tem o direito de receber informações claras e completas
                    sobre a possibilidade e as consequências de não fornecer
                    consentimento, quando ele for solicitado pela Empresa. O seu
                    consentimento, quando necessário, deve ser livre e
                    informado. Portanto, sempre que pedirmos seu consentimento,
                    você será livre para negá-lo – nesses casos, é possível que
                    alguns serviços não possam ser prestados.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">Revogação do consentimento</td>
                <td className="bggrey">
                  <p>
                    Caso você tenha consentido com alguma finalidade de
                    tratamento dos seus Dados Pessoais, você pode sempre optar
                    por retirar o seu consentimento. No entanto, isso não
                    afetará a legalidade de qualquer Tratamento realizado
                    anteriormente à revogação. Se você retirar o seu
                    consentimento, é possível que fiquemos impossibilitados de
                    lhe prestar certos serviços, mas iremos avisá-lo quando isso
                    ocorrer.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="bgorange">Oposição</td>
                <td className="bggrey">
                  <p>
                    A lei autoriza o tratamento de Dados Pessoais mesmo sem o
                    seu consentimento ou um contrato conosco. Nessas situações,
                    somente trataremos seus Dados Pessoais se tivermos motivos
                    legítimos para tanto, como, por exemplo, quando for
                    necessário para garantir a segurança das operações de vendas
                    da empresa ou no caso de cumprimento de obrigação legal ou
                    regulatória por parte do Controlador. Caso você não concorde
                    com alguma finalidade de tratamento dos seus Dados Pessoais,
                    você poderá apresentar oposição, solicitando a sua
                    interrupção.
                  </p>
                </td>
              </tr>
            </table>
          </S.TableWrapper>
          <h1 className={"maintitle"}>AVISOS IMPORTANTES</h1>
          <p>
            Para sua segurança, sempre que você apresentar uma requisição para
            exercer seus direitos,{" "}
            <b>
              a VIUZZ poderá solicitar algumas informações e/ou documentos
              complementares para que possamos comprovar a sua identidade
            </b>
            , buscando impedir fraudes. Fazemos isso para garantir a segurança e
            a privacidade de todos.
          </p>
          <p>
            Em alguns casos,
            <b>
              a Empresa pode ter motivos legítimos para deixar de atender a uma
              solicitação de exercício de direitos.
            </b>
            Essas situações incluem, por exemplo, casos em que uma revelação de
            informações específicas poderia violar direitos de propriedade
            intelectual ou segredos de negócio da Empresa ou de terceiros, bem
            como casos em que pedidos de exclusão de dados não possam ser
            atendidos em razão da existência de obrigação da Empresa de reter
            dados, seja para cumprir obrigações legais, regulatórias ou para
            possibilitar a defesa da Empresa ou de terceiros em disputas de
            qualquer natureza.
          </p>
          <p>
            Ainda,
            <b>
              algumas solicitações podem não ser respondidas de forma imediata,
            </b>
            mas a Empresa se compromete a responder todas as requisições em um
            prazo razoável e sempre em conformidade com a legislação aplicável.
          </p>
          <p>
            Caso você tenha alguma dúvida sobre essas questões ou sobre como
            você pode exercer seus direitos, fique à vontade para entrar em
            contato conosco por meio dos canais informados nesta Política
          </p>
          <h1>5. POR QUANTO TEMPO OS DADOS PESSOAIS SERÃO ARMAZENADOS?</h1>
          <p>
            A Empresa possui uma política de retenção de Dados Pessoais alinhada
            com a lei aplicável. Dados Pessoais são armazenados somente pelo
            tempo que forem necessários para cumprir com as finalidades para as
            quais foram coletados, salvo se houver qualquer outra razão para sua
            manutenção como, por exemplo, cumprimento de quaisquer obrigações
            legais, regulatórias, contratuais, entre outras permitidas sob a
            lei.
          </p>
          <p>
            Sempre fazemos uma análise técnica para determinar o período de
            retenção adequado para cada tipo de Dado Pessoal coletado,
            considerando a sua natureza, necessidade de coleta e finalidade para
            a qual ele será tratado, bem como eventuais necessidades de retenção
            para o cumprimento de obrigações ou o resguardo de direitos.
          </p>
          <p>
            Com relação aos dados pessoais tratados pela VIUZZ, com exceção de
            alguns dados pessoais no contexto da relação de trabalho, que são
            armazenados por 5 anos (tempo da prescrição trabalhista), como
            recibo de férias, e valores de salário, os demais dados pessoais são
            armazenados por tempo indeterminado para fins de controle da
            empresa, cumprimento de obrigações regulatórias e legais, bem como
            para resguardar direitos da <b>VIUZZ</b> e de terceiros que se
            relacionem com ela em caso de disputas de qualquer natureza.
          </p>
          <h1>6. COMO A VIUZZ PROTEGE SEUS DADOS PESSOAIS?</h1>
          <p>
            <b>
              Nossa responsabilidade é cuidar dos seus Dados Pessoais e
              utilizá-los somente para as finalidades descritas nessa Política.
            </b>
            Para garantir a sua privacidade e a proteção dos seus Dados
            Pessoais, adotamos recursos tecnológicos avançados para garantir a
            segurança de todos os dados tratados pela Empresa. Entre as medidas
            de segurança aplicadas estão a implementação de controles de acesso
            a sistemas e ambientes de tratamento de dados, técnicas de
            criptografia e a instalação de barreiras contra o acesso indevido às
            bases de dados (incluindo firewalls), entre outros controles de
            segurança da informação, tais como o uso de anti-vírus no estado da
            arte, e controle interno para o acesso de dados pessoais
            específicos. Por fim, ressaltamos que sempre que possível dados
            pessoais tratados pela companhia serão anonimizados para fins de
            preservar a privacidade do titular dos dados pessoais, por exemplo
            no caso de pesquisas de clientes feita pela Empresa.
          </p>
          <p>
            <b>
              Nós nos esforçamos para proteger a privacidade de seus Dados
              Pessoais, mas infelizmente não podemos garantir total segurança.
            </b>
            Entradas e usos não autorizados de terceiros com informações suas,
            falhas de hardware ou software que não estejam sob controle da
            Empresa e outros fatores externos podem comprometer a segurança dos
            seus Dados Pessoais. Por isso, sua atuação é fundamental para a
            manutenção de um ambiente seguro para todos. Caso você identifique
            ou tome conhecimento de qualquer fator que comprometa a segurança
            dos seus dados na sua relação com a Empresa, por favor entre em
            contato conosco por meio das informações de contato indicadas
            abaixo.
          </p>
          <h1>8. COMO FALAR SOBRE DADOS PESSOAIS COM A VIUZZ?</h1>
          <p>
            Se você acredita que seus Dados Pessoais foram tratados de maneira
            incompatível com esta Política ou com as suas escolhas enquanto
            Titular dos seus Dados Pessoais, ou, ainda, se você tiver dúvidas,
            comentários ou sugestões relacionadas a esta Política e à forma como
            tratamos seus Dados Pessoais, entre em contato conosco. Nós temos um
            Encarregado que está à disposição nos seguintes endereços de
            contato:
          </p>
          <div className={"catchy"}>
            <p>
              <b>Encarregado:</b> Lara Reis
            </p>
            <p>
              <b>Endereço para correspondências:</b> Av. Ávaro Otacilio, 3731,
              sala 503, Bloco A (Itália), Jatiúca, CEP: 57.036-850, Maceió-AL.{" "}
            </p>
            <p>
              <b>E-mail para contato:</b> Contato@viuzz.com.br
            </p>
          </div>
          <h1>9. MUDANÇAS NA POLÍTICA DE PRIVACIDADE</h1>
          <p>
            Como estamos sempre buscando melhorar os nossos serviços e a forma
            como operamos, esta Política de Privacidade pode passar por
            atualizações para refletir as melhorias realizadas. Desta forma,
            recomendamos a visita periódica desta página para que você tenha
            conhecimento sobre as modificações efetivadas
          </p>
          <h1 className="maintitle">
            <b>DATA DA ÚLTIMA ATUALIZAÇÃO:</b> 14 de Março de 2022.
          </h1>
        </S.Grid>
      </S.Grid>
    </>
  );
};

export default PrivacyPolicy;
