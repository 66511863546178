import React from "react";
import { Grid } from "@mui/material";
import * as S from './style'
import { useFilePicker } from 'react-sage';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import { imgPreview } from '../../../../utils/imgPreview';
import 'react-image-crop/dist/ReactCrop.css'
import { changeAvatar } from "../../../../utils/requests";
import showToast from '../../../../components/CustomComponents/ToastContainer/toast';

type props = {
	avatar?: string | undefined
	closeFunc: Function
};

const ChangeAvatarModal: React.FC<props> = ({ closeFunc }) => {

	const [postAvatar, setPostAvatar] = React.useState<File>()
	const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(undefined);

	const avatarInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

	React.useEffect(() => {
		const getDataUrls = async () => {
			if (avatarInput.files && avatarInput.files[0]) {
				setPostAvatar(avatarInput.files[0]);
				const reader = new FileReader();
				reader.onload = () => {
					setPreviewUrl(reader.result as string);
				};
				reader.readAsDataURL(avatarInput.files[0]);
			};
		};
		getDataUrls();
	}, [avatarInput.files]);

	const deleteAvatar = () => {
		setPostAvatar(undefined);
		setPreviewUrl(undefined);
		setCrop({
			unit: '%', // Can be 'px' or '%'
			x: 25,
			y: 25,
			width: 50,
			height: 50
		});
		setCompletedCrop(undefined);
		setCroppedAvatar(undefined);
	}

	const [croppedAvatar, setCroppedAvatar] = React.useState<File>();

	const previewCanvasRef = React.useRef<HTMLCanvasElement>(null)
	const imgRef = React.useRef<HTMLImageElement>(null)
	const [crop, setCrop] = React.useState<Crop>({
		unit: '%', // Can be 'px' or '%'
		x: 25,
		y: 25,
		width: 60,
		height: 50
	})
	const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>()

	React.useEffect(() => {
		if (
			completedCrop?.width &&
			completedCrop?.height &&
			imgRef.current &&
			previewCanvasRef.current
		) {
			imgPreview(imgRef.current, completedCrop).then(res => {
				let file = new File([res], "avatar.jpg", {
					type: "image/jpeg"
				})
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					/* console.log(reader.result); */
				};
				setCroppedAvatar(file);
			});
		};
	}, [completedCrop]);

	const sendAvatar = () => {
		if(!completedCrop && postAvatar) {
				changeAvatar(postAvatar);
        showToast("Foto de perfil atualizada com sucesso!", "success");
        setTimeout(() => {
          window.location.reload()
        }, 4000);
		};
		if(croppedAvatar) {
			changeAvatar(croppedAvatar);
			showToast("Foto de perfil atualizada com sucesso!", "success");
			setTimeout(() => {
				window.location.reload()
			}, 4000);
		};
	};

	return (
		<S.ClosersModal container>
			<S.ModalContainer container>
				<Grid container justifyContent="right" onClick={() => closeFunc(false)}>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="xButton">
						<path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white" />
					</svg>
				</Grid>
				{postAvatar &&
					<Grid container className="send">
						<Grid container className="SendGrid" flexDirection="row" justifyContent="center">
							<S.CropGrid container>
								<ReactCrop circularCrop aspect={1} minHeight={300} minWidth={300} crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)}>
									<img src={previewUrl} ref={imgRef} alt="preview" />
								</ReactCrop>
							</S.CropGrid>
							<Grid container className="buttonGrid" item flexDirection="row" spacing={2} justifyContent="center">
								<Grid container item lg={5} md={5}>
									<S.IconFab size="small" onClick={() => deleteAvatar()}>
										<DeleteIcon />
									</S.IconFab>
									<p>Deletar imagem</p>
								</Grid>
								<Grid container item lg={5} md={5}>
									<S.IconFab size="small" onClick={() => sendAvatar()}>
										<DoneIcon />
									</S.IconFab>
									<p>Confirmar imagem</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				}
				{!postAvatar &&
					<Grid container className="send" onClick={avatarInput.onClick}>
						<svg width="119" height="81" viewBox="0 0 119 81" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M95.9438 30.7813C92.5721 13.6751 77.5483 0.833008 59.5 0.833008C45.1704 0.833008 32.725 8.96467 26.5271 20.8647C11.6025 22.4513 0 35.0951 0 50.4163C0 66.8284 13.3379 80.1663 29.75 80.1663H94.2083C107.893 80.1663 119 69.0597 119 55.3747C119 42.2847 108.835 31.6738 95.9438 30.7813ZM69.4167 45.458V65.2913H49.5833V45.458H34.7083L59.5 20.6663L84.2917 45.458H69.4167Z" fill="#323232" />
						</svg>
						<p>
							Enviar imagem
						</p>
						<p>
							Tipos de arquivos compatíveis:  .jpg, .jpeg, .png
						</p>
						<avatarInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
					</Grid>
				}
				<S.CanvasGrid>
				{!!completedCrop && (
									<canvas
										ref={previewCanvasRef}
										style={{
											border: '1px solid black',
											objectFit: 'contain',
											width: completedCrop.width,
											height: completedCrop.height,
										}}
									/>
								)}
				</S.CanvasGrid>
			</S.ModalContainer>
		</S.ClosersModal>
	)
};

export default ChangeAvatarModal