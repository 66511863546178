import styled from "styled-components";
import FormLabel from "@mui/material/FormLabel";
import Input from "../../../components/Input/input";
import { Grid as MuiGrid } from "@mui/material";

export const Grid = styled(MuiGrid)`
  animation: fadeIn 0.3s;
  .saveButton{
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    align-items: center;

    color: #ffffff;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 10px;
    border-style: none;
    padding: 5px 30px;
    margin: 10px 0px;
    cursor: pointer;
    :disabled{
      background: linear-gradient(90deg, #c4c4c4 0%, #c4c4c4 100%), #c4c4c4;
    }
  }
`;

export const TextField = styled(Input)`
  .MuiInputBase-input {
    border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important;
    padding: 15px 15px;
    transition: 250ms;
    border: 1px solid rgb(46, 46, 56) !important;
    /* height:25px ; */
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
  }
  [aria-invalid="true"] {
    border-style: solid !important;
    border-width: 1px !important;
  }
  /* @media (min-width: 320px) {
    width: 320px !important;
  }
  @media (min-width: 420px) {
    width: 420px !important;
  } */

  [name="password"] {
    margin-top: 0px;
  }
`;

export const Formlabel = styled(FormLabel)`
  color: white !important;
`;
export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 0px;
  width: 100%;
  text-align: left;
`;
