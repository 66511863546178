import React from "react";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
export const CollectibleListSkeleton: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <SkeletonGrid item xs={6} sm={4} lg={3} >
        <Skeleton variant="rectangular" height={'100%'} width={'100%'} style={{'backgroundColor': '#121212'}}/>
      </SkeletonGrid>
      <SkeletonGrid item xs={6} sm={4} lg={3} >
        <Skeleton variant="rectangular" height={'100%'} width={'100%'} style={{'backgroundColor': '#121212'}}/>
      </SkeletonGrid>
      <SkeletonGrid item xs={6} sm={4} lg={3} >
        <Skeleton variant="rectangular" height={'100%'} width={'100%'} style={{'backgroundColor': '#121212'}}/>
      </SkeletonGrid>
      <SkeletonGrid item xs={6} sm={4} lg={3} >
        <Skeleton variant="rectangular" height={'100%'} width={'100%'} style={{'backgroundColor': '#121212'}}/>
      </SkeletonGrid>
    </Grid>
  );
};

export default CollectibleListSkeleton;

export const SkeletonGrid = styled(Grid)`
    width: 100% !important;
    aspect-ratio: 19/22 !important;
    span{
        border-radius: 10px;
    }
`
