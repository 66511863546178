import styled from "styled-components";
import { Avatar as MUIAvatar } from "@mui/material";
import { Menu as MUIMenu, MenuItem as MUIMenuItem } from "@mui/material";

export const Avatar = styled(MUIAvatar)`
  background-color: rgb(38, 38, 38) !important;
  border-width:2px;
  border-color: rgb(251,132,4);
  border-style: solid;
  :hover{
    cursor: pointer;
  }
`;

export const Menu = styled(MUIMenu)`
  ul, .MuiPaper-root {
    background-color: rgb(38, 38, 38) !important;
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  color: white !important;
  :hover{
      color: rgb(251,132,4) !important;
    }
`;
