import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { NFTListProvider } from './context/NFTListContext';

if(!(process.env.NODE_ENV === 'production')) console.log(process.env);
ReactDOM.render(
  <React.StrictMode>
    <NFTListProvider>
      <App />
    </NFTListProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
