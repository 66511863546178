import { CartItem } from "../types/CartItemType";
import { UnencryptedCardType } from "../types/UnencryptedCard";
import PagSeguro from './pagseguro.min';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const formatAsBRLCurrency = (value: number) => {
  let valueInReais = value / 100;
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valueInReais);
};

export const validateCpfOrCnpj = (strCPF: string) => {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

//validate cellphone number (99)99999-9999
export const validateCellphoneNumber = (cellphone: string) => {
  let cellphoneNumber = cellphone.replace(/\D/g, "");
  return cellphoneNumber.length === 11 ? true : false;
}

//mask cellphone (99)99999-9999
export const maskCellphone = (curvalue: string) => {
  let cellphoneNumber = curvalue.replace(/\D/g, "");
  if (curvalue.length === 9) return `(${cellphoneNumber.substring(0, 2)})${cellphoneNumber.substring(2, 7)}`
  if (curvalue.length === 13) return `(${cellphoneNumber.substring(0, 2)})${cellphoneNumber.substring(2, 7)}-${cellphoneNumber.substring(7, 11)}`
  return `(${cellphoneNumber.substring(0, 2)})${cellphoneNumber.substring(2, 11)}`
}

//mask expiredate MM/YYYY
export const maskExpiredate = (curvalue: string) => {
  let expiredate = curvalue.replace(/\D/g, "");
  if (curvalue.length === 2) return `${expiredate.substring(0, 2)}`
  if (curvalue.length === 3) return `${expiredate.substring(0, 2)}/${expiredate.substring(2, 3)}`
  if (curvalue.length < 2) return curvalue.replace("/", "")
  return `${expiredate.substring(0, 2)}/${expiredate.substring(2, 7)}`
}



//validates credit card cvv returning true or false
export const validateCvv = (cvv: string) => {
  let cvvNumber = cvv.replace(/\D/g, "");
  if (cvvNumber.length === 3) {
    return cvvNumber.match(/^(\d{3})/) ? true : false;
  } else {
    return false;
  }
};

//verify if card due date is valid using the regex [0-1][0-9]\/[2][0][2-9][2-9]

export const validateExpireDate = (dueDate: string) => {
  try {
    const [month, year] = dueDate.split("/");
    if (parseInt(month) < 1) return false;
    if (parseInt(month) > 12) return false;
    if (parseInt(year) < 22) return false;
    if (parseInt(year) > 99) return false;
  } catch (error) {
    return false;
  }
  return true;
}

//verificar se o cep está no formato ddddd-ddd
export const validateZipCode = (zipCode: string) => {
  let zip = zipCode.replace(/\D/g, "");
  if (zip.length === 8) {
    return zip.match(/^(\d{8})/) ? true : false;
  } else {
    return false;
  }
};

//request to api to get the address by zipcode
export const getAddressByZipCode = async (zipCode: string) => {
  const response = await fetch(
    `https://viacep.com.br/ws/${zipCode}/json/`
  );
  return await response.json();
}

//validate neighbourhood
export const validateNeighbourhood = (neighbourhood: string) => {
  return neighbourhood?.length >= 5 && neighbourhood?.length <= 40 ? true : false;
}

//validateStreet
export const validateStreet = (street: string) => {
  return street?.length >= 5 && street?.length <= 50 ? true : false;
}

//validate number, must have all numeric characters maxlength 5
export const validateNumber = (number: string) => {
  return parseInt(number?.replace(/\D/g, "")) ? true : false;
};

//calculate total cart price
export const calculateTotalPrice = (cart: CartItem[]) => {
  return cart.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);
}

//with cardnumber, verifies what is the brand and return it
export const getCardBrand = (cardnumber: string) => {
  const cardRegex = new Map<string, RegExp>();
  cardRegex.set('visa', /^4[0-9]{15}$/);
  cardRegex.set(
    'mastercard',
    /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
  );
  cardRegex.set('amex', /^3[47][0-9]{13}$/);
  cardRegex.set('aura', /^((?!504175))^((?!5067))(^50[0-9])/);
  cardRegex.set('discover', /^6(?:011|5[0-9]{2})[0-9]{12}/);
  cardRegex.set(
    'elo',
    /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))/,
  );
  cardRegex.set('hipercard', /^606282|^3841(?:[0|4|6]{1})0/);
  let result: string | undefined = undefined;
  cardRegex.forEach((regex: RegExp, cardbrand: string) => {
    if (cardnumber.replaceAll(' ', '').match(regex)) {
      result = cardbrand;
    }
  });
  return result;
}

export const encryptCard = (creditCardData: UnencryptedCardType):string => {
  type PagSeguroType = {
    encryptCard: (cardData:UnencryptedCardType) => {
      encryptedCard:string;
      errors: [];
      hasErrors:boolean;
    };
  }
  const PS: PagSeguroType = PagSeguro as PagSeguroType;
  return PS.encryptCard({
    ...creditCardData,
    publicKey: process.env.REACT_APP_PAGSEGURO_KEY,
  }).encryptedCard;
}

//function that receives two numeric values total and installments and calculates the total with interest
export const calculateTotalWithInterest = (totalvalue: number, installments: number) => {
  let interest: number = 0;
  if (installments === 1) return totalvalue;
  if (installments === 2) interest = 2.30;
  if (installments === 3) interest = 3.40;
  if (installments === 4) interest = 4.50;
  if (installments === 5) interest = 5.60;
  if (installments === 6) interest = 6.70;
  if (installments === 7) interest = 7.80;
  if (installments === 8) interest = 8.90;
  if (installments === 9) interest = 9.10;
  if (installments === 10) interest = 10.11;
  return ((interest / 100) * totalvalue) + totalvalue;
}


//profile validations
const validName = /^[\p{L}\p{M}]{3,50}$/;

export const validateName = (name: string) => {
  return validName.test(name);
}
//use regex to validate an email string
export const validateEmail = (email: string) => {
  //eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}
//function verifies if date string in format dd/mm/yyyy is valid using parser
export const validateDate = (date: string) => {
  try {
    const [day, month, year] = date.split('/');
    if (day.length !== 2 || month.length !== 2 || year.length !== 4) return false;
    const parsedDate: Date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    parsedDate.toISOString();
  } catch {
    return false;
  }
  return true;
}

//mask date format dd/mm/aaaa
export const maskDate = (value: string) => {
  let v = value.replace(/\D/g, '').slice(0, 10);
  if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
  }
  else if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`;
  }
  return v
}
//convert 1996-03-06T00:00:00.000Z to 03/06/1996
export const convertDate = (date: string | undefined) => {
  if (!date) return undefined;
  return new Date(date).toLocaleDateString('en-GB');
}

// Regular expression to match Ethereum wallet addresses
const cryptoWallet = /^0x[a-fA-F0-9]{40}$/;

// Function to validate a wallet address
export const validateWalletAddress = (walletAddress: string): boolean => {
  return cryptoWallet.test(walletAddress);
}

const validUsername = /^[a-zA-Z0-9_]{5,20}$/;

export const validateUsername = (username: string) => {
  return validUsername.test(username);
}