import { CartItem } from "../types/CartItemType";
import { CollectibleType } from "../types/CollectibleType";
import { InfluencerType } from "../types/InfluencerType";
/* import { MintType } from "../types/MintType"; */
import { CreatePostType } from "../types/CreatePostType";
import { OrderType } from "../types/OrderType";
import { OwnerArrayType } from "../types/OwnerTypes";
import {
  ChangePasswordResponseType, ClaimRewardResponse, CollectiblesResponse,
  CreditCardResponse,
  CreditPaymentResponse,
  FreeNftRequestType,
  GetUserRewardsResponse,
  LoginResponse,
  PixNftType,
  PixRequestType,
  RefreshResponse,
  UserCardStatusType, UserCollectiblesResponse, UserInfoResponse,
  ViuzzBillingResponseType,
  ViuzzCardsResponseType,
} from "../types/RequestTypes";
import { SecurePaymentType } from "../types/SecurePaymentType";
import { UserType } from "../types/UserType";
// import stripeaaa
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
if (!REACT_APP_API_URL) console.error("REACT_APP_API_URL is not set");


export const login = async (email: string, password: string, recaptchaToken: string) => {
  const response = await fetch(REACT_APP_API_URL + `/meta/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      recaptcha: recaptchaToken,
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  const data: LoginResponse = await response.json();
  if (data?.accessToken) localStorage.setItem("token", data?.accessToken);
  if (data?.refreshToken) localStorage.setItem("refreshToken", data?.refreshToken);
  return data;
};

//create async function to post https://api.viuzz.com.br/meta/users name, email, password
export const register = async (
  name: string,
  email: string,
  password: string,
  username: string
) => {
  const response = await fetch(REACT_APP_API_URL + `/meta/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      password,
      username,
    }),
  });
  const data = await response.json();
  return data;
};

export const getUserData = async (token: string, id: string) => {
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/meta/users?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const response: UserType = await data.json();
  return response;
};
// {
//   "email": "john@gmail.com",
//   "password": "123456",
//   "name": "John Doe",
//   "birthDate": "2022-04-10T16:33:27.809Z",
//   "phone": "11999999999",
//   "avatar": "https://i.picsum.photos/id/1/200/200.jpg",
//   "cpf": "12345678901"
// }
//change using patch on /meta/users
export const setUserData = async (token: string, userData: UserType) => {
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/meta/users`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  });
  const response = await data.json();
  return response as UserType;
};

//change password passing changePassword token and  new password using fetch on
// users/changepassword
export const changePassword = async (token: string, password: string) => {
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/meta/users/changepassword`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify({
      password,
      token,
    }),
  });
  const response = await data.json();
  return response as ChangePasswordResponseType;
}
//change password passing bearer token , old password and  new password using fetch on users/changepassword
//users/forgotpassword
export const changePasswordWithOldPassword = async (token: string, oldPass: string, password: string) => {
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/meta/users/changepassword`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
      Authorization: ` Bearer ${token}`,
    },
    body: JSON.stringify({
      oldPass,
      password,
    }),
  });
  const response = await data.json();
  return response as ChangePasswordResponseType;
}

//POST
//body{email:string}
export const forgotPassword = async (email: string, recaptchaToken: string) => {
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/meta/forgotpass`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
      "recaptcha": recaptchaToken,
    },
    body: JSON.stringify({
      email,
    }),
  });
  const response = await data.json();
  return response;
}
///payments/credit
export const securePayment = async (token: string, paymentData: SecurePaymentType, recaptchaToken: string) => {
  /*   console.log(recaptchaToken) */
  let refreshToken = localStorage.getItem("refreshToken")
  let paymentUrl = `${REACT_APP_API_URL}/payments/credit`;
  const response = await fetch(paymentUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
      Authorization: `Bearer ${token}`,
      recaptcha: recaptchaToken,
    },
    body: JSON.stringify(paymentData),
  });
  const data: CreditPaymentResponse = await response.json();

  // let message = data[0]?.collectionName ? "Compra realizada com sucesso!" : "Não foi possível realizar sua compra.";
  // let error = data[0]?.collectionName ? 'false' : 'true';
  // let resobject: CollectiblesResponse = {
  //   nftCollections: data,
  //   message,
  //   error,
  // };
  return data as CreditPaymentResponse;
}

export const getCollectiblesRequest = async () => {
  const response = await fetch(REACT_APP_API_URL + `/meta/nft`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  let resobject: CollectiblesResponse = {
    nftCollections: data.nftCollections,
    nfts: data.nfts,
    message: data.message,
    error: data.error,
  };
  return resobject;
};

export const getCollectible = async (title: string) => {
  const response = await fetch(
    REACT_APP_API_URL + `/meta/nft?url_title=${title}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  let resobject: CollectiblesResponse = {
    nftCollections: data.nftCollections,
    nfts: data.nfts,
  };
  return resobject;
};
//DEPRECATED - EBANX PAYMENTS
// export const getCreditCardToken = async (
//   name: string,
//   cardnumber: string,
//   cvv: string,
//   expiryDateString: string
// ) => {
//   const response = await fetch(`${REACT_APP_EBANX_API_URL}/ws/token`, {
//     method: "POST",
//     body: JSON.stringify({
//       integration_key: REACT_APP_EBANX_ENCRYPTION_KEY,
//       country: "br",
//       payment_type_code: getCardBrand(cardnumber),
//       card: {
//         card_number: cardnumber,
//         card_name: name,
//         card_due_date: expiryDateString,
//         card_cvv: cvv,
//       },
//     }),
//   });
//   const data = await response.json();
//   return data as CreditCardTokenResponse;
// };

export const getUserInfo = async () => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  let userData = {} as UserType;
  let responsedata: UserInfoResponse = {
    user: undefined,
    message: undefined,
    error: undefined,
  };

  if (user) userData = JSON.parse(user) as UserType;
  if (userData?.email) {
    responsedata.user = userData;
  } else {
    if (token && refreshToken) {
      const response = await fetch(REACT_APP_API_URL + `/meta/users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Refresh": refreshToken,
        },
      });
      const data = await response.json();

      if (data?.email) {
        responsedata.user = data;
        localStorage.setItem("user", JSON.stringify(data));
      } else {
        responsedata.message = data?.message ?? "FE - Erro não especificado";
        responsedata.error = true;
      }
    }
  }
  return responsedata;
};
//get card info from /meta/cards
export const getCreditCardStatus = async () => {
  const response = await fetch(REACT_APP_API_URL + `/meta/cards`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data as UserCardStatusType;
};

//save cardtoken and info on viuzz
export const saveCreditCard = async (
  brand: string,
  providerCardToken: string,
  holderName: string,
  holderCpf: string,
  expirationDate: string,
  maskedNumber: string
) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/cards`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify({
      brand,
      providerCardToken,
      holderName,
      holderCpf,
      expirationDate,
      maskedNumber,
    }),
  });
  let data = await res.json();
  return data as ViuzzCardsResponseType;
};
//get payment methods from /meta/cards
export const getCreditCard = async () => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/cards`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data as CreditCardResponse;
};
//save billing address on viuzz
//example of data for this request
// {
//   "street": "Rua dos bobos",
//   "number": "123",
//   "neighborhood": "Centro",
//   "city": "São Paulo",
//   "state": "SP",
//   "zipcode": "01234567",
//   "country": "BR"
// }
export const saveBillingAddress = async (
  street: string,
  number: string,
  neighborhood: string,
  city: string,
  state: string,
  zipcode: string
) => {
  //add dash after fifth char on string zipcode
  let formatedZipcode = zipcode.substring(0, 5) + "-" + zipcode.substring(5, 9);
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/billing-address`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify({
      street,
      number,
      neighborhood,
      city,
      state,
      zipCode: formatedZipcode,
    }),
  });
  let data = await res.json();
  return data as ViuzzBillingResponseType;
};

export const getBillingAddress = async () => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/billing-address`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data as ViuzzBillingResponseType;
};

//buyCollectibles
// {
//   "nftCollectionNames": {
//     "Bianca": 2,
//     "Crystal": 3,
//     "Rylai": 2
//   },
//   "installments": 10,
//   "payment_method": "BANK_SLIP"
// }
// /meta/payment/nft/buy

export const buyCollectibles = async (
  token: string,
  nftCollections: CartItem[],
  installments: number,
  payment_method: string,
) => {
  let collectionNames: Record<string, number> = {};
  nftCollections.forEach((item) => {
    collectionNames[`${item.url_title}`] = item.quantity;
  });
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/meta/nft/buy`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify({
      nftCollectionNames: collectionNames,
      installments,
      payment_method,
    }),
  });
  let data = await res.json();
  return data;
  //  as BuyCollectiblesResponse;
};

//payments/create-payment-intent
export const createPaymentIntent = async (token: string, price: number) => {
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/create-payment-intent`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify({
      value: price,
    }),
  });
  let data = await res.json();
  return data;
};

// get user collectibles /meta/nft/user
export const getUserCollectibles = async (id?: string) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/nft/user?id=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data as UserCollectiblesResponse;
};

export const createOrder = async (token: string, shoppingCart: CartItem[]) => {
  let collectibleList: { [k: string]: number } = {};
  /*   console.log(shoppingCart); */
  shoppingCart.forEach((item) => {
    collectibleList[`${item.url_title}`] = item.quantity;
  });

  let refreshToken = localStorage.getItem("refreshToken")

  const body = {
    nft_url_titles: collectibleList,
  };
  const res = await fetch(REACT_APP_API_URL + "/meta/orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify(body),
  });
  let data = await res.json();
  return data as OrderType;
};

export const executeOrder = async (token: string, orderId: string) => {
  let refreshToken = localStorage.getItem("refreshToken")
  const res = await fetch(REACT_APP_API_URL + `/meta/orders/${orderId}/execute`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  // return data as ExecuteOrderResponse;
  return data as CollectibleType[];
};

// get NFT owner data /meta/nft/owners
export const getOwnerData = async (collectionName: string) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/nft/owners?url_title=[${collectionName}]`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data as OwnerArrayType;
};

export const getAnyUserCollectibles = async (id: string) => {
  let refreshToken = localStorage.getItem("refreshToken")
  let token = localStorage.getItem("token");
  let res = await fetch(REACT_APP_API_URL + `/meta/nft/user?id=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data;
};

export const getAnyUserData = async (username: string) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/meta/users?username=${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Refresh": `${refreshToken}`,
    },
  });
  const response: UserType = await data.json();
  return response;
};

export const PixRequest = async (
  valor: { original: string },
  solicitacaopagador: string,
  recaptchaToken: string,
  cpf: string
) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/payments/cob`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      recaptcha: recaptchaToken,
      "Refresh": `${refreshToken}`,
    },
    body: JSON.stringify({
      valor,
      solicitacaopagador,
      cpf,
    }),
  });
  let data = await res.json();
  return data as PixRequestType;
};

export const GetPixNFT = async (txid: string, id: string) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  const data = await fetch(REACT_APP_API_URL + `/payments/cob/${txid}?order_id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Refresh": `${refreshToken}`,
    },
  });
  const response = await data.json();
  return response as PixNftType;
};

export const getAuthenticity = async (nftURI: string | undefined, nftTokenID: string | undefined) => {
  const data = await fetch(`https://viuzznftbucket.s3.sa-east-1.amazonaws.com/${nftURI}${nftTokenID}.json`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await data.json();
  return response;
};

export const getInfluencerData = async (username: string) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/users/influencer?username=${username}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data as InfluencerType;
};

export const executeFreeNFT = async (
  order_id: string
) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/payments/cob/free?order_id=${order_id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data as FreeNftRequestType;
};

export const changeAvatar = async (avatar: File) => {
  const formData = new FormData();
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  if (avatar) formData.append('avatar', avatar);
  const data = await fetch(REACT_APP_API_URL + `/tv/users/avatar`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Refresh": `${refreshToken}`,
    },
    body: formData,
  });
  /* const response = await data.json(); */
  return data;
};

//in development

/* export const mintNFT = async (
  collections: MintType
) => {
  let token = localStorage.getItem("token");
  let res = await fetch(REACT_APP_API_URL + `meta/nft/addressMinting`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      collections
    }),
  });
  let data = await res.json();
  return data;
};

export const testMint = async (shoppingCart: CartItem[]) => {
  let collectibleList: { [k: string]: number } = {};
  shoppingCart.forEach((item) => {
    collectibleList[`${item.collectionName}`] = item.quantity;
  });
  let token = localStorage.getItem("token");
  const body = {
    collections: collectibleList,
  };
  const res = await fetch(REACT_APP_API_URL + "/meta/nft/addressMinting", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  let data = await res.json();
  console.log(data);
  return data;
}; */

export const getInfluencerPosts = async (page: number, size: number, author: string, visibility: string, collectionUrlTitle?: string) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/posts/all?page=${page}&size=${size}&author=${author}&visibility=${visibility}${collectionUrlTitle ? `&collectionUrlTitle=${collectionUrlTitle}` : ""}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  let data = await res.json();
  return data;
};

export const createClosersPost = async (post: CreatePostType) => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  const formData = new FormData();
  if (post.media) formData.append("media", post.media);
  formData.append("body", post.body);
  if (post.author) formData.append("author", post.author);
  if (post.user_author) formData.append("user_author", post.user_author);
  formData.append("visibility", post.visibility);
  if (post.collectionUrlTitle) formData.append("collectionUrlTitle", post.collectionUrlTitle);

  const response = await fetch(REACT_APP_API_URL + `/meta/posts`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Refresh": `${refreshToken}`,
    },
    body: formData,
  })
  const data = await response.json();
  return data;
};

export const refreshAccessToken = async () => {
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken")
  let res = await fetch(REACT_APP_API_URL + `/meta/refreshToken`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Refresh": `${refreshToken}`,
    },
  });
  try {
    let data = await res.json();
    return data as RefreshResponse;
  }
  catch {
    //Não retornar erro por questões de segurança
  }
};

export const getProjectsRequest = async () => {
  const response = await fetch(REACT_APP_API_URL + `/meta/projects/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const getProject = async (title: string) => {
  const response = await fetch(REACT_APP_API_URL + `/meta/projects?url_title=${title}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const getCollectionsRequest = async () => {
  const response = await fetch(REACT_APP_API_URL + `/meta/collections/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const getCollection = async (title: string) => {
  const response = await fetch(REACT_APP_API_URL + `/meta/collections?url_title=${title}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const getUserRewards = async (project: string) => {
  let token = localStorage.getItem("token");
  let res = await fetch(REACT_APP_API_URL + `/meta/projects/rewards?project=${project}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  return data as GetUserRewardsResponse;
};

export const ClaimProjectReward = async (project: string, reward: string) => {
  let token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/meta/projects/claimReward`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      project,
      reward,
    }),
  });
  const data = await response.json();
  return data as ClaimRewardResponse;
};

export const DeletePost = async (id: string) => {
  let token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/meta/posts/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const getTrending = async () => {
  let res = await fetch(REACT_APP_API_URL + `/meta/collections/hot`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  return data;
};

export const getOnSale = async (url_title: string) => {
  let res = await fetch(REACT_APP_API_URL + `/meta/nft/onsale?project_url_title=${url_title}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  return data;
};