import React from "react";
import * as S from "./style";
import { ListItem } from "@mui/material";

const RenderList = () => {
  const [questions, setQuestions] = React.useState([
    {
      id: "0",
      question: "Porquê eu deveria me importar com NFTs?",
      answer:"Como uma alternativa extremamente rentável no mercado de investimentos, os tokens não-fungíveis (NFTs) abrem portas para um mundo de possibilidades para ganhar dinheiro com ativos digitais.\nCriptografado e com certificado único, tornando-o valioso e limitado para colecionadores e investidores de obras digitais, estes ativos carregam possibilidades infinitas e servem como uma ponte entre os mundos digital e real. Daqui a algum tempo você vai se arrepender de não ter começado a colecionar NFTs hoje.",
      open: false,
    },
    {
      id: "1",
      question: "Quando e por quanto eu posso mintar?",
      answer:"Quando decidir transformar sua arte digital individual e colecionável em um NFT exclusivo, criando um identificador singular registrado e rastreado por uma rede global e segura chamada blockchain. Diferentes blockchains têm processos diferentes e, consequentemente, taxas diferentes. O tamanho dos dados, a qualidade do projeto, a velocidade da transação, o tempo de cunhagem, bem como as taxas de gás (taxas de transação) podem afetar o custo.",
      open: false,
    },
    {
      id: "2",
      question: "Como criar minha própria coleção de NFT??",
      answer: "Para montar o seu NFT é necessário que ele possua um nível desejável de suporte e produção especializada. Para isso, conte com a Viuzz. Basta enviar sua ideia para contato@viuzz.com.br e retornaremos com as informações direcionadas." ,
      open: false,
    },
    {
      id: "3",
      question: "Posso ter mais de um NFT viuzz?",
      answer:"Claro! Cada colecionador de ativo digitais pode adquirir quantos NFTs Viuzz desejar, com foco em sempre aumentar a coleção presente em sua carteira digital.",
      open: false,
    },
    {
      id: "4",
      question: "Qual a utilidade de comprar um NFT?",
      answer:"Por serem facilmente negociáveis e transferidos de uma carteira digital a outra, os NFTs são ótimas opções de moedas de investimentos. Únicos e limitados, esses ativos digitais acabam sendo valiosos e podem ser negociados por valores ainda mais altos do que foi pago.Na Viuzz, os NFTs ainda detêm de benefícios como sócio participante em sociedades limitadas, comunidades exclusivas e apoio ao crescimento do seu criador de conteúdo preferido.",
      open: false,
    },
    {
      id: "5",
      question: "O que é o metaverso Viuzz?",
      answer:"É um universo virtual 3D, onde possibilita a democratização na criação e comercialização de avatares digitais representativos e NFTs. Apresenta-se como um espaço compartilhado, imersivo via realidade aumentada ou virtual e colaborativa, possível de reproduzir todos os aspectos da vida, seja comunicação, diversão e negócios no mundo virtual.",
      open: false,
    },
  ]);
  const toggleOpen = (id: String) => {
    setQuestions((state) => {
      return state.map((item) => {
        return item.id === id ? { ...item, open: !item.open } : item;
      });
    });
  };
  return (
    <S.List>
      <S.Grid container>
        <S.Divider />
        {questions.map((q) => {
          return (
            <S.Grid key={q.id} item container>
              <ListItem key={q.id} >
                <S.Grid xs={12} item container>
                  <S.Grid item xs={8}>
                    {q.question}
                  </S.Grid>
                  <S.Grid item container xs={4} justifyContent={"flex-end"}>
                    {q.open ? <S.Less onClick={() => toggleOpen(q.id)} /> : <S.More onClick={() => toggleOpen(q.id)} />}
                  </S.Grid>
                </S.Grid>
              </ListItem>
              <S.Grid item container>
                {q.open ? <S.ListItemText>{q.answer}</S.ListItemText> : <></>}
              </S.Grid>
              <S.Divider />
            </S.Grid>
          );
        })}
      </S.Grid>
    </S.List>
  );
};

const Faq: React.FC = () => {
  return (
    <S.MainContainer container>
      <S.Grid item xs={12} paddingTop="20px" paddingBottom="20px">
        <S.WhiteStrap />
        <S.OrangeStrap />
      </S.Grid>
      <S.FaqTitle item container xs={12}>
        <span>FAQ</span>
      </S.FaqTitle>
      <RenderList />
    </S.MainContainer>
  );
};

export default Faq;
