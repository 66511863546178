import styled, { css } from 'styled-components';
import { InputBase } from '@mui/material';

const Input = styled(InputBase)`
  ${({ theme, error }) => css`
    && {
      color: white;
      font-size: 1.3rem;
      width: 100%;

      > .MuiInputBase-input {
        border: 3px solid rgb(253, 112, 11);
        border-radius: 25px;
        padding: 15px 20px;
        transition: 250ms;
        ${error && css`
          border-color: red;
        `}
      }

      &.Mui-disabled > .MuiInputBase-input,
      > input:read-only {
        border-color: grey;
      }

      &.Mui-disabled {
        color: grey;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active  {
        transition: background-color 9999s;
        -webkit-text-fill-color: #fff !important;
      }

      & input::placeholder {
        opacity: 0.8;
      }

      & input[type='date'] {
        position: relative;
        z-index: 1;

        &:read-only {
          color: grey;
        }

        &:after {
          content: '📅';
          font-size: 2rem;
          height: 50px;
          position: absolute;
          right: 0;
          top: 17px;
          width: 50px;
          z-index: -1;
        }

        &::-webkit-calendar-picker-indicator {
          opacity: 0;
        }
      }
    }
  `}
`;

export default Input;
