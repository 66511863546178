import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./style";

import mobileBackground from "../../../assets/mobile-bg.png";
import desktopBackground from "../../../assets/welcome-bg.png";


type Props = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};
//eslint-disable-next-line
const Welcome: React.FC<Props> = ({auth, setAuth}) => {
  const navigate = useNavigate();
  return (
    <S.MainContainer container>
      <Grid item>
        <img src={mobileBackground} className="mobile-bg" alt="Mascote Z da viuz apontando para o texto"/>
      </Grid>
      <S.WelcomeText item container md={4}>
        <p>
          Bem-vindos à VIUZZ
          <br />
          <br />
          <span>
            Democratização na criação e comercialização de NFTs e avatares
          </span>
        </p>
        <Grid container item justifyContent="center">
          <Grid md={12} item>
            <S.GradientButton onClick={()=>{ /* auth ? navigate("/colecoes") : navigate("/cadastrar") */ navigate("/manutencao")  }} >JUNTE-SE A REVOLUÇÃO</S.GradientButton>
          </Grid>
        </Grid>
      </S.WelcomeText>
      <Grid item md={8}>
        <img src={desktopBackground} className="desktop-bg" alt="Mascote Z da viuz apontando para o texto"/>
      </Grid>
      <Grid container className="fadeout"></Grid>
      <Grid container >
        
      </Grid>
    </S.MainContainer>
  );
};

export default Welcome;
