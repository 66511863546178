import React from "react";
import * as S from "./style"
import { NFTListContext } from "../../context/NFTListContext";
import { CollectibleType } from "../../types/CollectibleType";
import { Grid } from "@mui/material";

export const SearchBar = () => {
	const [search, setSearch] = React.useState("");

	const { nftList } = React.useContext(NFTListContext);

	let nfts = []

	nfts = nftList?.filter((nft: CollectibleType) => nft?.title?.toLowerCase().includes(search.toLowerCase()));

	return (
		<S.GridContainer>
			<Grid container flexDirection='column'>
				<S.InputGrid>
					<S.TextField inputMode="search" value={search} onChange={(e) => setSearch(e.target.value)} /* onBlur={() => setSearch("")} */ placeholder="Pesquisar por nome do NFT"></S.TextField>
				</S.InputGrid>
				{search &&
					<S.ResultGrid container>
							<ul>
								{nfts?.map((nft: CollectibleType) => (
									<S.ItemGrid container key={nft.id}>
											<li>
												<S.NavLink to={"/colecionaveis/" + nft.url_title} reloadDocument key={nft.nftId}>
													<Grid container flexDirection="row" spacing={3}>
														<S.ImageGrid container item md={4} sm={4} xs={6} lg={4} xl={3}>
															<img src={nft.imageUrl} alt="Imagem do NFT" />
														</S.ImageGrid>
														<S.TextGrid container item md={8} sm={8} xs={6} lg={8} flexDirection="column">
															<p className="title">{nft.title}</p>
															<Grid container flexDirection="row" className="creatorGrid">
																<p>por</p>
																<p className="creatorName">{nft.creator?.name}</p>
																<S.Verified />
															</Grid>
														</S.TextGrid>
													</Grid>
												</S.NavLink>
											</li>
										</S.ItemGrid>
								))}
							</ul>
					</S.ResultGrid>
				}
			</Grid>
		</S.GridContainer>
	);
};