import React from "react";
import * as S from "./style";

import useShoppingCart from "../../hooks/useShoppingCart";
import { ClaimProjectReward, getProject, getUserRewards, getOnSale } from "../../utils/requests";
import { Checkbox, Collapse, FormControl, Grid, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, Tab, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useCountdown from "../../hooks/useCountdown";
import { ProjectType } from "../../types/ProjectType";
import { CleanedType } from "../../types/CleanedType"
import Closers from "../../components/ClosersPosts/Closers";
import ProjectList from "./CollectibleList/ProjectList";
import placeholderBanner from "../../assets/placeholder_banner.png"
import ReactMarkdown from 'react-markdown'
import remarkUnwrapImages from 'remark-unwrap-images'
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { GetUserRewardsResponse } from "../../types/RequestTypes";

import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "../../components/CustomComponents/ToastContainer/toast";
import { OnSaleType } from "../../types/OnSaleType";

type ColecionaveisProps = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectDetails: React.FC<ColecionaveisProps> = ({ auth, setAuth }) => {
  //eslint-disable-next-line
  const [shoppingCart, setShoppingCart] = useShoppingCart();
  const params = useParams();
  const navigate = useNavigate();

  const [project, setProject] = React.useState<ProjectType>();
  const [trending, setTrending] = React.useState<OnSaleType[]>();
  const [userRewards, setUserRewards] = React.useState<GetUserRewardsResponse>();

  const requestAndSetProject = async () => {
    const response = await getProject(params?.url_title ?? "");
    if (response) {
      let project = response;
      setProject(project);
    }
  };

  React.useEffect(() => {
    requestAndSetProject();
    //eslint-disable-next-line
  }, []);

  const requestUserRewards = async (projectId: string) => {
    const response = await getUserRewards(projectId);
    if (response) {
      setUserRewards(response);
    };
  };

  React.useEffect(() => {
    if (project) requestUserRewards(project.id);
    //eslint-disable-next-line
  }, []);

  const requestOnSale = async () => {
    const response = await getOnSale(params?.url_title ?? "");
    if (response) {
      setTrending(response);
    };
  };

  React.useEffect(() => {
    requestOnSale();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const claimRewards = async (projectId: string, rewardId: string) => {
    const response = await ClaimProjectReward(projectId, rewardId);
    if (response.reclaimed_rewards) {
      showToast("Recompensa resgatada com sucesso", "success");
    } else if (response.message) {
      showToast(response.message, "error");
    };
  };

  type TabType = 'History' | 'News' | 'Rewards' | 'Listed';

  const [currentTab, setCurrentTab] = React.useState<TabType>("History");
  const [filter, setFilter] = React.useState("");
  const [milestone, setMilestone] = React.useState<number[]>([]);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState([false, false]);
  const [refresh, setRefresh] = React.useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setCurrentTab(newValue);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
  };

  const [day, hour, minute, second] = useCountdown(project?.dateEnded!)

  const actualDate = new Date().toISOString();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    })
  };

  const verifyMilestones = () => {
    const valueArr: Array<number> = []
    if (project?.milestones) {
      project?.milestones.forEach((milestone) => {
        const value = parseInt(milestone.value);
        if ((project.totalSum / 100) <= value) {
          valueArr.push(value);
        }
      })
      setMilestone(valueArr);
    };
  };

  React.useEffect(() => {
    verifyMilestones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const milestonePercentage: number = project && milestone ? ((project?.totalSum / 100) / milestone[0]) * 100 : 0;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const nftArray: CleanedType[][] = []

  if (project?.cleanedNfts) {
    nftArray.push(project.cleanedNfts);
  };

  if (project?.nftsFromCollections) {
    nftArray.push(project.nftsFromCollections);
  };

  const flatNftArray = nftArray.flat();

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [filteredNfts, setFilteredNfts] = React.useState<CleanedType[]>([]);


  const handleFilter = (filter: boolean) => {
    setFilteredNfts(flatNftArray.filter((nft: CleanedType) => nft.isAvailable === filter));
  };

  React.useEffect(() => {
    if (checked[0] === true && checked[1] === false) {
      handleFilter(true)
    };
    if (checked[0] === false && checked[1] === true) handleFilter(false);
    if (((checked[0] === false) && (checked[1] === false)) || ((checked[0] === true) && (checked[1] === true))) {
      setFilteredNfts(flatNftArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    let nfts = flatNftArray?.filter((nft: CleanedType) => nft?.title?.toLowerCase().includes(search.toLowerCase()));
    setFilteredNfts(nfts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    if (filter === "recent") {
      const sort = flatNftArray.sort((a, b) => -a.createdAt.localeCompare(b.createdAt));
      setFilteredNfts(sort);
    };
    if (filter === "priceDesc") {
      const sort = flatNftArray.sort((a, b) => b.value - a.value);
      setFilteredNfts(sort);
    };
    if (filter === "priceAsc") {
      const sort = flatNftArray.sort((a, b) => a.value - b.value);
      setFilteredNfts(sort);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <S.NavBar
        cartItemCount={shoppingCart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <S.OuterContainer
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <S.Container container item>
          <S.TitleContainer item xs={12}>
            <S.CollectionTitle>{project ? project.title : ""}</S.CollectionTitle>
            <Grid container className="creatorGrid" justifyContent="center">
              <p className="sideItems">por</p>
              <p className="creatorName" onClick={() => navigate(`/profile/${project?.author.username}`)}>{project ? project?.author.name : null}</p>
              <svg className="sideItems" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.0833 10L18.8466 7.4425L19.1583 4.06L15.8491 3.30833L14.1166 0.375L11 1.71333L7.88329 0.375L6.15079 3.29917L2.84163 4.04167L3.15329 7.43333L0.916626 10L3.15329 12.5575L2.84163 15.9492L6.15079 16.7008L7.88329 19.625L11 18.2775L14.1166 19.6158L15.8491 16.6917L19.1583 15.94L18.8466 12.5575L21.0833 10ZM9.24913 14.3267L5.76579 10.8342L7.12246 9.4775L9.24913 11.6133L14.6116 6.2325L15.9683 7.58917L9.24913 14.3267Z" fill="url(#paint0_linear_256_162)" />
                <defs>
                  <linearGradient id="paint0_linear_256_162" x1="0.916625" y1="18.2499" x2="21.0833" y2="18.2499" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FB8404" />
                    <stop offset="1" stopColor="#FE1E14" />
                  </linearGradient>
                </defs>
              </svg>
            </Grid>
          </S.TitleContainer>
          <S.OuterCarouselGrid container>
            <S.LeftCarouselGrid flexDirection="row" justifyContent="center">
              <Grid className="testCarouselBugFix" item xs={12} sm={12} md={7} lg={7}>
                <Carousel
                  additionalTransfrom={0}
                  containerClass="container"
                  draggable
                  infinite
                  arrows={false}
                  minimumTouchDrag={80}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024
                      },
                      items: 1
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0
                      },
                      items: 1
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464
                      },
                      items: 1
                    }
                  }}
                  shouldResetAutoplay
                  showDots
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {project?.media.length === 0 &&
                    <img
                      src={placeholderBanner}
                      style={{
                        display: 'block',
                        height: '100%',
                        margin: 'auto',
                        width: '100%'
                      }}
                      alt="Banner Placeholder"
                    />
                  }
                  {project?.media.map((media) => {
                    return (
                      <img
                        src={media}
                        key={media}
                        style={{
                          display: 'block',
                          height: '100%',
                          margin: 'auto',
                          width: '100%'
                        }}
                        alt="Imagem de divulgação"
                      />
                    )
                  })

                  }
                </Carousel>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <S.RightGrid container>
                  <Grid container flexDirection="row" className="valueGrid">
                    <p className="valueText">R$ {project ? project?.totalSum / 100 : 0},00</p>
                    <p className="collectedText">arrecadados</p>
                  </Grid>
                  <Grid container justifyContent="center">
                    <S.FundingProgressBar variant="determinate" value={milestonePercentage ? milestonePercentage : 0} />
                  </Grid>
                  <Grid container>
                    <p className="goalText">Meta: {milestone ? milestone[0] : 0},00 ({milestonePercentage ? milestonePercentage.toFixed(2) : 0}% alcançada)</p>
                  </Grid>
                  <S.StatsGrid container>
                    <Grid container flexDirection="row">
                      <svg className="icons" width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.53846 5.42857C5.53846 8.668 6.85137 11.7748 9.18836 14.0654C11.5254 16.356 14.695 17.6429 18 17.6429C21.305 17.6429 24.4746 16.356 26.8116 14.0654C29.1486 11.7748 30.4615 8.668 30.4615 5.42857H36C36.0006 8.65692 35.0975 11.8236 33.389 14.5836C31.6805 17.3437 29.2321 19.5914 26.3105 21.0819L26.3077 38H9.69231V21.0846C6.76973 19.5942 4.3205 17.3463 2.61146 14.5857C0.90242 11.8251 -0.000870417 8.65766 6.2937e-07 5.42857H5.53846ZM18 0C19.8361 0 21.597 0.714921 22.8954 1.98749C24.1937 3.26006 24.9231 4.98603 24.9231 6.78571C24.9231 8.5854 24.1937 10.3114 22.8954 11.5839C21.597 12.8565 19.8361 13.5714 18 13.5714C16.1639 13.5714 14.403 12.8565 13.1046 11.5839C11.8063 10.3114 11.0769 8.5854 11.0769 6.78571C11.0769 4.98603 11.8063 3.26006 13.1046 1.98749C14.403 0.714921 16.1639 0 18 0Z" fill="url(#paint0_linear_180_836)" />
                        <defs>
                          <linearGradient id="paint0_linear_180_836" x1="19.6175" y1="-1.86007e-06" x2="19.6175" y2="58.9559" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FB8404" />
                            <stop offset="1" stopColor="#FE1E14" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <p className="numbers">0</p>
                      <p className="rewardsText">apanhadores de recompensas</p>
                    </Grid>
                    <Grid container flexDirection="row">
                      <svg className="icons" width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0002 12.5834L17.8796 21.9114L27.9482 22.7181L20.2776 29.2887L22.6206 39.1154L14.0002 33.8501L5.37991 39.1154L7.72291 29.2887L0.0522461 22.7181L10.1209 21.9114L14.0002 12.5834ZM6.66691 0.666748V17.1667H3.00025V0.666748H6.66691ZM25.0002 0.666748V17.1667H21.3336V0.666748H25.0002ZM15.8336 0.666748V9.83341H12.1669V0.666748H15.8336Z" fill="url(#paint0_linear_180_824)" />
                        <defs>
                          <linearGradient id="paint0_linear_180_824" x1="15.2536" y1="0.666746" x2="15.2536" y2="60.3187" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FB8404" />
                            <stop offset="1" stopColor="#FE1E14" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <p className="numbers rewardNumber">{project ? project?.reward.length : ""}</p>
                      <p className="rewardsText">recompensas disponíveis</p>
                    </Grid>
                    <Grid container flexDirection="row">
                      <svg className="icons" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.3333 4.00008H31.9999C32.4419 4.00008 32.8659 4.17568 33.1784 4.48824C33.491 4.8008 33.6666 5.22472 33.6666 5.66675V32.3334C33.6666 32.7754 33.491 33.1994 33.1784 33.5119C32.8659 33.8245 32.4419 34.0001 31.9999 34.0001H1.99992C1.55789 34.0001 1.13397 33.8245 0.821407 33.5119C0.508847 33.1994 0.333252 32.7754 0.333252 32.3334V5.66675C0.333252 5.22472 0.508847 4.8008 0.821407 4.48824C1.13397 4.17568 1.55789 4.00008 1.99992 4.00008H8.66658V0.666748H11.9999V4.00008H21.9999V0.666748H25.3333V4.00008ZM3.66659 14.0001V30.6667H30.3333V14.0001H3.66659ZM6.99992 20.6667H15.3333V27.3334H6.99992V20.6667Z" fill="url(#paint0_linear_180_860)" />
                        <defs>
                          <linearGradient id="paint0_linear_180_860" x1="18.4976" y1="0.666746" x2="18.4976" y2="52.3824" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FB8404" />
                            <stop offset="1" stopColor="#FE1E14" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <p className="numbers">{(project?.dateEnded && actualDate > project?.dateEnded) ? `${day}:${hour}:${minute}:${second}` : "Encerrado"}</p>
                    </Grid>
                  </S.StatsGrid>
                  <Grid container justifyContent="center">
                    <S.BuyButtons onClick={() => scrollToBottom()}>
                      <p className="ButtonTxt">COMPRAR AGORA</p>
                    </S.BuyButtons>
                  </Grid>
                </S.RightGrid>
              </Grid>
            </S.LeftCarouselGrid>
          </S.OuterCarouselGrid>
          <S.TabGrid container>
            <S.Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="Abas do perfil"
              variant="scrollable"
            >
              <Tab className='profilebuttons' value="History" label="A História" />
              <Tab className='profilebuttons' value="News" label="Novidades" />
              <Tab className='profilebuttons' value="Rewards" label="Recompensas" />
              <Tab className='profilebuttons' value="Listed" label="Listados" />
            </S.Tabs>
            <S.TabDivider />
          </S.TabGrid>
          {currentTab === "History" &&
            <Grid container>
              <ReactMarkdown
                remarkPlugins={[remarkUnwrapImages]}
                components={{
                  img: ({ node, ...props }) => <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><img {...props} alt="Imagem de divulgação da campanha" style={{ maxWidth: '100%' }} /></div>,
                  // eslint-disable-next-line jsx-a11y/heading-has-content
                  h1: ({ node, ...props }) => <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><h1 style={{ textAlign: 'center' }} {...props} /></div>
                }}
              >
                {`${project ? project.description : ""}`}
              </ReactMarkdown>
            </Grid>
          }
          {currentTab === "Rewards" &&
            <S.RewardsContainer container flexDirection="row" justifyContent="center">
              {project?.reward.map((reward) => {
                return (
                  <S.RewardsGrid>
                    <Grid container className="tierText" justifyContent="center">
                      <p>{reward.name}</p>
                    </Grid>
                    <Grid container className="rewardText" justifyContent="center">
                      <p>{reward.value} NFT</p>
                    </Grid>
                    <Grid container justifyContent="center">
                      {userRewards?.toClaim.find((userReward) => userReward.id === reward.id) &&
                        <S.RewardButton>
                          <p className="ButtonTxt" onClick={() => claimRewards(project?.id, reward?.id)}>OBTER RECOMPENSA</p>
                        </S.RewardButton>
                      }
                      {!userRewards?.toClaim.find((userReward) => userReward.id === reward.id) &&
                        <S.RewardButton className="disabledButton" disabled>
                          <p className="ButtonTxt">OBTER RECOMPENSA</p>
                        </S.RewardButton>
                      }
                      {userRewards?.alreadyClaimed.find((userReward) => userReward.id === reward.id) &&
                        <S.RewardButton className="disabledButton" disabled>
                          <p className="ButtonTxt">JÁ RESGATADA</p>
                        </S.RewardButton>
                      }
                    </Grid>
                    <Grid>
                      <p className="descriptionTitle">Esta opção garante:</p>
                    </Grid>
                    <Grid>
                      <ReactMarkdown>{reward.description}</ReactMarkdown>
                    </Grid>
                  </S.RewardsGrid>
                )
              })
              }
            </S.RewardsContainer>
          }
          {currentTab === "News" &&
            <>
              <Closers key={refresh} creatorUsername={project ? project?.author.username : ""} refresh={setRefresh} />
            </>
          }
          {currentTab === "Listed" &&
            <>
              <Grid>
                {!trending &&
                  <Grid>
                    <p>Não há colecionáveis listados</p>
                  </Grid>
                }
                {trending &&
                  <Grid container item lg={12} xl={12}>
                    <p>{trending.length} resultados</p>
                    <ProjectList saleList={trending} lg={2} />
                  </Grid>
                }
              </Grid>
            </>
          }
          <Grid container>
            <S.SubtitleGrid container>
              <p className="subTitle">Itens da Coleção</p>
            </S.SubtitleGrid>
            <S.BottomContainer container flexDirection="row">
              <S.FilterIconGrid container item xl={1} lg={1} className="leftIcon" onClick={() => { setShowFilter(!showFilter) }}>
                <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.1667 17.5H15.8333V14.6667H10.1667V17.5ZM0.25 0.5V3.33333H25.75V0.5H0.25ZM4.5 10.4167H21.5V7.58333H4.5V10.4167Z" fill="url(#paint0_linear_226_113)" />
                  <defs>
                    <linearGradient id="paint0_linear_226_113" x1="14.1457" y1="0.499999" x2="14.1457" y2="26.875" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#FB8404" />
                      <stop offset="1" stopColor="#FE1E14" />
                    </linearGradient>
                  </defs>
                </svg>
              </S.FilterIconGrid>
              <Grid container item xl={2} lg={2} flexDirection="column" justifyContent="center">
                <p className="results">{flatNftArray ? flatNftArray.length : ""} resultados</p>
              </Grid>
              <Grid container item xl={6} lg={6} className="searchGrid">
                <input inputMode="search" className="searchBar" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisar por nome"></input>
              </Grid>
              <S.SelectGrid container item xl={2} lg={2}>
                <FormControl fullWidth>
                  <S.SelectLabel id="demo-simple-select-label">
                    <Typography>Filtro</Typography>
                  </S.SelectLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    className="select"
                    id="demo-simple-select"
                    value={filter}
                    label="Filtro"
                    onChange={handleChange}
                    sx={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={"recent"}>Listado recentemente</MenuItem>
                    <MenuItem value={"priceDesc"}>Maior preço</MenuItem>
                    <MenuItem value={"priceAsc"}>Menor preço</MenuItem>
                  </Select>
                </FormControl>
              </S.SelectGrid>
            </S.BottomContainer>
          </Grid>
          <Grid container>
            {showFilter &&
              <S.FilterGrid container item lg={2} xl={2}>
                <List>
                  <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Status" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <S.CheckboxLabel control={<Checkbox checked={checked[0]} onChange={() => setChecked([!checked[0], checked[1]])} />} label="Disponíveis" />
                    </List>
                    <List component="div" disablePadding>
                      <S.CheckboxLabel control={<Checkbox checked={checked[1]} onChange={() => setChecked([checked[0], !checked[1]])} />} label="Esgotados" />
                    </List>
                  </Collapse>
                </List>
              </S.FilterGrid>
            }
            {project &&
              <Grid container item lg={showFilter ? 10 : 12} xl={showFilter ? 10 : 12}>
                <ProjectList cleanedNftsList={filteredNfts.length > 0 || checked[1] ? filteredNfts : flatNftArray} lg={2} unavailable />
              </Grid>
            }
          </Grid>
        </S.Container>
      </S.OuterContainer>
      <S.Footer />
    </>
  );
};

export default ProjectDetails;
