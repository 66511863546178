import styled from "styled-components";
import { Button, Fab, Grid as MuiGrid, Typography } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import { Tabs as MuiTabs } from "@mui/material";

export const ClosersGrid = styled(MuiGrid)`
    .IconText {
      margin: 0;
    };

    .LeurisImg {
        height: 304px;
        width: 400px;
        margin-right: 40px;
          
          @media (min-width: 700px) {
          height: 600px;
          width: 600px;
          }
          @media (min-width: 900px) {
          padding-top: 200px;
          height: 600px;
          width: 600px;
          }
          @media (min-width: 1200px) {
          padding-top: 200px;
          height: 600px;
          width: 800px;
          }
    };

    .ButtonGrid {
      @media (max-width: 650px) {
        justify-content: center;
        margin-top: 15px;
      }
    }
`

export const TestClosersGrid = styled(MuiGrid)`
    .ClosersAvatar {
    width: 86px;
    height: 86px;
    border-radius: 65px;
    }
`
export const ClosersAvatarNameGrid = styled(MuiGrid)`
  padding-left: 20px;
  @media (min-width: 1200px) {
    padding-left: 50px;
  }
`

export const ClosersAvatar = styled(Avatar)`
  border: double 2px transparent;
  border-radius: 60px;
  background-image: linear-gradient(black, black), radial-gradient(#fb8404 0%, #fe1e14 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  width: 70px !important;
  height: 70px !important;
`

export const RedInfoIcon = styled(InfoIcon)`
  color: #E22B00;
`

export const CreatePostButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  gap: 15px;
    
  @media (min-width: 700px) {
      width: 300px;
      height: 60px;
   }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`

export const ScrollableGrid = styled(MuiGrid)`
  overflow: auto;
  max-height: 680px;
  margin-top: 20px;
  @media (max-width: 899px) {
    margin-bottom: 30px;
  }
`

export const PostGrid = styled(MuiGrid)`
  background: rgba(255, 255, 255, 0.1);
  padding-bottom: 60px;
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 15px;
  margin-top: 30px !important;

  .InnerGrid {
    padding: 20px 20px 0 20px;
  }
`

export const EmptyClosersGrid = styled(MuiGrid)`
  margin-bottom: 30px;
`

export const EmptyClosersText = styled(Typography)`
  font-family: 'Bebas Neue' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 38px !important;
  
  @media (min-width: 700px) {
    font-size: 66px !important;
   }

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  line-height: 0.8 !important;

  color: #FFFFFF;

  padding-bottom: 30px;
  padding-top: 100px;
`

export const Grid = styled(MuiGrid)`
  text-align: center;
  justify-content: center !important;

  .linear-gradient-line {
    display: flex;
    width: 100vw;
    background-color: red;
    height: 2px;
    transform: translate(0, -4px);
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    opacity: 0.75;
    border-radius: 5px;
    @media (min-width: 440px) {
      width: 440px;
    }
    @media (min-width: 570px) {
      width: 570px;
    }
    @media (min-width: 600px) {
      width: 600px;
      height: 3px;
      transform: translate(0, -6px);
    }
    @media (min-width: 990px) {
      width: 780px;
    }
  }
`;

export const CreationGrid = styled(Grid)`
  justify-content: end;
  text-align: left !important;
  
    .SadImg {
      height: 304px;
      width: 238px;
      margin-right: 70px;
        
        @media (min-width: 700px) {
        height: 404px;
        width: 238px;
        }
    }

    .PointingImg{
      height: 304px;
      width: 238px;
      margin-right: 40px;
        
        @media (min-width: 700px) {
        height: 404px;
        width: 238px;
        }
    } 
`

export const RedirectButtons = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  margin-bottom: 50px !important;
    
  @media (min-width: 700px) {
      width: 417px;
      height: 60px;
   }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`
export const Tabs = styled(MuiTabs)`
  justify-content: space-evenly;
  @media (min-width: 1440px) {
    width: 100%;
  }
  .MuiTab-root {
    font-family: "Barlow Semi Condensed";
    font-style: normal;
    font-weight: 600;
    text-transform: none !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    @media (min-width: 570px) {
      font-size: 20px;
    }
    @media (min-width: 990px) {
      font-size: 28px;
    }
  }
  .MuiTabs-indicator {
    z-index: 2;
    height: 6px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    border-radius: 1px;
    @media (min-width: 600px) {
      height: 9px;
    }
  }
`;

export const VerticalTabs = styled(Tabs)`
  width: 100%;
  .MuiTab-root {
    font-weight: 400;
    height: 100px;
  }
  .MuiTab-root.Mui-selected {
    font-weight: 600;
  }
  .MuiTabs-indicator {
    left: 10px;
  }
`

export const ClosersText = styled(Typography)`
  font-family: 'Bebas Neue' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 38px !important;
  
  @media (min-width: 700px) {
    font-size: 66px !important;
   }

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  line-height: 0.8 !important;

  color: #FFFFFF;

  padding-bottom: 30px;
`

export const PostMenuGrid = styled(MuiGrid)`
  justify-content: end;
  align-self: start;

  svg {
    cursor: pointer;
    align-self: end;
  }
`

export const PostOptionsGrid = styled(MuiGrid)`
  border: 1px solid rgba(255,255,255,0.45);
  border-radius: 5px;
  justify-content: center;

  p {
    color: #E22B00;
  }
`

export const PostDeleteConfirmationGrid = styled(MuiGrid)`
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgba(255,255,255,0.45);
  border-radius: 5px;
  justify-content: center;
  max-width: 300px;
  background-color: black;

  @media (max-width: 992px) {
    top: auto;
    left: auto;
  }

  .buttonGrid {
    margin-top: 20px;
    gap: 10px;
  }
`

export const IconFab = styled(Fab)`
  justify-self: right;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
    
    :hover{
      transform: scale(1.1);
/*       margin-right: 10px; */
    }
`

export const ConfirmButtonsGrid = styled(MuiGrid)`
  justify-content: center;
  padding: 0;

  @media (max-width: 992px) {
    gap: 10px;

    p {
      text-align: left !important;
    }
  }

  p {
    min-width: 125px;
    text-align: center;
  }
`