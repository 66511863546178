import styled from "styled-components";
import Navbar from "../../components/Navbar/Navbar";
import WelcomeComponent from "./Welcome/Welcome";
import AvatarComponent from "./Avatar/Avatar";
import NftComponent from "./Nft/Nft";
import FaqComponent from "./Faq/Faq";
import FooterComponent from "../../components/Footer/Footer";
import Fab from '@mui/material/Fab';

export const NavBar = styled(Navbar)`
  && {
    position: absolute;
    z-index: 500;
  }
`;

export const Welcome = styled(WelcomeComponent)``;
export const Roadmap = styled.img`
  width: 100%;
  aspect-ratio: 410/ 82;
  @media (max-width: 900px) {
    padding-top: 50px;
  }
`;
export const Avatar = styled(AvatarComponent)``;
export const Nft = styled(NftComponent)``;
export const Faq = styled(FaqComponent)`
  width: 100vw !important;
  overflow-x: hidden !important;
`;
export const Footer = styled(FooterComponent)``;

export const SupportFab = styled(Fab)`
  position: fixed !important;
  bottom: 100px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 10px !important;
    
    :hover{
      transform: scale(1.3);
      margin-right: 10px;
    }
`