import styled from "styled-components";
import { Grid } from "@mui/material";
import Input from "../../components/Input/input";
import { Link } from "react-router-dom";
import VerifiedIcon from '@mui/icons-material/Verified';

export const ItemGrid = styled(Grid)`
	background-color: #101010;
	margin: 5px 0;
	padding: 10px 30px;
	border: 3px solid #E22B00;
  cursor: pointer;
  border-radius: 10px;

	img {
		width: 100%;
		height: 100%;

    @media (max-width: 992px) {
      height: 60%;
    }
	}

  .css-fsg4ki-MuiGrid-root>.MuiGrid-item {
    @media (max-width: 992px) {
      padding-left: 0px;
    }
  }
`

export const ResultGrid = styled(Grid)`
	justify-content: right;
	ul {
		position: fixed;
    height: 83%;
		list-style: none;
    overflow: auto;
    padding: 0px;
    margin: 0px;
		width: 40%;

    @media (max-width: 992px) {
      position: static;
      width: 100%;
      overflow-wrap: anywhere;
    }
	}
`

export const InputGrid = styled(Grid)`
	text-align: right !important;

	div {
		justify-content: right;
	}

	input {
		width: 50%;
    
    @media (max-width: 992px) {
    width: 100%;
    }
	}
`

export const GridContainer = styled(Grid)`
	position: fixed;
	top: 70px;
	right: 30px;
	width: 50%;

  @media (max-width: 992px) {
    position: static;
    width: 100%
  }
`

export const TextField = styled(Input)`
  .MuiInputBase-input {
    border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important;
    padding: 15px 15px;
    transition: 250ms;
    border: 1px solid rgb(46,46,56) !important;
    /* height:25px ; */
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
  }
  [aria-invalid="true"] {
    border-style: solid !important;
    border-width: 1px !important;
  }
  /* @media (min-width: 320px) {
    width: 320px !important;
  }
  @media (min-width: 420px) {
    width: 420px !important;
  } */

  [name="password"] {
    margin-top: 0px;
  }
`;

export const TextGrid = styled(Grid)`
  font-size: 24px;
  padding-left: 14px !important;

  .creatorGrid {
    @media (max-width: 992px) {
      p {
        margin: 0;
      }
    }
  }

  .creatorName {
    padding: 0 5px;
    padding-top: 17px;
    font-size: 32px;
    color: #E22B00;
    font-weight: 700;
    margin-top: 0;
    @media (max-width: 992px) {
      padding-left: 0;
    }
  }

  .title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0;
  }
`

export const ListGrid = styled(Grid)`
  @media (max-width: 992px) {
  padding-left: 0;
  }
`

export const NavLink = styled(Link)`
  padding: 0 !important;
  text-align: initial !important;
`

export const Verified = styled(VerifiedIcon)`
  padding-top: 28px;
  color: #E22B00;
`

export const ImageGrid = styled(Grid)`
  @media (max-width: 992px) {
    justify-content: center !important;
    flex-direction: column !important;
  }
`