import React from 'react'
import { UserType } from '../../../types/UserType';
import { ValidatedField } from '../../../types/ValidatedField';
import showToast from '../../../components/CustomComponents/ToastContainer/toast';
import * as T from "../../../components/CustomComponents/ToastContainer/style";

import * as S from './style'
import { changePasswordWithOldPassword } from '../../../utils/requests';

type props = {
  user: UserType;
}
//eslint-disable-next-line
const ChangePassword: React.FC<props> = ({ user }) => {
  const [currentPassword, setCurrentPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<ValidatedField>({
    value: "",
    valid: true,
    message: "A senha precisa ter entre 4 e 20 caracteres."
  });
  const [confirmPassword, setConfirmPassword] = React.useState<ValidatedField>({
    value: "",
    valid: true,
    message: "A senha precisa ter entre 4 e 20 caracteres."
  });

  //declare validated field


  const handleChangePassword = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const token = localStorage.getItem('token');
    if (!currentPassword || !newPassword || !confirmPassword) return;
    if (newPassword.value !== confirmPassword.value) 
      { showToast("O campo 'Nova Senha' e 'Repetir Nova Senha' devem ser iguais.", "error"); return; }

    if (newPassword.value.length < 4 || newPassword.value.length > 20) 
      { showToast("A senha precisa ter entre 4 e 20 caracteres.", "error"); return; }

    if (currentPassword === newPassword.value) 
      { showToast("A nova senha deve ser diferente da atual.", "error"); return; }

    if( !token ) { showToast("Não foi possível alterar a senha. Token inválido.", "error"); return; }

    let btnSalvar = e.currentTarget;
    btnSalvar.disabled = true;
    const res = await changePasswordWithOldPassword(token, currentPassword, newPassword.value);
    if (!res?.email) showToast("Não foi possível alterar a senha.", "error");
    if (res?.email) {
      showToast("Senha alterada com sucesso.", "success")
      setCurrentPassword("");
      setNewPassword(curVal => {
        return {
          ...curVal,
          value: "",
        }
      });
      setConfirmPassword(curVal => {
        return {
          ...curVal,
          value: "",
        }
      });
    };
    btnSalvar.disabled = false;
  }

  return (
    <S.Grid container item xs={12} px={1}>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <S.TextField
        type="password"
        placeholder="Senha atual"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <S.TextField
        type="password"
        placeholder="Nova Senha"
        value={newPassword.value}
        onChange={(e) => {
          setNewPassword((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value,
              valid: 20 >= e.target.value.length && e.target.value.length >= 4
                ? true
                : false,
            }
          })
        }}
        error={newPassword.valid}
        style={{ marginTop: "1rem" }}
      />
      {!newPassword.valid ? (
        <S.ErrorMessage>{newPassword.message}</S.ErrorMessage>
      ) : null}
      <S.TextField
        type="password"
        placeholder="Repetir Nova Senha"
        value={confirmPassword.value}
        onChange={(e) => {
          setConfirmPassword((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value,
              valid: 20 >= e.target.value.length && e.target.value.length >= 4
                ? true
                : false,
            }
          })
        }}
        error={confirmPassword.valid}
        style={{ marginTop: "1rem" }}
      />
      {!confirmPassword.valid ? (
        <S.ErrorMessage>{confirmPassword.message}</S.ErrorMessage>
      ) : null}
      <S.Grid item container justifyContent={'end'} px={0}>
        <S.Grid item px={0}>
          {user && <button className="saveButton"onClick={e => handleChangePassword(e)}>Salvar</button>}
        </S.Grid>
      </S.Grid>

    </S.Grid>

  )
}

export default ChangePassword