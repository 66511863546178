import { Grid } from "@mui/material";
import styled from "styled-components";

export const BlackGrid = styled(Grid)`
  background-color: #000;
  justify-content: center;

  img {
    width: 80%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`