import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import { GradientButton } from "../../../components/CustomComponents/GradientButton/style";

export const Grid = styled(MuiGrid)`
  background-color: rgba(0,0,0,0) !important;
  .avatardemo {
    display: inline-flex;
    width: 60%;
    margin: 20%;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
  }
  @media (min-width: 900px) {
    .avatardemo {
      width: 100%;
      /* transform: translate(8vw, -28vw); */
      margin: 0px;
      height: auto;
    }
  }
`;

export const Container = styled(MuiGrid)`
  padding: 0px;
  margin-top: 25px;
  /* overflow-x: hidden; */
`;

export const OutlinedText = styled(MuiGrid)`
  p {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Black Han Sans;
    font-size: 18vw;
    font-style: normal;
    font-weight: 400;
    line-height: 103%;
    letter-spacing: 0.05em;
    text-align: center;
    overflow-y: hidden;

    color: rgb(0, 0, 0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.16);
  }
  @media (min-width: 900px) {
    p {
      transform: translate(-12vw, 0px) scale(1.2);
      width: 100%;
      font-size: 12.2vw;
      padding-right: 0vw;
      padding-left: 0px;
      margin-top: 30px;
      justify-content: flex-end;
    }
    z-index: -1;
  }
`;

export const TextWrapper = styled(MuiGrid)`
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 7.5vw;
  line-height: 84.5%;
  letter-spacing: 0.015em;
  /* or 59px */
  padding-right: 8vw;
  padding-left: 8vw;
  color: #ffffff;
  /* transform: translate(0px, -70%); */
  @media (min-width: 900px) {
    font-size: 5vw;
    /* transform: translate(0px, -11.3vw); */
    padding-right: 0px;
    padding-left: 0px;
    p {
      margin-bottom: 0px;
      padding-bottom: 0px;
      justify-content: center;
    }
  }
`;

export const Button = styled(GradientButton)`
  text-align: center;
  display: inline-flex;
  margin-top: 30px;
`;

export const TextContainer = styled(MuiGrid)`
  padding-bottom: 50px;
  transform: translate(0px, -8vw);
  @media (max-width: 900px) {
    height: 250px;
  }
  @media (min-width: 900px){
    padding-right:10vw;
  }
`;
