import { FormControl, Grid, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link, useLocation } from 'react-router-dom';
import useShoppingCart from "../../hooks/useShoppingCart";
import { securePayment, PixRequest, GetPixNFT, /* testMint */ } from "../../utils/requests";
import {
  calculateTotalPrice, calculateTotalWithInterest, encryptCard, formatAsBRLCurrency, getCardBrand, maskCellphone,
  maskExpiredate, validateCellphoneNumber, validateCpfOrCnpj,
  validateCvv,
  validateExpireDate
} from "../../utils/sharedfunctions";
import * as S from "./style";

import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "../../components/CustomComponents/ToastContainer/toast";
import PurchaseCompleted from "./PurchaseCompleted/PurchaseCompleted";

import { BillingAddressType } from "../../types/BillingAddressType";
import { CollectibleType } from "../../types/CollectibleType";
import { CreditCardType } from "../../types/CreditCardType";
import { OrderType } from "../../types/OrderType";
import { CreditPaymentResponse, PixRequestType } from "../../types/RequestTypes";
import { SecurePaymentType } from "../../types/SecurePaymentType";
import { UserType } from "../../types/UserType";
import { ValidatedField } from "../../types/ValidatedField";
import { getUserData, executeFreeNFT } from './../../utils/requests';
import BillingForm from "./BillingForm/BillingForm";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import ccIcon from '../../assets/ccIcon.png';
import PixIcon from '../../assets/PixIcon.png';
import SadZe from '../../assets/ze_sad.png';


type Props = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

type CreditCardProps = {
  props: {
    cpf: [ValidatedField, React.Dispatch<React.SetStateAction<ValidatedField>>];
    name: [
      ValidatedField,
      React.Dispatch<React.SetStateAction<ValidatedField>>
    ];
    phone: [
      ValidatedField,
      React.Dispatch<React.SetStateAction<ValidatedField>>
    ];
    creditCardNumber: [
      ValidatedField,
      React.Dispatch<React.SetStateAction<ValidatedField>>
    ];
    cvv: [ValidatedField, React.Dispatch<React.SetStateAction<ValidatedField>>];
    expiryDate: [
      ValidatedField,
      React.Dispatch<React.SetStateAction<ValidatedField>>
    ];
    handleSavePayment: () => void;
  };
};

const CreditCard: React.FC<CreditCardProps> = ({ props }) => {
  const [name, setName] = props.name;
  const [phone, setPhone] = props.phone;
  const [cpf, setCpf] = props.cpf;
  const [cardnumber, setCardNumber] = props.creditCardNumber;
  const [cvv, setCvv] = props.cvv;
  const [expiryDate, setExpiryDate] = props.expiryDate;
  const handleSavePayment = props.handleSavePayment;


  return (
    <>
      <S.TextField
        type="text"
        placeholder="Nome do Titular"
        value={name.value}
        onChange={(e) => {
          setName((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value,
              valid: e.target.value.length >= 4 ? true : false,
            };
          });
        }}
        error={!name.valid}
      />
      {!name.valid && name.value !== "" ? <S.ErrorMessage>{name.message}</S.ErrorMessage> : null}

      <S.TextField
        type="text"
        placeholder="Telefone - (99) 99999-9999"
        value={phone.value}
        onChange={(e) => {
          setPhone((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value.length > 15 ? maskCellphone(e.target.value.slice(0, 15)) : maskCellphone(e.target.value),
              valid: validateCellphoneNumber(e.target.value),
            };
          });
        }}
        error={!phone.valid}
        style={{ marginTop: "1rem" }}
      />
      {!phone.valid && phone.value !== "" ? <S.ErrorMessage>{phone.message}</S.ErrorMessage> : null}

      <S.TextField
        type="text"
        placeholder="CPF ou CNPJ"
        value={cpf.value}
        onChange={(e) => {
          setCpf((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value.replace(/\D/g, "").substring(0, 11),
              valid: validateCpfOrCnpj(e.target.value.replace(/\D/g, "").substring(0, 11)),
            };
          });
        }}
        error={!cpf.valid}
        style={{ marginTop: "1rem" }}
      />
      {!cpf.valid && cpf.value !== "" ? <S.ErrorMessage>{cpf.message}</S.ErrorMessage> : null}

      <S.TextField
        type="text"
        placeholder="Número do cartão"
        value={cardnumber.value}
        onChange={(e) => {
          setCardNumber((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 16),
              valid: !!getCardBrand(e.target.value.substring(0, 16)),
            };
          });
        }}
        error={!cardnumber.valid}
        style={{ marginTop: "1rem" }}
      />
      {!cardnumber.valid && cardnumber.value !== "" ? (
        <S.ErrorMessage>{cardnumber.message}</S.ErrorMessage>
      ) : null}
      <S.Grid item container p={0}>
        <S.Grid item xs={5} md={6} p={0}>
          <S.TextField
            type="text"
            placeholder="CVV"
            value={cvv.value}
            onChange={(e) => {
              setCvv((curvalue) => {
                return {
                  ...curvalue,
                  value: e.target.value.substring(0, 3),
                  valid: validateCvv(e.target.value.substring(0, 3)),
                };
              });
            }}
            error={!cvv.valid}
            style={{ marginTop: "1rem" }}
          />
          {!cvv.valid && cvv.value !== "" ? <S.ErrorMessage>{cvv.message}</S.ErrorMessage> : null}
        </S.Grid>
        <S.Grid item xs={7} md={6} p={0} pl={2}>
          <S.TextField
            type="text"
            placeholder="Validade MM/AA"
            value={expiryDate.value}
            onChange={(e) => {
              setExpiryDate((curvalue) => {
                return {
                  ...curvalue,
                  value: maskExpiredate(e.target.value.substring(0, 5)),
                  valid: validateExpireDate(e.target.value.substring(0, 7)),
                };
              });
            }}
            error={!expiryDate.valid}
            style={{ marginTop: "1rem" }}
          />
          {!expiryDate.valid && expiryDate.value !== "" ? (
            <S.ErrorMessage>{expiryDate.message}</S.ErrorMessage>
          ) : null}
        </S.Grid>
      </S.Grid>
      <S.Grid item container xs={12} justifyContent={"flex-end"} px={0}>
        <button onClick={handleSavePayment}>Continuar</button>
      </S.Grid>
    </>
  );
};

const Payment: React.FC<Props> = ({ auth, setAuth }) => {
  //eslint-disable-next-line
  const [shoppingcart, setShoppingCart] = useShoppingCart();
  //eslin-disable-next-line
  const [creditCardData, setCreditCardData] = React.useState<CreditCardType | undefined>(undefined)
  //eslint-disable-next-line
  const [billingAddressData, setBillingAddressData] = React.useState<BillingAddressType | undefined>(undefined)
  const [user, setUser] = React.useState<UserType | undefined>(undefined);
  const [order, setOrder] = React.useState<OrderType | undefined>(undefined);

  type LocationState = {
    order: OrderType;
  }
  const location = useLocation();
  const lstate = location?.state as LocationState;
  if (lstate?.order.order && order === undefined) {
    setOrder(lstate.order)
    localStorage.setItem('order', JSON.stringify(lstate.order))
  }

  //form field 
  const cpf = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "Por favor, digite um CPF ou CNPJ válido",
  });
  const name = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "O nome precisa ter pelo menos 4 caractéres.",
  });
  const phone = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "O telefone precisa estar no formato (99)99999-9999.",
  });
  const creditCardNumber = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "Por favor, digite um número de cartão válido",
  });
  const cvv = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "Por favor, digite um código de segurança válido",
  });
  const expiryDate = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "Por favor, digite uma data de expiração válida",
  });

  const zipCode = React.useState<ValidatedField>({
    value: "",
    valid: false,
    message: "Por favor, digite um cep válido",
  });

  const state = React.useState<string>("");
  const city = React.useState<string>("");
  const neighborhood = React.useState<ValidatedField>({
    value: "",
    valid: true,
    message: "Por favor, digite um bairro válido",
  });

  const street = React.useState<ValidatedField>({
    value: "",
    valid: true,
    message: "Por favor, digite uma rua válida",
  });

  const number = React.useState<ValidatedField>({
    value: "",
    valid: true,
    message: "Por favor, digite um número válido",
  });

  const [installments, setinstallments] = React.useState<number>(1);
  const [validatedPayment, setValidatedPayment] =
    React.useState<boolean>(false);
  const [validatedAddress, setValidatedAddress] =
    React.useState<boolean>(false);

  const [purchaseCompleted, setPurchaseCompleted] = React.useState<boolean>(false);
  const [boughtNfts, setBoughtNfts] = React.useState<CollectibleType[]>([] as CollectibleType[]);
  const [purchaseFail, setpurchaseFail] = React.useState<boolean>(false)

  const [paymentMethod, setPaymentMethod] = React.useState<number>(0);

  const [radioValue, setRadioValue] = React.useState<number>(0);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let paymentValue = parseInt((event.target as HTMLInputElement).value)
    if (!isNaN(paymentValue)) setRadioValue(paymentValue)
  };

  //in development

/*   const mintNFT = async () => {
    const res = await testMint(shoppingcart);
    console.log(res)
  } */

  const handleClick = () => {setPaymentMethod(radioValue); /* mintNFT() */;} 

  type InstallmentsType = {
    value: string;
    label: string;
  }

  const installmentsValues = () => {
    const totalprice = calculateTotalPrice(shoppingcart);
    let res: InstallmentsType[] = [];
    for (let i = 1; i < 11; i++) {
      if ((calculateTotalWithInterest(totalprice, i) / i) > 500 || i === 1) {
        res.push({
          value: i.toString(),
          label: i + 'x ' + formatAsBRLCurrency(calculateTotalWithInterest(totalprice, i) / i)
        })
      }
    }
    return res;;
  }

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    setinstallments(e.target.value as number);
  };

  const handleSavePayment = async () => {
    const paymentFields = [name, phone, creditCardNumber, cvv, expiryDate, cpf];
    var continueToAddress = true;
    paymentFields.forEach((field) => {
      if (!field[0].valid || field[0].value === "") {
        continueToAddress = false;
        /* showToast("Não foi possível salvar informações do cartão: dados inválidos ou vazios", "error"); */
        if (!field[0].valid) showToast(field[0].message, "error");
      }
    });

    if (continueToAddress) {
      setCreditCardData({
        holder: {
          name: name[0].value,
          tax_id: cpf[0].value,
          email: user?.email ?? "",
        },
        card: {
          number: creditCardNumber[0].value,
          exp_month: expiryDate[0].value.split("/")[0],
          exp_year: "20" + expiryDate[0].value.split("/")[1],
          security_code: cvv[0].value
        }
      })
      setValidatedPayment(true);
      setValidatedAddress(true)
    }
  };

  const handleSaveBillingAddress = async () => {
    const fields = [zipCode, neighborhood, street, number];
    var continueToInstallments = true;
    fields.forEach(field => {
      if (!field[0].valid && field[0].value !== "") {
        continueToInstallments = false;
        showToast("Não foi possível salvar endereço de pagamento: dados inválidos ou vazios.", "error");
      }
    })
    if (continueToInstallments) {
      setBillingAddressData({
        city: city[0],
        state: state[0],
        neighborhood: neighborhood[0].value,
        street: street[0].value,
        number: number[0].value,
        postal_code: zipCode[0].value,
        country: "Brazil"
      })
      setValidatedAddress(true);
    }
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    //Login, ForgotPassword, Transaction, CreateIntent
    const token = await executeRecaptcha('Transaction');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  const handleFinishOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  /*   console.log(recaptchaToken); */
    const button = e.currentTarget;
    let token = localStorage.getItem("token");

    const encryptedCardData = encryptCard({
      holder: creditCardData?.holder.name!,
      number: creditCardData?.card.number!,
      expMonth: creditCardData?.card.exp_month!,
      expYear: creditCardData?.card.exp_year!,
      securityCode: creditCardData?.card.security_code!
    })

    if (!encryptedCardData) return;
    if (executeRecaptcha) {
      const recaptoken = await executeRecaptcha('Transaction');
      setRecaptchaToken(recaptoken);
    }
    //console.log(token, creditCardData, order, recaptchaToken);
    if (token && creditCardData && order && recaptchaToken
    ) {
      const paymentData: SecurePaymentType = {
        orderId: order.order.id,
        amount: order.price.toString(),
        installments: installments,
        description: "COMPRA EM NFT.VIUZZ.COM",
        type: "CREDIT_CARD",
        card: encryptedCardData as string,
        holder: creditCardData?.holder,
      }

/*     const nftMinting = async (collectionName: string, quantity: number) => {
      let collections = {
        [collectionName]: 1
      };
      const data = await mintNFT(collections);
      console.log(data);
    }; */
      /* button.disabled = true; */
      const paymentResponse: CreditPaymentResponse = await securePayment(token, paymentData, recaptchaToken)!
      if (paymentResponse.message === 'Error on PagSeguro processing: Payment declined') {
        showToast("Pagamento Recusado, por favor, verifique seus dados e tente novamente", "error");
        setpurchaseFail(true);
      }
      if (paymentResponse.message === 'Error on PagSeguro processing: Payment Canceled') {
        showToast("Pagamento Cancelado, por favor, entre em contato com a operadora do cartão", "error");
        setpurchaseFail(true);
      }
      if (paymentResponse.error === false) {
        showToast("Compra realizada com sucesso!", "success");
        setBoughtNfts(paymentResponse.nftCollections!); //VERIFICAR SE FUNCIONA, SE NAO CORRIGIR TIPAGEM
        setPurchaseCompleted(true);
        setShoppingCart([]);
      }/*  else {
        showToast(`${paymentResponse?.data?.message}, erro`, "error");
        setpurchaseFail(true);
      } */ /* Debug on dev only */
      button.disabled = true;
    } else {
      showToast("Ocorreu um erro ao realizar a compra, tente novamente.", "error");
    }
  }

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  React.useEffect(() => {
    async function getUser() {
      const token: string = localStorage.getItem("token") != null ? localStorage.getItem("token") as string : "";
      const localUserData = JSON.parse(localStorage.getItem("user")!);
      const userInfo = await getUserData(token, localUserData.id)
      if (userInfo.email) {
        setUser(userInfo)
      }
    }
    getUser();
  }, [])

  const [userCpf, setUserCpf] = React.useState<string>("");

  const [pixInfo, setPixInfo] = React.useState<PixRequestType>();

  const valor = { original: `${calculateTotalPrice(shoppingcart) / 100}.00` }
  const solicitacaopagador = 'Cobrança da compra de NFT Viuzz'

  const generatePix = async () => {
    if (recaptchaToken) {
      const response = await PixRequest(valor, solicitacaopagador, recaptchaToken, userCpf)
      setPixInfo(response);
    }
  }

  React.useEffect(() => {
    generatePix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  const handlePixStatus = async () => {
    const response = await GetPixNFT(pixInfo?.txid!, order?.order.id!)
    if (response.nftCollections) {
      showToast("Compra realizada com sucesso!", "success");
      setBoughtNfts(response.nftCollections);
      setPurchaseCompleted(true);
      setShoppingCart([]);
    } else {
      showToast("Pagamento não confirmado", "error");
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(pixInfo?.pixCopiaECola!)
  }

const freeNFT = async () => {
  if(order) {
    const response = await executeFreeNFT(order?.order.id);
    if (response.nftCollections) {
      showToast("Compra realizada com sucesso!", "success");
      setBoughtNfts(response.nftCollections);
      setPurchaseCompleted(true);
      setShoppingCart([]);
    } else {
      showToast("Compra não realizada", "error");
    }
  }
};

React.useEffect(() => {
  if(order && calculateTotalPrice(shoppingcart) === 0) {
    freeNFT()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []) 

  return (
    <React.Fragment>
      <S.NavBar
        cartItemCount={shoppingcart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <S.MainContainer container justifyContent={"center"} alignItems={"start"}>
        {!purchaseCompleted && (
          <S.Grid item container xs={12} sm={10} md={8} lg={6}>
            {validatedPayment && (
              <S.Grid item xs={12}>
                <h1>Forma de Pagamento</h1>
                {`Cartão de Crédito: ••••••••••••${creditCardData?.card.number.slice(creditCardData?.card.number.length - 4)}`}
                <br />
              </S.Grid>
            )}
            {/* {validatedAddress && (
              <S.Grid item xs={12}>
                <h1>Endereço de Pagamento</h1>
                {`${billingAddressData?.street}, ${billingAddressData?.number} - ${billingAddressData?.neighborhood} - ${billingAddressData?.city} - ${billingAddressData?.state} - ${billingAddressData?.postal_code}`}
                <br />
                <button onClick={e => {
                  setValidatedAddress(false);
                }}>Alterar</button>
              </S.Grid>
            )} */}

            {!paymentMethod && (
              <>
                <S.SelectGrid container item xs={12}>
                  <S.Grid item xs={12}>
                    <h1>Forma de Pagamento</h1>
                    <RadioGroup
                      row
                      aria-labelledby="Payment Method selection"
                      name="payment-method-radio-button"
                      value={radioValue}
                      onChange={handleRadioChange}
                    >
                      <S.PaymentSelect>
                        <Grid container>
                          <S.RadioButtonLabel value="1" control={<Radio />} label="" />
                          <img src={ccIcon} alt="Credit Card Icon" className="ccIcon"></img>
                          <p className="ButtonTxt">Crédito</p>
                        </Grid>
                      </S.PaymentSelect>
                      <S.PaymentSelect className="rightSelect">
                        <Grid container>
                          <S.RadioButtonLabel value="2" control={<Radio />} label="" />
                          <img src={PixIcon} alt="Pix Icon" className="pixIcon"></img>
                        </Grid>
                      </S.PaymentSelect>
                    </RadioGroup>
                    {radioValue === 1 && (
                      <>
                        <S.Grid container className="InstallmentGrid">
                          <S.Grid item xs={12}>
                            <h1>Parcelamento</h1>
                          </S.Grid>
                          <S.Grid item xs={12}>
                            <FormControl fullWidth>
                              <S.Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={installments}
                                label="Parcelas"
                                onChange={handleChange}
                              >
                                {
                                  installmentsValues().map((item) => {

                                    return <MenuItem key={item.value} value={parseInt(item.value)}>{item.label}</MenuItem>
                                  })
                                }
                              </S.Select>
                            </FormControl>
                          </S.Grid>
                        </S.Grid>
                        <S.Grid item container xs={12} justifyContent={"flex-end"}>
                          <h1>Total: {formatAsBRLCurrency(calculateTotalWithInterest(calculateTotalPrice(shoppingcart), installments))}</h1>
                        </S.Grid>
                      </>
                    )}
                    {radioValue === 2 && !user?.cpf &&
                      <>
                        <S.Grid item xs={12}>
                          <h1>Digite seu CPF</h1>
                        </S.Grid>
                        <S.TextField
                          type="text"
                          placeholder="CPF"
                          value={userCpf}
                          onChange={(e) => setUserCpf(e.target.value)}
                        />
                      </>
                    }
                    <S.Grid item container xs={12} justifyContent={"flex-end"}>
                      <button onClick={() => handleClick()} disabled={false}>Continuar</button>
                    </S.Grid>
                  </S.Grid>
                </S.SelectGrid>
              </>
            )}

            {paymentMethod === 1 && !validatedPayment && (
              <>
                <S.Grid item container>
                  <h1>Dados de Pagamento</h1>
                </S.Grid>
                <S.Grid item xs={12}>
                  <CreditCard
                    props={{
                      name,
                      phone,
                      cpf,
                      creditCardNumber,
                      cvv,
                      expiryDate,
                      handleSavePayment,
                    }}
                  />
                </S.Grid>
              </>
            )}

            {paymentMethod === 2 && (
              <>
                <S.Grid container>
                  <S.Grid container>
                    <h1>Forma de Pagamento:</h1>
                  </S.Grid>
                  <S.Grid container>
                    <button onClick={e => {
                      setPaymentMethod(0);
                    }}>Alterar</button>
                  </S.Grid>
                  <S.Grid item xs={12}>
                    <p>O código Pix será gerado para pagamento é válido por 10 minutos após a finalização do pedido.</p>
                  </S.Grid>
                  <S.Grid item xs={12}>
                    <img src={pixInfo?.urlImagemQrCode} className="QRCode" alt="QR Code de Pix para pagamento"></img>
                  </S.Grid>
                  <S.Grid item xs={12}>
                    <p>Verique se o código QR está claramente visível na tela do seu dispositivo. </p>
                    <Grid container className="pixBorder">
                      <p>{pixInfo?.pixCopiaECola}</p>
                    </Grid>     
                    <button onClick={() => handleCopy()} disabled={false}>Copiar código</button>
                  </S.Grid>
                  <S.Grid item container xs={12} justifyContent={"flex-end"}>
                    <button onClick={() => handlePixStatus()} disabled={false}>Concluir Pedido</button>
                  </S.Grid>
                </S.Grid>
              </>
            )}

            {validatedPayment && !validatedAddress &&
              <>
                <S.Grid item xs={12}>
                  <h1>Endereço de Pagamento</h1>
                </S.Grid>
                <S.Grid item xs={12}>
                  <BillingForm
                    props={{
                      zipCode,
                      state,
                      city,
                      neighborhood,
                      street,
                      number,
                    }}
                    buttonTitle="Continuar"
                    handleActionButton={handleSaveBillingAddress}
                  />
                </S.Grid>
              </>}

            {purchaseFail &&
              <>
                <Grid container justifyContent='center'>
                  <Grid container item md={4} justifyContent='center'>
                    <img src={SadZe} className='SadImg' alt='Mascote Zé com expressão triste'></img>
                  </Grid>
                  <Grid item md={8} textAlign='center'>
                    <S.FailText>FALHA NO PAGAMENTO</S.FailText>
                    <Link to={"/carrinho/"}>
                      <S.RedirectButtons>
                        <Typography className='ReturnButtonTxt'>VOLTAR AO CARRINHO</Typography>
                      </S.RedirectButtons>
                    </Link>
                  </Grid>
                </Grid>
              </>
            }

            {validatedAddress && validatedPayment ? (
              <>
                <S.Grid item container xs={12} justifyContent={"flex-end"}>
                  <h1 className="TotalTxt">Total: {formatAsBRLCurrency(calculateTotalWithInterest(calculateTotalPrice(shoppingcart), installments))}</h1>
                </S.Grid>
                <S.Grid item container xs={12} justifyContent={"flex-end"}>
                  <button onClick={(e) => handleFinishOrder(e)} disabled={false}>Concluir Pedido</button>
                </S.Grid>
              </>
            ) : (
              <></>
            )}
          </S.Grid>
        )}
      </S.MainContainer>
      {purchaseCompleted &&
        (<S.PurchaseCompletedContainer>
          {boughtNfts && <PurchaseCompleted boughtNfts={boughtNfts} />}
        </S.PurchaseCompletedContainer>)}

    </React.Fragment>
  );
};

export default Payment;