import { Tab } from '@mui/material';
import React from 'react';
import { getAnyUserData, getUserCollectibles } from "../../../utils/requests";
import Navbar from "../../../components/Navbar/Navbar";

import * as S from './style'
import { CollectibleType } from '../../../types/CollectibleType';
import CollectibleList from '../../Collectibles/CollectibleList/CollectibleList';
import { UserType } from '../../../types/UserType';

import AvatarPlaceholder from "../../../assets/avatar-placeholder-300x250.png"
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import SadZe from '../../../assets/ze_sad.png'
import EthIcon from '../../../assets/ETH.svg'

import { getInfluencerData } from "../../../utils/requests";
import { useParams, Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt-br';
import { InfluencerType } from '../../../types/InfluencerType';


import ClosersModal from './ClosersModal';
import Closers from '../../../components/ClosersPosts/Closers';


type props = {
  auth: boolean;
  setAuth: (auth: boolean) => void;
}
const PublicProfile: React.FC<props> = ({ auth, setAuth }) => {
  type TabType = 'creations' | 'mycollection' | 'closers';
  //eslint-disable-next-line
  const [currentTab, setCurrentTab] = React.useState<TabType>("creations");
  //eslint-disable-next-line
  const [user, setUser] = React.useState<UserType>({ name: "" } as UserType);
  const [ownedCollec, setOwnedCollec] = React.useState<CollectibleType[]>([]);
  const [influencer, setInfluencer] = React.useState<InfluencerType>({ name: "", nfts: [] } as InfluencerType);
  const [viewingUser, setViewingUser] = React.useState<UserType>({ name: "" } as UserType);
  const [influencerViewing, setInfluencerViewing] = React.useState<InfluencerType>({ name: "" } as InfluencerType);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<number>(0);
/*   const [propertyValue, setPropertyValue] = React.useState<number>(0); */
  const params = useParams();


/*   const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
        setPropertyValue(propertyValue => propertyValue + 1);
      };
  }; */
  React.useEffect(() => {
    let isCancelled = false;
    getAnyUserData(params?.username ?? "").then(res => {
      if (!isCancelled && res) setUser(res);
    })
    return () => { isCancelled = true; };
  }, [params?.username]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setCurrentTab(newValue);
  };

  React.useEffect(() => {
    let isCancelled = false;
    getInfluencerData(params?.username ?? "").then(res => {
      if (!isCancelled && res.username) setInfluencer(res);
    })
    return () => { isCancelled = true; };
  }, [params?.username]);

  React.useEffect(() => {
    let isCancelled = false;
    getUserCollectibles(user?.id ?? "").then((res) => {
      if (!isCancelled && res?.nfts) setOwnedCollec(res?.nfts);
    })
    return () => { isCancelled = true; };
  }, [user?.id]);

  const localUserData = JSON.parse(localStorage.getItem("user")!);

  React.useEffect(() => {
    let isCancelled = false;
      getAnyUserData(localUserData?.username).then(res => {
      if (!isCancelled && res.username) setViewingUser(res);
    })
    return () => { isCancelled = true; };
  }, [localUserData?.username]);
  
  React.useEffect(() => {
    let isCancelled = false;
      getInfluencerData(localUserData?.username).then(res => {
      if (!isCancelled && res.nfts) setInfluencerViewing(res);
    })
    return () => { isCancelled = true; };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  moment.locale("pt-br")

/*   const handleCreationsClick = () => {
    setCurrentTab('creations')
  }

  const mapUserNFTs = viewingUser?.nfts?.map(o => o.creator?.username!); */


/*   const mapInfluencerNFTs = influencerViewing?.nftCollections?.map((o => o.creator?.username!)); */
  
/*   const checkOwnedNFT = mapUserNFTs?.includes(`${params.username}`);

  const [postsObj, setPostsObj] = React.useState<PostsType[]>([])
  const [page, setPage] = React.useState(0);
  const [postsArr, setPostsArr] = React.useState<PostsType[]>([]);

  let posts: Post = postsObj[0]

  const postsToArr = Object.values(posts || {});

  React.useEffect(() => {
    if(checkOwnedNFT || (influencerViewing?.username === influencer.username)) {
      getInfluencerPosts(page, 5, `${params?.username}`).then((res) => {
        if (res[0].length > 0) {
          setPostsObj([ ...postsObj, ...res]);
          res[0].forEach((posts: PostsType) => {
            postsToArr.push(posts)
          });
          setPostsArr(postsToArr);
        }
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]); */


  const closeModal = (close: boolean) => {
    setShowModal(close);
  };

  const flatOwnedArr = ownedCollec.flat();

  return (
    <>
    <Grid container>
      <Navbar auth={auth} setAuth={setAuth} />
      <S.GridContainer
        container
        alignItems="flex-start"
      >
        <Grid container item md={9} justifyContent='center'>
          <S.TabGrid container>
            <S.Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="Abas do perfil"
            >
              <Tab className='profilebuttons' value="creations" label="Minhas Coleções" />
              <Tab className='profilebuttons' value="closers" label="★ Closers" />
              <Tab className='profilebuttons' value="mycollection" label="Galeria" />
            </S.Tabs>
            <S.TabDivider />
          </S.TabGrid>
          <S.BottomGrid container justifyContent='center'>
            {currentTab === "mycollection" &&
              <>
                <Grid container>
                  <S.RedInfoIcon />
                  <Typography>Artes de coleções obtidas por {user?.username ? `@${user?.username}` : `@${influencer?.username}`}</Typography>
                </Grid>
                <S.CollecGrid container className='CollectGrid'>
                  {flatOwnedArr?.length > 0 &&
                    <CollectibleList collectibleList={flatOwnedArr} ownerDetails={true} />
                  }
                  {(!flatOwnedArr || (flatOwnedArr.length <= 0)) &&
                    <h1>Este usuário não possui NFTs...</h1>
                  }
                </S.CollecGrid>
              </>
            }

            {currentTab === "creations" &&
              <>
                {influencer.nfts.length > 0 &&
                  <>
                    <Grid container className='TxtGrid'>
                      <S.RedInfoIcon />
                      <Typography>Artes de coleções criadas por {user?.username ? `@${user?.username}` : `@${influencer?.username}`}</Typography>
                    </Grid>
                    <S.CollecGrid container className='CollectGrid'>
                      <CollectibleList collectibleList={influencer.nfts ? influencer?.nfts : []} />
                    </S.CollecGrid>
                  </>
                }
                {influencer?.nfts?.length === 0 &&
                  <>
                      <Grid container justifyContent='center'>
                          <>
                            <Grid container>
                              <S.RedInfoIcon />
                              <Typography>Artes de coleções criadas por {user?.username ? `@${user?.username}` : `@${influencer?.username}`}</Typography>
                            </Grid>
                            <S.ContentGrid container justifyContent="center">
                              <S.CreationGrid container item xs={2}>
                                <img src={SadZe} className='SadImg' alt='Mascote Zé com expressão triste'></img>
                              </S.CreationGrid>
                              <Grid item xs={6}>
                                <S.CreationText>NENHUMA ARTE CRIADA<br /> POR ESTE USUÁRIO NO<br /> MOMENTO...</S.CreationText>
                                <Link to={"/colecionaveis"}>
                                  <S.RedirectButtons>
                                    <Typography className='ButtonTxt'>VER OUTRAS COLEÇÕES</Typography>
                                  </S.RedirectButtons>
                                </Link>
                              </Grid>
                            </S.ContentGrid>
                          </>
                      </Grid>
                  </>
                }
              </>
            }

            {currentTab === "closers" &&
              <>
                <S.ClosersGrid container justifyContent='center'>
                  {((user.username && viewingUser?.username === user?.username ) || (influencerViewing.username && influencerViewing?.username === influencer?.username))  &&
                    <Grid container justifyContent="center">
                      <Grid container className='ButtonGrid'>
                        {(viewingUser.username === user.username || influencerViewing?.username === influencer.username) &&
                          <S.CreatePostButton onClick={() => setShowModal(true)}>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17 9.71429H9.71429V17H7.28571V9.71429H0V7.28571H7.28571V0H9.71429V7.28571H17V9.71429Z" fill="white"/>
                            </svg>
                            <p className='ButtonTxt'>CRIAR POST</p>
                          </S.CreatePostButton>
                        }
                        {influencer.statusCode ? viewingUser.username !== user.username : influencerViewing?.username !== influencer.username &&
                          <>
                            <S.RedInfoIcon />
                            <p className='IconText'>Segredos e planos publicados por {user?.username ? `@${user?.username}` : `@${influencer?.username}`} 👀</p>
                          </>
                        }
                      </Grid>
                    </Grid>
                  }
{/*                   {!checkOwnedNFT && ((viewingUser.username !== user.username) || influencerViewing?.username !== influencer.username) && 
                    <>
                      <Grid container className='iconText'>
                        <S.RedInfoIcon />
                        <Typography className='closersText'>Segredos e planos publicados por {user?.username ? `@${user?.username}` : `@${influencer?.username}`} 👀</Typography>
                      </Grid>
                        <S.CreationGrid container item xs={4} lg={3}>
                        <img src={PointingZe} className='PointingImg' alt='Mascote Zé apontando para a direita'></img>
                      </S.CreationGrid>
                      <Grid item xs={7} lg={5}>
                        <S.ClosersText>ADQUIRA CRIAÇÕES DE<br /> {user?.username ? `@${user?.username}` : `@${influencer?.username}`} PARA PODER<br /> ACESSAR ESTA PÁGINA...</S.ClosersText>
                        <S.RedirectButtons onClick={handleCreationsClick}>
                          <Typography className='ButtonTxt'>ACESSE AS CRIAÇÕES</Typography>
                        </S.RedirectButtons>
                      </Grid>
                    </>
                  } */}
                </S.ClosersGrid>
                <S.ClosersContainer container>
                  <Closers key={refresh} creatorUsername={user?.username ? user?.username : influencer?.username} refresh={setRefresh}/>
                </S.ClosersContainer>
              </>
            }
          </S.BottomGrid>
        </Grid>

        <Grid item md={3} lg={2}>
          <S.RightGrid container alignItems='center'>
            {user?.avatar 
            ? <img src={user.avatar === null ? AvatarPlaceholder : user.avatar} alt='Avatar do usuário'></img>
            : <img src={influencer.avatar === null ? AvatarPlaceholder : influencer.avatar} alt='Avatar do usuário'></img>
            }
            <Grid container justifyContent='center'>
              <S.RightGridText className='RightGridUsername'>{user?.username ? `@${user?.username}` : `@${influencer?.username}`}</S.RightGridText>
              {influencer.username &&
                <S.Verified />
              }     
            </Grid>
            <Grid container justifyContent='center' flexDirection='row'>
              <img alt='Ícone do Ethereum' src={EthIcon} className='EthIcon' />
              <p className='WalletText'>{user?.wallet?.address ? `${user.wallet?.address.slice(0, 6)}...${user.wallet?.address.slice(-4)}` : null}</p>
            </Grid>
            <Grid container>
              <Grid container justifyContent='space-evenly'>
                <S.RightGridText className='UserInfoNumbers'>{user?.nfts ? user?.nfts.length : "0"}</S.RightGridText>
                <S.RightGridText className='UserInfoNumbers'>{influencer?.nfts ? influencer?.nfts.length : "0"}</S.RightGridText>
              </Grid>
              <Grid container className='TextGrid' justifyContent='space-evenly' >
                <S.RightGridText className='UserInfoLabel'>Galeria</S.RightGridText>
                <S.RightGridText className='UserInfoLabel'>Criações</S.RightGridText>
              </Grid>
              <Grid container justifyContent='center'>
                <S.RightGridText className='UserBio'>
                  BIO<br />
                  membro desde {user.createdAt ? moment(user?.createdAt).format('LL') : moment(influencer?.createdAt).format('LL')}<br /><br />
                </S.RightGridText>
              </Grid>
            </Grid>
          </S.RightGrid>
        </Grid>
      </S.GridContainer>
      </Grid>
      {showModal &&
        <ClosersModal avatar={user?.avatar ? user?.avatar : influencer?.avatar} closeFunc={closeModal} influencerUsername={influencer.username ? influencer.username :  undefined} userUsername={user.username ? user.username : undefined} refresh={setRefresh} />
      }
    </>
  )
}

export default PublicProfile