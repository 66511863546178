import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

//image saver import
/* import FileSaver from 'file-saver'; */

//mui imports
import Grid from "@mui/material/Grid";
/* import FileDownloadIcon from '@mui/icons-material/FileDownload'; */
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useShoppingCart from "../../hooks/useShoppingCart";
import { formatAsBRLCurrency, scrollToTop } from "../../utils/sharedfunctions";

//import styled toast
import * as T from "../../components/CustomComponents/ToastContainer/style";
import showToast from "../../components/CustomComponents/ToastContainer/toast";

//styles
import * as S from "./style";
//types
import { CartItem } from "../../types/CartItemType";
import { CollectibleType } from "../../types/CollectibleType";
import { OwnersType } from "../../types/OwnerTypes";

import bgBase from "../../assets/bgBase.svg";
import { getCollectible, getOwnerData, getUserData } from "../../utils/requests";

import AvatarPlaceholder from "../../assets/avatar-placeholder-300x250.png";
import { UserType } from "../../types/UserType";
import { OnSaleType } from "../../types/OnSaleType";

type CollectibleDetailsProps = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const CollectibleDetails: React.FC<CollectibleDetailsProps> = ({
  auth,
  setAuth,
}) => {
  type LocationState = {
    collectible: CollectibleType;
    ownerDetails?: boolean;
    listed?: OnSaleType;
  };
  const params = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const [shoppingCart, setShoppingCart] = useShoppingCart();
  //eslint-disable-next-line

  const requestAndSetCollectible = async () => {
    const response = await getCollectible(params?.collectionName ?? "");
    if (response?.nfts) {
      let collectible = response.nfts[0];
      setCollectible(collectible);
    }
  };

  const [collectible, setCollectible] = React.useState<
    CollectibleType | undefined
  >();

  React.useEffect(() => {
    if (!collectible) {
      requestAndSetCollectible();
    }
    //eslint-disable-next-line
  }, [collectible]);

  const addToShoppingCart = async (item: CollectibleType) => {

    let itemExists = shoppingCart.find((cartItem: CartItem) => {
      return item.url_title === cartItem.url_title;
    });
    if (itemExists) {
      console.log("item already exists");
      let newShoppingCart = shoppingCart.map((cartItem: CartItem) => {
        if (cartItem.url_title === item.url_title) {
          if (cartItem.quantity + 1 > item.available) {
            showToast(
              "Não foi possível adicionar este nft ao carrinho. O nft não está disponível ou já foram adicionados todos os disponíveis.",
              "error"
            );
          } else {
            cartItem.quantity += 1;
            cartItem.subtotal = cartItem.quantity * cartItem.price;
          }
        }
        return cartItem;
      });
      showToast("Item adicionado ao carrinho!", "success");
      setTimeout(() => {
        navigate("/carrinho");
        scrollToTop();
      }, 3000);
      setShoppingCart(newShoppingCart);
    } else {
      if (item.available > 0) {
        const newCartItem: CartItem = {
          url_title: item.url_title,
          imageUrl: item.imageUrl,
          title: item?.title ?? "",
          quantity: 1,
          price: item.value,
          subtotal: item.value,
          available: item.available,
          total: item.total,
        };
        showToast("Item adicionado ao carrinho!", "success");
        setTimeout(() => {
          navigate("/carrinho");
          scrollToTop();
        }, 3000);
        setShoppingCart([...shoppingCart, newCartItem]);
      } else {
        showToast(
          "Não foi possível adicionar este nft ao carrinho. O nft não está disponível ou já foram adicionados todos os disponíveis.",
          "error"
        );
      }
    }
  };

  /*   const imgLink = collectible?.imageUrl;
  
    const imgDownload = () => {
      FileSaver.saveAs(`${imgLink}`, "NFT.jpg")
    }; */

  const [owner, setOwner] = React.useState<Array<OwnersType>>()

  React.useEffect(() => {
    if (!owner) {
      requestAndSetOwner();
    }
    //eslint-disable-next-line
  }, [owner, collectible]);

  const requestAndSetOwner = async () => {
    const response = await getOwnerData(collectible?.url_title ?? "");
    if (response) {
      let owner = response[0].owners;
      setOwner(owner);
    }
  };

  const [user, setUser] = React.useState<UserType>({} as UserType);

  const requestAndSetUser = async () => {
    const localUserData = JSON.parse(localStorage.getItem("user")!);
    if (localUserData) {
      const response = await getUserData(localStorage.getItem("token") ?? "", localUserData.id);
      setUser(response);
    }
  };

  React.useEffect(() => {
    requestAndSetUser()
  }, [user?.id]);

  //Remove duplicate avatars (multiple nft owners)
  const mapID = owner?.map(o => o.oobId!)
  const filteredOwners = owner?.filter(({ oobId }, index) => !mapID?.includes(oobId, index + 1))

  const [infCollectibles, setinfCollectibles] = React.useState<CollectibleType | undefined>(undefined);

  const requestAndTestCollectible = async () => {
    if (!collectible?.creator) {
      const response = await getCollectible(params?.collectionName ?? "");
      if (response?.nfts) {
        let collectibles = response.nfts[0];
        setinfCollectibles(collectibles);
      }
    }
  };

  React.useEffect(() => {
    requestAndTestCollectible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <S.NavBar
        cartItemCount={shoppingCart.length}
        auth={auth}
        setAuth={setAuth}
      />
      <S.MainContainer
        container
        justifyContent={"center"}
        alignItems={"center"}
      // bgCover={collectible?.imageUrl ?? ""}
      >
        <Grid
          className={"bgCover"}
          item
          container
          xs={12}
          md={6}
          style={{
            backgroundImage: `url(${bgBase})`,
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {!collectible?.videoUrl &&
            <img src={collectible?.imageUrl ?? ""} alt={collectible?.title ?? ""} />
          }
          {collectible?.videoUrl &&
            <video
              width="100%"
              height="100%"
              autoPlay
              loop
              playsInline
              muted
            >
              <source src={collectible?.videoUrl ?? ""} type="video/mp4" />
            </video>
          }
        </Grid>
        <Grid className={"details"} item container xs={12} md={6}>
          <h1>{collectible?.title ?? ""}</h1>
          {state.listed &&
            <Grid container flexDirection="row" style={{ gap: 10 }}>
              <p className="collection-by">por </p>
              <p className="collection-username" onClick={() => navigate(`/profile/${collectible?.creator?.username}`)}> {collectible ? collectible?.creator?.name : null}</p>
              <S.Verified />
            </Grid>
          }
          <br />
          {
            collectible?.description?.split("\\n").map((paragraph, index) => {
              return paragraph !== '' ? <h2 key={index}>{paragraph}</h2> : null;
            })
          }
          <br />
          {!state.listed &&
            <>
              <h3>Criador:</h3>
              <br />
              <Link to={`/profile/${collectible?.creator ? collectible?.creator?.username : infCollectibles?.creator?.username}`}><h2>{collectible?.creator ? collectible?.creator?.name : infCollectibles?.creator?.name}</h2></Link>
              <br />
            </>
          }
          {state.listed &&
            <>
              <h3>Vendedor</h3>
              <br />
              <Link to={`/profile/${state.listed.user.username}`}><h2 >{state.listed.user.name}</h2></Link>
              <br />
            </>
          }
          <Grid item container justifyContent={"center"} xs={12}>
            <Grid item container className={state?.ownerDetails ? "seeCollecWrapper" : "wrapper"}>
              {state?.ownerDetails === true &&
                <Grid className={"seeCollectible"} item xs={12}>
                  {collectible && (
                    <button
                      onClick={() => {
                        navigate(`/colecionaveis/${collectible?.collectionName}`);
                      }}
                    >
                      Ver colecionável
                      <ShoppingCartIcon />
                    </button>
                  )}
                </Grid>
              }
              {!state?.ownerDetails &&
                <>
                  <Grid className={"collectibleInfo"} item xs={12}>
                    <span color="red">Disponível:&nbsp;<b>{collectible?.available}/{collectible?.total}</b> </span>
                    <div className="divider" />
                    <span color="red">
                      Valor:&nbsp; <b>{formatAsBRLCurrency(collectible?.value ?? 0)}</b>
                    </span>
                  </Grid>
                  <Grid className={"addToCart"} item xs={12}>
                    {collectible && (
                      <button
                        onClick={() => {
                          addToShoppingCart(collectible);
                          if (!auth) navigate("/cadastrar");
                        }}
                      >
                        Adicionar ao carrinho
                        <ShoppingCartIcon />
                      </button>
                    )}
                  </Grid>
                </>
              }

              {/*               { collectibles.length > 0 && collectibles.find(nftName => nftName.collectionName === collectible?.collectionName) ? 
                  <Grid className="downloadJpg" item xs={12}>
                    <button onClick={imgDownload}>
                      Baixar JPG
                      <FileDownloadIcon />
                    </button>
                  </Grid> :
                null
              } */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container flexDirection='column' className="ownerTxt">
          <div><h1>Donos</h1></div>
          <S.OwnerDivider />
          <Grid
            container
            justifyContent='initial'
            justifyItems='center'
            spacing={1}
          >
            {filteredOwners?.map((item: OwnersType, index: number) => {
              const handleLinkClick = (event: any) => {
              }
              return (
                <S.OwnerGrid item md={2} key={index}>
                  <Link to={`/profile/${item.username}`} onClick={handleLinkClick}>
                    <img
                      className="ownerAvatar"
                      src={item.avatar === null ? AvatarPlaceholder : item.avatar}
                      alt="Avatar de Dono do NFT"
                    />
                  </Link>
                  <p>{item.name.split(" ")[0]}</p>
                </S.OwnerGrid>
              )
            })}
          </Grid>
        </Grid>
      </S.MainContainer>
    </>
  );
};

export default CollectibleDetails;
