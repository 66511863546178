import React from 'react'
import styled from 'styled-components';
// import { Skeleton, Grid as MuiGrid } from '@mui/material';
// import { BillingAddressType } from '../../../types/BillingAddressType';

import Input from '../../../components/Input/input';
import showToast from '../../../components/CustomComponents/ToastContainer/toast';
import { ValidatedField } from '../../../types/ValidatedField';
import { Alert, Button, Grid as MuiGrid } from '@mui/material';
import * as T from "../../../components/CustomComponents/ToastContainer/style";
// import { saveBillingAddress, setUserData } from '../../../utils/requests';
import ProfileForm from './ProfileForm/ProfileForm';
import { UserType } from '../../../types/UserType';
import { setUserData } from '../../../utils/requests';
import { convertDate, maskCellphone, validateWalletAddress } from '../../../utils/sharedfunctions';
import { TouchedType } from '../../../types/TouchedType';
import moment from 'moment';
/* import jwtDecode from 'jwt-decode'; */

type ProfileProps = {
  user: UserType;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
}

const Profile: React.FC<ProfileProps> = ({ user, setUser }) => {
  const name = React.useState<ValidatedField>({
    value: user.name ?? "",
    valid: true,
    message: "Por favor, digite um nome válido.",
  });

  const email = React.useState<ValidatedField>({
    value: user.email ?? "",
    valid: true,
    message: "Por favor, digite um email válido.",
  });

  const phone = React.useState<ValidatedField>({
    value: maskCellphone(user?.phone ?? ""),
    valid: true,
    message: "Por favor, digite um telefone válido.",
  });

  const gender = React.useState<ValidatedField>({
    value: user.gender ?? "Selecione",
    valid: true,
    message: "Por favor, selecione um gênero.",
  });

  const birthdate = React.useState<ValidatedField>({
    value: convertDate(user?.birthDate) ?? "",
    valid: true,
    message: "Por favor, digite uma data de nascimento no formado dd/mm/aaaa.",
  });

/*   const cpf = React.useState<ValidatedField>({
    value: user.cpf ?? "",
    valid: true,
    message: "Por favor, digite um cpf válido",
  }); */

  const username = React.useState<ValidatedField>({
    value: user.username ?? "",
    valid: true,
    message: "Por favor, digite um nome de usuário válido.",
  });

  const wallet = React.useState<ValidatedField>({
    value: user.wallet?.address ?? "",
    valid: true,
    message: "Por favor, digite uma carteira válida.",
  });

  const touched = React.useState<TouchedType[]>([]) 

/*   const token = localStorage.getItem("token");
  const decoded: UserType = jwtDecode(token ?? "");
  delete decoded.exp
  delete decoded.iat */

  // const handleSaveBillingAddress = async () => {
  //   const fields = [zipCode, neighborhood, street, number];
  //   var continueSaving = true;
  //   fields.forEach(field => {
  //     if (!field[0].valid && field[0].value !== "") {
  //       continueSaving = false;
  //     }
  //   })
  //   if (continueSaving) {
  //     await saveBillingAddress(
  //       street[0].value,
  //       number[0].value,
  //       neighborhood[0].value,
  //       city[0],
  //       state[0],
  //       zipCode[0].value
  //     ).then(res => {
  //       res?.error ? showToast("Ocorreu um erro ao salvar seu endereço, tente novamente.", "error") : showToast("Endereço salvo com sucesso.", "success")
  //     })
  //   } else {
  //     showToast("Dados informados inválidos. Verifique os campos e tente novamente.", "error")
  //   }
  // };
const isTouched  = (field:string) =>{
  if(touched[0].find(string => string === field)){
    return true
  }
  return false
}
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const [validated, setValidated] = React.useState<boolean>(false);

  const confirmWalletChange = () => {
    if(isTouched("wallet")) {
     validateWalletAddress(wallet[0].value) ? (() => { setConfirmed(true); setValidated(true) })() : showToast("Carteira Inválida", "error")
    };
  };

  const alertCall = () => {if(isTouched("wallet")) setShowAlert(true)}; 

  const isEmpty = (anObject: any) => {
    return !Object.keys(anObject ?? {}).length;
  };

  let userData = {} as UserType;

  const handleUpdateProfile = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      if(isTouched("name") )       userData.name       = name[0].value;
      if(isTouched("email") )      userData.email      = email[0].value;
      if(isTouched("phone") )      userData.phone      = phone[0].value;
      if(isTouched("birthdate") )  userData.birthDate  = moment(birthdate[0].value, "DD/MM/YYYY").format("YYYY-MM-DDT00.00.00.000Z");
/*       if(isTouched("cpf") )        userData.cpf        = cpf[0].value; */
      if(isTouched("username") )  userData.username    = username[0].value;
      if(isTouched("wallet") && confirmed && validated ) {
        let walletAdd = { address: wallet[0].value };
        userData.wallet = walletAdd;
      };
      if(isEmpty(userData) === false ) {
        setUserData(token, userData as UserType).then(res => {
          if(res.email) {
            showToast("Dados atualizados com sucesso!","success");
            setUser(res);
          } else {
            showToast("Ocorreu um erro ao atualizar seus dados. Por favor, atualize a página e tente novamente","error");
          } 
        });
      } else if (!confirmed && isTouched("wallet")) showToast("Por favor, verifique sua carteira cuidadosamente e clique em confirmar.","warning");
      else showToast("Ocorreu um erro ao atualizar seus dados, verifique suas informações.","error")
    }
  };

  const props = {
    name,
    email,
    phone,
    gender,
    birthdate,
    username,
    wallet,
    touched,
/*     cpf */
  };

  return (
    <ProfileContainer item >
      {showAlert &&
        <Alert
          severity="error"
          action={
            <>
            <Button color="inherit" size="small" onClick={() => handleUpdateProfile()}>
              Confirmar
            </Button>
            <Button color="inherit" size="small" onClick={() => {setShowAlert(false); setConfirmed(false); setValidated(false); /* cleanUserData(); */}}>
              Cancelar
            </Button>
            </>
          }
        >
          Confirme sua carteira antes de enviar
        </Alert>
      }
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <Grid container justifyContent={'center'}>
        <ProfileForm
          props={props}
          buttonTitle={"Salvar"}
          handleActionButton={handleUpdateProfile}
          touched={touched}
        />
        <Grid item container justifyContent={'end'} px={0}>
          <Grid item px={0}>
            { user && <button onClick={() => {alertCall(); confirmWalletChange(); handleUpdateProfile();}}>Salvar</button>}
          </Grid>
        </Grid>
      </Grid>
    </ProfileContainer>
  )
}

export default Profile;

export const ProfileContainer = styled(MuiGrid)`
    padding: 10px 0px;
    @media (min-width: 440px) {
      width: 440px;
    }
    @media (min-width: 570px) {
      width: 570px;
    }
    @media (min-width: 600px) {
      width: 600px;
    }
    @media (min-width: 990px) {
      width: 780px;
    }
`
export const Grid = styled(MuiGrid)`
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  padding: 0px 20px;
  animation: fadeIn 0.300s;
  button {
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    align-items: center;

    color: #ffffff;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 10px;
    border-style: none;
    padding: 5px 30px;
    margin: 10px 0px;
    cursor:pointer;
  }
`

export const TextField = styled(Input)`
  .MuiInputBase-input {
    border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important;
    padding: 15px 15px;
    transition: 250ms;
    border: 1px solid rgb(46,46,56) !important;
    /* height:25px ; */
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
  }
  [aria-invalid="true"] {
    border-style: solid !important;
    border-width: 1px !important;
  }
  [name="password"] {
    margin-top: 0px;
  }
`;

export const ConfirmAlert = styled(Alert)`
  background-color: rgb(46, 46, 56);
  color: white
`;