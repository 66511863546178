/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BgBase from "../../../assets/bgBase.svg";

import * as S from "./style";

import { CollectibleType } from "../../../types/CollectibleType";


type props = {
  collectibleList: CollectibleType[];
  xs?: number
  md?: number
  lg?: number
  showOwned?: boolean
  ownerDetails?: boolean
  unavailable?: boolean
};

const CollectibleList: React.FC<props> = ({ collectibleList, xs, md, lg, showOwned, ownerDetails, unavailable }) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnMouseOver = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.currentTarget.play();
  };

  const handleOnMouseOut = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.currentTarget.pause();
  };
  return (
    <S.MainContainer container item xs={12} spacing={3}>
      {collectibleList?.map((collectible) => {
        // const video = React.useRef<HTMLVideoElement>(null);
        return (
          <S.CardContainer
            item
            container
            xs={xs ? xs : 6}
            md={md ? md : 4}
            lg={lg ? lg : 3}
            onClick={() => {
              scrollToTop();
              navigate("/colecionaveis/" + collectible.url_title, {
                state: { collectible: collectible, ownerDetails: ownerDetails },
              });
            }}
            style={{ 'backgroundImage': `url(${BgBase})` }}
            // onLoadedData={() => {
            //   if (video.current) {
            //     video.current.play();
            //     video.current.currentTime = 4;
            //     video.current.pause();
            //   }
            // }}
            // onMouseEnter={() => {
            //   video.current && video.current.play();
            // }}
            // onMouseLeave={() => {
            //   if (video.current) {
            //     video.current.currentTime = 4;
            //     video.current && video.current.pause();
            //   }
            // }}
            key={collectible.id}
          >
            {showOwned &&
              <S.QuantityFab color="primary" aria-label="add">
                <p>{collectible.available}</p>
              </S.QuantityFab>
            }
            {!collectible?.videoUrl &&
              <div className={`card ${unavailable && collectible?.available === 0 && 'unavailable'}`} style={{ 'backgroundImage': `url("${collectible.imageUrl}")` }}>
                <S.SpaceFiller className={`${unavailable && collectible?.available === 0 ? 'unavailableFiller' : 'defaultFiller'}`} />
                {unavailable && collectible.available === 0 &&
                  <S.UnavailableGrid container justifyContent="center">
                    <p>ESGOTADO</p>
                  </S.UnavailableGrid>
                }
                <S.Divider className={'details'} />
                <S.CardInfoTest
                  className={'details'}
                // item container xs={12} rowSpacing={0}

                >
                  <Grid item container xs={12} alignItems={"center"}>
                    <Grid item xs={12}><h2>{collectible.title}</h2></Grid>
                    <Grid item xs={10}>
                      <h3>
                        <Link to={"/colecionaveis/" + collectible.url_title}>
                          Mais detalhes
                        </Link>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <S.ArrowRightIcon />
                    </Grid>
                  </Grid>

                </S.CardInfoTest>
              </div>
            }
            {collectible?.videoUrl &&
              <div className={'card'} /* style={{ 'backgroundImage': `url("${collectible.imageUrl}")` }} */ >
                {/* <img src={collectible.imageUrl} style={{ margin: "30px" }} alt={"Imagem do nft"} /> */}

                <div style={{ 'width': '100%', 'aspectRatio': '595/490' }}>
                  <video width="100%" height="100%" muted loop onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
                    <source src={collectible?.videoUrl ?? ""} type="video/mp4"></source>
                  </video>
                </div>
                <S.Divider className={'details'} />
                <S.CardInfoTest
                  className={'details'}
                // item container xs={12} rowSpacing={0}

                >
                  <Grid item container xs={12} alignItems={"center"}>
                    <Grid item xs={12}><h2>{collectible.title}</h2></Grid>
                    <Grid item xs={10}>
                      <h3>
                        <Link to={"/colecionaveis/detalhes/" + collectible.url_title}>
                          Mais detalhes
                        </Link>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <S.ArrowRightIcon />
                    </Grid>
                  </Grid>
                </S.CardInfoTest>
              </div>
            }
          </S.CardContainer>
        );
      })}
    </S.MainContainer>
  );
};

export default CollectibleList;
