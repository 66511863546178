import styled from "styled-components";
import { Grid as MuiGrid, Divider } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import VerifiedIcon from '@mui/icons-material/Verified';

export const NavBar = styled(Navbar)`
  && {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 13%,
      rgba(0, 212, 255, 0) 100%
    );
    z-index: 500;
  }
`;
export const MainContainer = styled(MuiGrid)`
  padding: 80px 30px 50px 30px;
  @media (min-width: 575px) {
    padding-top: 80px;
  }
  @media (min-width: 900px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  @media (min-width: 900px) {
    padding-bottom: 90px;
    padding-top: 90px;
  }
  .bgCover {
    height: 80vw;
    background: url("https://storage.opensea.io/files/6512966cf79df2a542b8434fc502bdc2.mp4#t=0.001");

    background-color: rgba(0, 0, 0, 0) !important;
    background-size: cover;
    background-position: 50% 50%;
    img{
      height:100%;
      text-align:center;
      border-radius:10px;
    }
    @media (min-width: 900px) {
      max-height: 85vh !important;
      img{
        height:80%;
      }
    }
    
  }
  .details {
    text-align: center;
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    padding-right: 20px;
    padding-left: 20px;
    @media (min-width: 575px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media (min-width: 900px) {
      text-align: left;
    }
    @media (min-width: 1200px) {
      padding-right: 100px;
      padding-left: 100px;
    }

    h1 {
      width: 100%;
      text-align: center;
      font-size: 50px;
      line-height: 60px;
      @media (min-width: 900px) {
        margin: 0px;
        font-size: 45px;
        text-align: left;
      }
    }
    h2 {
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      width:100%;
      @media (min-width: 900px) {
        font-size: 20px;
      }
    }
    h3 {
      margin: 0px;
      font-size: 26px;
      line-height: 31px;
      width:100%;
      text-align:center;
      @media (min-width: 900px) {
        font-size: 23px;
        text-align: left;
      }
    }
    h4 {
      color: rgba(255, 255, 255, 0.75) !important;
      display: inline-flex;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (min-width: 900px) {
        font-size: 16px;
        margin: 0px;
      }
      a {
        font-family: Barlow Semi Condensed;
        font-style: normal;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
  .wrapper {
    width: 320px;
    text-align: left;
    margin-top: 30px;
    background: rgb(75, 75, 75);
    background: linear-gradient(
      90deg,
      rgba(75, 75, 75, 1) 0%,
      rgba(36, 36, 36, 1) 100%
    );
    border-radius: 10px;
    span {
      display: flex;
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      color: rgba(179, 179, 179, 0.65) !important;
      padding-left: 23px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .divider {
      height: 2px;
      width: 274px;
      background-color: rgba(255, 255, 255, 0.3);
      margin-left: 23px;
      margin-right: 23px;
    }
  }
  .seeCollecWrapper {
    width: 240px !important;
    text-align: left;
    margin-top: 30px;
    background: rgb(75, 75, 75);
    background: linear-gradient(
      90deg,
      rgba(75, 75, 75, 1) 0%,
      rgba(36, 36, 36, 1) 100%
    );
    border-radius: 10px;
    span {
      display: flex;
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      color: rgba(179, 179, 179, 0.65) !important;
      padding-left: 23px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .collectibleInfo {
    margin-top: 1px;
    margin-left: 1px;
    width: 318px;
    height: 114px;
    margin-bottom: 0px;
    background: rgb(38, 38, 38);
    border-radius: 9px 9px 0px 0px;
  }
  .addToCart {
    padding: 0px;
    margin: 0px;
    width: 320px !important;
    height: 58px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 0px 0px 10px 10px;
    button {
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 31px;
      background: none;
      color: white;
      border: none;
      padding: 14px;
      padding-left: 22px;
      :hover {
        cursor: pointer;
      }
    }
    svg {
      margin-left: 40px;
    }
  }
  .seeCollectible {
    padding: 0px;
    margin: 0px;
    width: 200px !important;
    height: 58px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 10px 10px 10px 10px;
    button {
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 31px;
      background: none;
      color: white;
      border: none;
      padding: 14px;
      padding-left: 22px;
      :hover {
        cursor: pointer;
      }
    }
    svg {
      margin-left: 10px;
    }
  }
  .downloadJpg {
    padding: 0px;
    margin: 0px;
    width: 320px !important;
    height: 58px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 0px 0px 10px 10px;
    button {
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 31px;
      background: none;
      color: white;
      border: none;
      padding: 14px;
      padding-left: 22px;
      :hover {
        cursor: pointer;
      }
    }
    svg {
      margin-left: 147px;
    }
  }

  .ownerTxt {
    text-align: center;
    width: 100%;
  }

  .collection-username{
    color: #E22B00;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin: 10px 0;
    cursor: pointer;
  }

  .collection-by{
    font-size: 24px;
    margin-top: 15px;
  }
`;

export const OwnerGrid = styled(MuiGrid)`
  text-align: center;

  .ownerAvatar {
    width: 130px;
    height: 130px;
    border-radius: 30px !important;
    margin-top: 10px;
  }
    p {
      font-family: Barlow Semi Condensed;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      color: #E22B00;
      margin: 0;
    }
    img:hover {
      cursor: pointer;
    }
`

export const OwnerDivider = styled(Divider)`
  border-width: 2px !important;
  margin-bottom: 10px !important;
  border-image: linear-gradient(90deg ,#fb8404 0%, #fe1e14 100%) 30;
`

export const Verified = styled(VerifiedIcon)`
  padding-top: 20px;
  color: #E22B00;
`