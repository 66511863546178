import styled from "styled-components";
import navbar from "../../components/Navbar/Navbar";
import { Button, Grid as MuiGrid, Select as MuiSelect, Typography } from "@mui/material";
import Input from "../../components/Input/input";

import {
  // FormControl,
  FormControlLabel,
  FormLabel,
  // Radio,
  RadioGroup,
} from "@mui/material";

export const NavBar = styled(navbar)``;
export const MainContainer = styled(MuiGrid)`
  padding-top: 0px;
  padding-bottom: 70px;
  @media (min-width: 900px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`;

export const Grid = styled(MuiGrid)`
  font-family: Barlow Semi Condensed;
  font-style: normal;
  padding: 10px 20px;
  h1 {
    margin: 0px;
    padding: 0px;
    font-size: 26px;
  }
  .active {
    border-color: rgb(253, 112, 11) !important;
    color: rgb(253, 112, 11) !important;
    background-color: black !important;
    cursor: pointer !important;
  }
  button {
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    align-items: center;
    height: 60px;

    color: #ffffff;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
    border-radius: 10px;
    border-style: none;
    padding: 5px 50px;
    margin: 10px 0px;
    cursor: pointer;
    :disabled{
      background: darkgrey;
    }
  }
  form{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .StripeElement{
    width:100% ;
  }
  .p-FieldLabel{
    color:white !important;
  }
  .QRCode{
    width: 469px;
    height: 469px;  
      @media (max-width: 600px) {
        width: 250px !important;
        height: 250px !important;
      }
  }
  .ButtonDiv{
    opacity: 1 !important;
    color: white
  }
  .ButtonTxt{
    color: white;
    display: inline-block;
    padding-left: 5px;
    font-size: 20px;
    margin-top: 30px;
  }
  .ccIcon{
    width: 49.34px;
    height: 39.89px;
    padding-left: 5px;
    padding-top: 20px;
  }
  .ButtonPad{
    padding-top: 20px;
  }
  .pixIcon{
    width: 116px;
    height: 41px;
    margin-top: 20px;
  }
  .css-hyxlzm{
    color: lightgray;
  }
  .rightSelect{
    margin-left: 15px;
      @media (max-width: 629px) {
      margin-left: 0;
    }
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #fb8404;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  .pixBorder{
    border: 3px solid;
    border-radius: 45px !important;
    border-image: linear-gradient(90deg ,#fb8404 0%, #fe1e14 100%) 30;
    padding: 10px;
    overflow-wrap: anywhere;
  }

  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }

  .TotalTxt {
    padding-top: 400px;
  }

  .InstallmentGrid {
    margin-left: -40px;
  }
`;

export const TextField = styled(Input)`
  .MuiInputBase-input {
    border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important;
    padding: 15px 15px;
    transition: 250ms;
    border: 1px solid rgb(46,46,56) !important;
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
  }
  [aria-invalid="true"] {
    border-style: solid !important;
    border-width: 1px !important;
  }
  /* @media (min-width: 320px) {
    width: 320px !important;
  }
  @media (min-width: 420px) {
    width: 420px !important;
  } */

  [name="password"] {
    margin-top: 0px;
  }
`;

export const Formlabel = styled(FormLabel)`
  color: white !important;
`;

export const PaymentOption = styled.div`
  && {
    color: grey;
    border-radius: 15px;
    border-width: 1px;
    border-color: grey;
    border-style: solid;
    text-align: center;
    height: 60px;
    line-height: 55px;
    background-color: rgb(46, 46, 56);
    font-size: 25px;
    :hover {
      cursor: pointer;
      border-color: rgb(253, 112, 11);
      color: rgb(253, 112, 11);
      background-color: black;
    }
  }
`;
export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 0px;
`;
export const Select = styled(MuiSelect)`
  && {
    background-color: rgb(46, 46, 56);
    color: white;
    font-size: 20px;
    padding-left: 5px;
    border-radius: 10px;
    border-width: 0px !important;
    .MuiOutlinedInput-notchedOutline {
      border-style: none !important;
    }
    .MuiSelect-icon {
      color: white !important;
    }
  }
`;
export const PurchaseCompletedContainer = styled(MuiGrid)`
  width:100%;
  margin:auto;
  margin-top:80px;
  @media (min-width:900px){
    width:900px;
  }
  @media (min-width:1200px){
    width:1200px;
  }
`

export const PaymentSelect = styled(MuiGrid)`
  width: 194.64px;
  height: 82px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  flex-direction: row;
  display: inline-block;
  margin-top: 20px;
`

export const RadioButtonLabel = styled(FormControlLabel)`
  margin-left: 10px !important;
  margin-bottom: 25px;
  margin-right: 0 !important;
  padding-top: 20px;
`
export const RedirectButtons = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 110px !important;
  margin-bottom: 50px !important;
    
  @media (min-width: 700px) {
      width: 417px;
      height: 80px !important;
      margin-top: 25px !important;
   }

    .ReturnButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }
`
export const FailText = styled(Typography)`
  font-size: 60px !important;
  line-height: 1 !important;
  margin-top: 120px !important;
`

export const SelectGrid = styled(MuiGrid)`
  font-family: Barlow Semi Condensed;
  font-style: normal;
  padding: 10px 20px;
    @media (max-width: 899px) {
      margin-top: 40px !important;
    }
`
export const PaymentRadioGroup = styled(RadioGroup)`
  @media (max-width: 629px) {
  flex-direction: column;
  display: grid;
  }
`