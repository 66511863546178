import styled from "styled-components";
import { Badge as MuiBadge } from "@mui/material";

export const Badge = styled(MuiBadge)`
  color:white;
  span {
    background-color: rgb(38, 38, 38) !important;
  }
  button {
    padding: 0px 15px !important;
  }
  .active button:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
