import React from "react";
import { BillingFormType } from "../../../types/BillingFormType";
import { getAddressByZipCode, validateNeighbourhood, validateNumber, validateStreet, validateZipCode } from "../../../utils/sharedfunctions";
import * as S from "../style"


const BillingForm: React.FC<BillingFormType> = ({ props, handleActionButton, buttonTitle }) => {
  // { props }
  const [zipCode, setZipCode] = props.zipCode;
  const [street, setStreet] = props.street;
  const [number, setNumber] = props.number;
  const [neighborhood, setNeighborhood] = props.neighborhood;
  const [city, setCity] = props.city;
  const [state, setState] = props.state;

  React.useEffect(() => {
    if (validateZipCode(zipCode.value) && zipCode.valid) {
      getAddressByZipCode(zipCode.value).then((address) => {
        if (address.logradouro) setStreet({
          value: address.logradouro,
          valid: true,
          message: "A rua precisa ter entre 5 e 40 caracteres",
        });
        if (address.bairro) setNeighborhood({
          value: address.bairro,
          valid: true,
          message: "O bairro precisa ter entre 5 e 40 caracteres.",
        });
        if (address.localidade) setCity(address.localidade);
        if (address.uf) setState(address.uf);
        if (address.erro) {
          // showToast("O CEP informado não existe.", "error");
          setZipCode({
            value: zipCode.value,
            message: "O CEP informado não existe.",
            valid: false
          })
        }
      });
    }
    //eslint-disable-next-line
  }, [zipCode]);
  return (
    <>
      <S.TextField
        type="text"
        placeholder="CEP"
        value={zipCode.value}
        onChange={(e) => {
          setZipCode((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.replace(/\D/g, "").substring(0, 8),
              valid: validateZipCode(e.target.value.replace(/\D/g, "").substring(0, 8)),
            };
          });
        }}
        error={!zipCode.valid}
      />
      {!zipCode.valid ? (
        <S.ErrorMessage>{zipCode.message}</S.ErrorMessage>
      ) : null}
      <S.Grid item container xs={12} p={0} py={2}>
        <S.Grid item xs={6} p={0}>
          <S.TextField
            type="text"
            placeholder="Estado"
            value={state}
            readOnly={true}
            error={false}
          />
        </S.Grid>
        <S.Grid item xs={6} p={0} pl={2}>
          <S.TextField
            type="text"
            placeholder="Cidade"
            value={city}
            readOnly={true}
            error={false}
          />
        </S.Grid>
      </S.Grid>
      <S.TextField
        type="text"
        placeholder="Bairro"
        value={neighborhood.value}
        onChange={(e) => {
          setNeighborhood((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 40),
              valid: validateNeighbourhood(neighborhood.value),
            };
          });
        }}
        error={!neighborhood.valid}
      />
      {!neighborhood.valid ? (
        <S.ErrorMessage>{neighborhood.message}</S.ErrorMessage>
      ) : null}

      <S.TextField
        type="text"
        placeholder="Rua"
        value={street.value}
        onChange={(e) => {
          setStreet((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 50),
              valid: validateStreet(e.target.value),
            };
          });
        }}
        error={!street.valid}
        style={{ marginTop: "1rem" }}
      />
      {!street.valid ? <S.ErrorMessage>{street.message}</S.ErrorMessage> : null}
      <S.TextField
        type="text"
        placeholder="Número"
        value={number.value}
        onChange={(e) => {
          setNumber((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 5).replace(/\D/g, ""),
              valid: validateNumber(e.target.value),
            };
          });
        }}
        error={!number.valid}
        style={{ marginTop: "1rem" }}
      />
      {!number.valid ? <S.ErrorMessage>{number.message}</S.ErrorMessage> : null}
      <S.Grid item container xs={12} justifyContent={"flex-end"} px={0}>
        <button onClick={handleActionButton}>{buttonTitle}</button>
      </S.Grid>
    </>
  );
};

export default BillingForm;