import styled from "styled-components";
import { Grid as MuiGrid } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/viuzzLogo.svg";

export const Grid = styled(MuiGrid)`
  p {
    font-family: Barlow Semi Condensed;
    font-style: normal;
    font-weight: 500;
    margin-right: 8vw;
    text-align: right;
    font-size: 4vw;
    align-items: center;
    @media (min-width: 992px) {
      font-size: 26px;
      margin-right: 4vw;
    }
  }
`;

export const ViuzzLogo = styled(Logo)`
  display: inline-flex;
  margin: 15px;
  height: 40px;
  @media (min-width: 576px) {
    height: 50px;
  }
  @media (min-width: 992px) {
    height: 60px;
    padding-left: 30px;
    padding-top: 10px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const LicensedGrid = styled(Grid)`
  margin-right: 4vw;
  @media (max-width: 992px) {
    margin-right: 8vw;
  }
  p {
    margin: 0;
    margin-right: 2px;
  }
  .href {
    font-weight: 900;
  }
`
