/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BgBase from "../../../assets/bgBase.svg";

import * as S from "./style";

import { CollectibleType } from "../../../types/CollectibleType";
import { ProjectType } from "../../../types/ProjectType";
import { CollectionsType } from "../../../types/CollectionsType";
import { CleanedType } from "../../../types/CleanedType";
import { OnSaleType } from "../../../types/OnSaleType";


type props = {
  collectibleList?: CollectibleType[];
  projectList?: ProjectType[] | CollectionsType[];
  collectionList?: CollectionsType[];
  cleanedNftsList?: CleanedType[];
  saleList?: OnSaleType[];
  xs?: number
  md?: number
  lg?: number
  showOwned?: boolean
  ownerDetails?: boolean
  unavailable?: boolean
};

const ProjectList: React.FC<props> = ({ xs, md, lg, ownerDetails, unavailable, cleanedNftsList, collectionList, saleList }) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const collectionNftArray: Array<CollectionsType[] | CleanedType[]> = []


  if (collectionList) {
    collectionNftArray.push(collectionList);
  };
  if (cleanedNftsList) {
    collectionNftArray.push(cleanedNftsList);
  };

  const flatArr = collectionNftArray.flat();

/*   const handleOnMouseOver = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.currentTarget.play();
  };

  const handleOnMouseOut = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.currentTarget.pause();
  }; */
  return (
    <S.MainContainer container item xs={12} spacing={3}>
      {!saleList && flatArr?.map((collection) => {
        // const video = React.useRef<HTMLVideoElement>(null);
        return (
          <S.CardContainer
            item
            container
            xs={xs ? xs : 6}
            md={md ? md : 4}
            lg={lg ? lg : 3}
            onClick={() => {
              scrollToTop();
              navigate(collection.thumb ? "/colecoes/" + collection.url_title : "/colecionaveis/" + collection.url_title, {
                state: { collection: collection, ownerDetails: ownerDetails, /* listed: listed */},
              });
            }}
            style={{ 'backgroundImage': `url(${BgBase})` }}
            key={collection.id}
          >
{/*             {showOwned &&
              <S.QuantityFab color="primary" aria-label="add">
                <p>{project.available}</p>
              </S.QuantityFab>
            } */}
            {collection &&
              <div className={`card ${unavailable && !collection?.isAvailable && 'unavailable'}`} style={{ 'backgroundImage': `url("${collection.thumb ? collection.thumb : collection.imageUrl}")` }}>
                <S.SpaceFiller className={`${unavailable && !collection?.isAvailable ? 'unavailableFiller' : 'defaultFiller'}`} />
                {unavailable && !collection.isAvailable &&
                  <S.UnavailableGrid container justifyContent="center">
                    <p>ESGOTADO</p>
                  </S.UnavailableGrid>
                }
                <S.Divider className={'details'} />
                <S.CardInfoTest
                  className={'details'}
                // item container xs={12} rowSpacing={0}

                >
                  <Grid item container xs={12} alignItems={"center"}>
                    <Grid item xs={12}><h2>{collection.title}</h2></Grid>
                    <Grid item xs={10}>
                      <h3>
                        <Link to={collection.thumb ? "/colecoes/" + collection.url_title : "/colecionaveis/" + collection.url_title}>
                          Mais detalhes
                        </Link>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <S.ArrowRightIcon />
                    </Grid>
                  </Grid>

                </S.CardInfoTest>
              </div>
            }
            {/* {collectible?.videoUrl &&
              <div className={'card'} >
                <div style={{ 'width': '100%', 'aspectRatio': '595/490' }}>
                  <video width="100%" height="100%" muted loop onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
                    <source src={collectible?.videoUrl ?? ""} type="video/mp4"></source>
                  </video>
                </div>
                <S.Divider className={'details'} />
                <S.CardInfoTest
                  className={'details'}
                // item container xs={12} rowSpacing={0}

                >
                  <Grid item container xs={12} alignItems={"center"}>
                    <Grid item xs={12}><h2>{collectible.title}</h2></Grid>
                    <Grid item xs={10}>
                      <h3>
                        <Link to={"/colecionaveis/detalhes/" + collectible.collectionName}>
                          Mais detalhes
                        </Link>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <S.ArrowRightIcon />
                    </Grid>
                  </Grid>
                </S.CardInfoTest>
              </div>
            } */}
          </S.CardContainer>
        );
      })}
      {saleList && saleList?.map((collection) => {
        // const video = React.useRef<HTMLVideoElement>(null);
        return (
          <S.CardContainer
            item
            container
            xs={xs ? xs : 6}
            md={md ? md : 4}
            lg={lg ? lg : 3}
            onClick={() => {
              scrollToTop();
              navigate("/colecionaveis/" + collection.url_title, {
                state: { collection: collection, ownerDetails: ownerDetails, listed: collection},
              });
            }}
            style={{ 'backgroundImage': `url(${BgBase})` }}
            key={collection.id}
          >
{/*             {showOwned &&
              <S.QuantityFab color="primary" aria-label="add">
                <p>{project.available}</p>
              </S.QuantityFab>
            } */}
            {collection &&
              <div className={`card`} style={{ 'backgroundImage': `url("${collection.imageUrl}")` }}>
                <S.SpaceFiller className={'defaultFiller'} />
                <S.Divider className={'details'} />
                <S.CardInfoTest
                  className={'details'}
                // item container xs={12} rowSpacing={0}

                >
                  <Grid item container xs={12} alignItems={"center"}>
                    <Grid item xs={12}><h2>{collection.title}</h2></Grid>
                    <Grid item xs={10}>
                      <h3>
                        <Link to={"/colecionaveis/" + collection.url_title}>
                          Mais detalhes
                        </Link>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <S.ArrowRightIcon />
                    </Grid>
                  </Grid>

                </S.CardInfoTest>
              </div>
            }
            {/* {collectible?.videoUrl &&
              <div className={'card'} >
                <div style={{ 'width': '100%', 'aspectRatio': '595/490' }}>
                  <video width="100%" height="100%" muted loop onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
                    <source src={collectible?.videoUrl ?? ""} type="video/mp4"></source>
                  </video>
                </div>
                <S.Divider className={'details'} />
                <S.CardInfoTest
                  className={'details'}
                // item container xs={12} rowSpacing={0}

                >
                  <Grid item container xs={12} alignItems={"center"}>
                    <Grid item xs={12}><h2>{collectible.title}</h2></Grid>
                    <Grid item xs={10}>
                      <h3>
                        <Link to={"/colecionaveis/detalhes/" + collectible.collectionName}>
                          Mais detalhes
                        </Link>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <S.ArrowRightIcon />
                    </Grid>
                  </Grid>
                </S.CardInfoTest>
              </div>
            } */}
          </S.CardContainer>
        );
      })}
    </S.MainContainer>
  );
};

export default ProjectList;
