import styled from  'styled-components';
import { Fab, FormLabel, Grid, Select as MuiSelect } from "@mui/material";
import Input from "../../../../components/Input/input";
import { Avatar as MUIAvatar } from "@mui/material";
import Button from '@mui/material/Button';

export const TextField = styled(Input)`
  .MuiInputBase-input {
    border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important;
    padding: 15px 15px;
    transition: 250ms;
    border: 1px solid rgb(46,46,56) !important;
    /* height:25px ; */
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
  }
  [aria-invalid="true"] {
    border-style: solid !important;
    border-width: 1px !important;
  }
  /* @media (min-width: 320px) {
    width: 320px !important;
  }
  @media (min-width: 420px) {
    width: 420px !important;
  } */

  [name="password"] {
    margin-top: 0px;
  }
`;

export const Formlabel = styled(FormLabel)`
  color: white !important;
`;
export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 0px;
`;
export const Select = styled(MuiSelect)`
  && {
    background-color: rgb(46, 46, 56);
    color: white;
    font-size: 20px;
    padding-left: 5px;
    border-radius: 10px;
    border-width: 0px !important;
    text-align: left;
    .MuiOutlinedInput-notchedOutline {
      border-style: none !important;
    }
    .MuiSelect-icon {
      color: white !important;
    }
  }
`;

export const Avatar = styled(MUIAvatar)`
  background-color: rgb(38, 38, 38) !important;
  border-width:2px;
  border-color: rgb(251,132,4);
  border-style: solid;
  width: 300px !important;
  height: 300px !important;
`;

export const ClosersModal = styled(Grid)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled(Grid)`
  background-color: #101010;
  width: 60% !important;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 25px;
  @media (max-width: 650px) {
    width: 80% !important;
  }

  .send{
    padding: 32px 0;
    width: 80%;
    height: 40%;
    border: 2px;
    background-image: repeating-linear-gradient(-50deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px), repeating-linear-gradient(40deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px), repeating-linear-gradient(130deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px), repeating-linear-gradient(220deg, #ffffff, #ffffff 5px, transparent 5px, transparent 13px, #ffffff 13px);
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    cursor: pointer;
    @media (max-width: 650px) {
      margin-top: 30px;
    }

    .buttonGrid {
      flex-direction: row;
    }

    p {
      padding: 0 16px
    }

    button {
      @media (max-width: 992px) {
        margin-left: 20px;
      }

      @media (min-width: 1400px) {
        margin-left: 3vw;
      }
    }
  }

  .xButton{
    padding: 20px 20px 0 0;
    cursor: pointer;
  }

  .TextfieldGrid{
    @media (min-width: 1536px) {
      padding-left: 40px;
    }
  }

  .previewImg{
    width: 300px;
    height: 300px;
    margin: 20px 0;
  }

  .previewVid{
    margin: 20px 0 20px 20px;
    width: 90%;
    height: 90%;
  }

  .BottomGrid {
    margin-top: 40px;
    @media (max-width: 650px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .SendGrid{
    justify-content: center;
  }
`

export const ModalAvatar = styled(Avatar)`
  background-color: rgb(38, 38, 38) !important;
  border-width: 3px;
  border-color: rgb(251,132,4);
  border-style: solid;
  width: 80px !important;
  height: 80px !important;
/*   width: 300px !important;
  height: 300px !important; */
`;

export const ModalTextField = styled(Input)`
  width: 100%;
  font-family: 'Barlow Semi Condensed';
  font-size: 22px;
  font-weight: 400;
  .MuiInputBase-input {
/*     border: 3px solid rgb(253, 112, 11);
    border-style: none !important;
    background-color: rgb(46, 46, 56) !important;
    border-radius: 10px !important; */
    padding: 15px 15px;
/*     transition: 250ms; */
/*     border: 1px solid rgb(46,46,56) !important; */
    color: #FFFFFF;
    :focus {
      border: 1px solid rgb(253, 112, 11) !important;
      transition: 10ms !important;
    }
    ::placeholder{
      color: #FFFFFF;
      opacity: 1;
      font-family: 'Barlow Semi Condensed';
      font-weight: 200;
      font-size: 20px;
    }
  }
`;

export const ModalButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  gap: 15px;
  margin: 20px 0 !important;
    
  @media (min-width: 700px) {
    width: 30%;
    height: 60px;
  }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`
export const CreatePostButton = styled(Button)`
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  border-radius: 36.5px !important;
  width: 220px;
  height: 68px;
  gap: 15px;
    
  @media (min-width: 700px) {
      width: 300px;
      height: 60px;
   }

    .ButtonTxt {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
        
      @media (min-width: 700px) {
        font-size: 30px;
      }
    }
`

export const IconFab = styled(Fab)`
  justify-self: right;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
    
    :hover{
      transform: scale(1.1);
/*       margin-right: 10px; */
    }
`

export const CanvasGrid = styled(Grid)`
  display: none;
`

export const CropGrid = styled(Grid)`
  padding: 0 15px;
  margin-bottom: 10px;
  justify-content: center;
`