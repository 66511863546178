import styled from "styled-components";
import { Tabs as MuiTabs } from "@mui/material";
import { Grid as MuiGrid } from "@mui/material";
export const Tabs = styled(MuiTabs)`
  @media (min-width: 1440px) {
    width: 100%;
  }
  .MuiTab-root {
    font-family: "Barlow Semi Condensed";
    font-style: normal;
    font-weight: 600;
    text-transform: none !important;
    line-height: 16px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 10px !important;
    color: white !important;
    @media (min-width: 570px) {
      font-size: 20px;
      line-height: 20px;
      padding: 25px 25px !important;
      /* padding-bottom: 20px !important; */
    }
    @media (min-width: 990px) {
      font-size: 28px;
      line-height: 28px;
      padding: 25px 30px !important;
    }
  }
  .MuiTabs-indicator {
    z-index: 2;
    height: 6px;
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    border-radius: 1px;
    @media (min-width: 600px) {
      height: 9px;
    }
  }
`;
export const MainContainer = styled(MuiGrid)`
  padding-top: 80px;
  width: 100%;
  text-align: center;
`;
export const Grid = styled(MuiGrid)`
  text-align: center;
  justify-content: center !important;

  .linear-gradient-line {
    display: flex;
    width: 100vw;
    background-color: red;
    height: 2px;
    transform: translate(0, -4px);
    background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%);
    opacity: 0.75;
    border-radius: 5px;
    @media (min-width: 440px) {
      width: 440px;
    }
    @media (min-width: 570px) {
      width: 570px;
    }
    @media (min-width: 600px) {
      width: 600px;
      height: 3px;
      transform: translate(0, -6px);
    }
    @media (min-width: 990px) {
      width: 780px;
    }
  }
`;
export const CenteredContainer = styled(MuiGrid)`
  /* background-color: red; */
  padding:5px 15px;
  @media (min-width: 440px) {
    width: 440px !important;
  }
  @media (min-width: 570px) {
    width: 570px !important;
  }
  @media (min-width: 600px) {
    width: 600px !important;
  }
  @media (min-width: 990px) {
    width: 780px !important;
  }
`;

