import React from "react";
import * as S from "./style";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/sharedfunctions";
export const Footer: React.FC = () => {

  return (
    <S.Grid container>
      <S.Grid item container xs={6}>
        <S.Grid item alignItems="center" container>
          <S.ViuzzLogo onClick={scrollToTop} />
        </S.Grid>
      </S.Grid>
      <S.Grid item container xs={6} justifyContent="flex-end">
        <S.Grid item>
          <p>
            ©viuzz
            <br />
            <Link to="/politicadeprivacidade">Privacy Policy</Link>
            {/* <br />
            <Link to="/termosecondicoes">Terms & Conditions</Link> */}
          </p>
        </S.Grid>
      </S.Grid>
      <S.Grid item container xs={12} justifyContent="flex-end">
        <S.LicensedGrid container gap='5px' justifyContent='flex-end'>
          <p>
            Conteúdo licenciado por
            {/* <br />
            <Link to="/termosecondicoes">Terms & Conditions</Link> */}
          </p>
          <p className="href">
            <Link to="/politicadeprivacidade">Viuzz Tecnologia LTDA</Link>
          </p>
          <p>35.491.409/0001-32</p>
        </S.LicensedGrid>
      </S.Grid>
    </S.Grid>
  );
}

export default Footer;
