import React from 'react'
import * as S from './style';
import { ProfileFormType } from '../../../../types/ProfileFormType';
import { /* maskCellphone, maskDate, validateCellphoneNumber, */ /* validateCpfOrCnpj */ /* validateDate */
  validateEmail, validateName, validateUsername, validateWalletAddress
} from '../../../../utils/sharedfunctions';
import { getUserInfo } from '../../../../utils/requests';
import { UserType } from '../../../../types/UserType';
import 'react-image-crop/dist/ReactCrop.css'
// import { FormControl, MenuItem, SelectChangeEvent } from '@mui/material'
import ChangeAvatarModal from './ChangeAvatarModal';

const ProfileForm: React.FC<ProfileFormType> = ({ props, touched }) => {
  const [name, setName] = props.name;
  const [email, setEmail] = props.email;
  /*   const [phone, setPhone] = props.phone; */
  // const [gender, setGender] = props.gender;
  /*   const [birthdate, setBirthdate] = props.birthdate; */
  /*   const [cpf, setCpf] = props.cpf; */
  const [username, setUsername] = props.username;
  const [wallet, setWallet] = props.wallet;
  const setTouchedFields = touched[1];

  // const handleChange = (e: SelectChangeEvent<unknown>) => {
  //   setGender((curvalue: any) => {
  //     return {
  //       ...curvalue,
  //       value: e.target.value,
  //       valid: true,
  //     };
  //   });
  // };

  const [user, setUser] = React.useState<UserType>({} as UserType);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const closeModal = (close: boolean) => {
    setShowModal(close);
  };

  React.useEffect(() => {
    getUserInfo().then((res) => {
      setUser(res?.user ?? ({} as UserType));
    });
  }, []);

  return (
    <>
      <div>
        <S.Avatar src={user?.avatar ?? ""} />
        <button onClick={() => setShowModal(true)}>
          Alterar Foto
        </button>
      </div>
      <S.TextField
        type="text"
        placeholder="Nome"
        value={name.value}
        onChange={(e) => {
          !!setTouchedFields && setTouchedFields(curValue => [...curValue, "name"]);
          setName((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 50),
              valid: validateName(e.target.value),
            };
          });
        }}
        error={!name.valid}
      />
      {!name.valid ? (
        <S.ErrorMessage>{name.message}</S.ErrorMessage>
      ) : null}
      <S.TextField
        type="text"
        placeholder="Email"
        value={email.value}
        onChange={(e) => {
          !!setTouchedFields && setTouchedFields(curValue => [...curValue, "email"]);
          setEmail((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 50),
              valid: validateEmail(e.target.value),
            };
          });
        }}
        error={!email.valid}
        style={{ marginTop: "1rem" }}
      />
      {!email.valid ? (
        <S.ErrorMessage>{email.message}</S.ErrorMessage>
      ) : null}
      {/*       <S.TextField
        type="text"
        placeholder="Telefone"
        value={phone.value}
        onChange={(e) => {
          !!setTouchedFields && setTouchedFields(curValue => [...curValue, "phone"]);
          setPhone((curvalue: any) => {
            return {
              ...curvalue,
              value: maskCellphone(e.target.value),
              valid: validateCellphoneNumber(e.target.value),
            };
          });
        }}
        error={!phone.valid}
        style={{ marginTop: "1rem" }}
      />
      {!phone.valid ? (
        <S.ErrorMessage>{phone.message}</S.ErrorMessage>
      ) : null} */}
      {/* <FormControl fullWidth margin={'normal'}>
        <S.Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gender.value}
          label="Gênero"
          onChange={handleChange}
        >
          <MenuItem value={'Selecione'}>Selecione</MenuItem>
          <MenuItem value={'MALE'}>Masculino</MenuItem>
          <MenuItem value={'FEMALE'}>Feminino</MenuItem>
          <MenuItem value={'OTHER'}>Outro</MenuItem>
        </S.Select>
      </FormControl> */}
      {/*       <S.TextField
        type="text"
        placeholder="Data de nascimento"
        value={birthdate.value}
        onChange={(e) => {
          !!setTouchedFields && setTouchedFields(curValue => [...curValue, "birthdate"]);
          setBirthdate((curvalue: any) => {
            return {
              ...curvalue,
              value: maskDate(e.target.value.substring(0, 10)),
              valid: validateDate(maskDate(e.target.value).substring(0, 10)),
            };
          });
        }}
        error={!birthdate.valid}
        style={{ marginTop: "1rem" }}
      />
      {!birthdate.valid ? (
        <S.ErrorMessage>{birthdate.message}</S.ErrorMessage>
      ) : null} */}
      {/*       <S.TextField
        type="text"
        placeholder="CPF ou CNPJ"
        value={cpf.value}
        onChange={(e) => {
          setCpf((curvalue) => {
            return {
              ...curvalue,
              value: e.target.value.replace(/\D/g, "").substring(0, 11),
              valid: validateCpfOrCnpj(e.target.value.replace(/\D/g, "").substring(0, 11)),
            };
          });
        }}
        error={!cpf.valid}
        style={{ marginTop: "1rem" }}
      />
      {!cpf.valid ? <S.ErrorMessage>{cpf.message}</S.ErrorMessage> : null} */}
      <S.TextField
        type="text"
        placeholder="Nome de Usuário"
        value={username.value}
        onChange={(e) => {
          !!setTouchedFields && setTouchedFields(curValue => [...curValue, "username"]);
          setUsername((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 50),
              valid: validateUsername(e.target.value),
            };
          });
        }}
        error={!username.valid}
        style={{ marginTop: "1rem" }}
      />
      {!username.valid ? (
        <S.ErrorMessage>{username.message}</S.ErrorMessage>
      ) : null}
      <S.TextField
        type="text"
        placeholder="Carteira Cripto"
        value={wallet.value}
        onChange={(e) => {
          !!setTouchedFields && setTouchedFields(curValue => [...curValue, "wallet"]);
          setWallet((curvalue: any) => {
            return {
              ...curvalue,
              value: e.target.value.substring(0, 50),
              valid: validateWalletAddress(e.target.value),
            };
          });
        }}
        error={!wallet.valid}
        style={{ marginTop: "1rem" }}
      />
      {!wallet.valid ? (
        <S.ErrorMessage>{wallet.message}</S.ErrorMessage>
      ) : null}
      {showModal &&
        <ChangeAvatarModal closeFunc={closeModal}></ChangeAvatarModal>
      }
    </>
  )
}

export default ProfileForm