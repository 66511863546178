import React from 'react'

type props = {
    url:string;
}
const FormsRedirect:React.FC<props> = ({url}) => {
  const redirectToUrl = () =>{
    window.location.href = url;
    return "";
  }
  return (
    <div>{redirectToUrl()}</div>
  )
}

export default FormsRedirect